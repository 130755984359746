import * as R from 'ramda';

export const PERMISSIONS = {
  admin: 'ADMIN',
  writeHQ: 'HQ_WRITE',
  write: 'WRITE',
  readHQ: 'HQ_READ',
  read: 'READ',
};

// ------------------------------------------------------------FROM-PERMISSIONS

export const getPermissions = R.indexBy(R.prop('permission'));
export const getWritePermissions = R.pipe(getPermissions, R.pick([PERMISSIONS.writeHQ, PERMISSIONS.write, PERMISSIONS.admin]));

const hasAdmin = R.has(PERMISSIONS.admin);
const hasWriteHQ = R.has(PERMISSIONS.writeHQ);
const hasWrite = R.has(PERMISSIONS.write);

export const getCanCreateEvent = R.anyPass([hasAdmin, hasWriteHQ, hasWrite]);
export const getCanEditEvent = R.anyPass([hasAdmin, hasWriteHQ, hasWrite]);
export const getCanDeleteEvent = R.anyPass([hasAdmin, hasWriteHQ, hasWrite]);

export const getCanCreateHQEvent = R.anyPass([hasAdmin, hasWriteHQ]);
export const getCanEditHQEvent = R.anyPass([hasAdmin, hasWriteHQ]);
export const getCanDeleteHQEvent = R.anyPass([hasAdmin, hasWriteHQ]);

// -----------------------------------------------------------------FROM-GROUPS

const hasAdminPermission = R.propEq('permission', PERMISSIONS.admin);
const hasWriteHQPermission = R.propEq('permission', PERMISSIONS.writeHQ) 
const hasWritePermission = R.propEq('permission', PERMISSIONS.write) 

export const getWriteGroups = R.filter(R.anyPass([
  hasAdminPermission,
  hasWriteHQPermission,
  hasWritePermission
]));

export const getIsAdminGroup = R.find(R.propEq('permission', PERMISSIONS.admin));
export const getAdminId = R.pipe(getIsAdminGroup, R.prop('id'));