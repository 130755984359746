import { useEffect, useState, Fragment } from 'react';
import * as R from 'ramda';
import dayjs from 'dayjs';
import './EventDetails.css';
import * as dateFns from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box, CircularProgress } from '@mui/material';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import { styled } from '@mui/material';
import { selectEvent, selectEventClosed } from '../../selectors/calendar';
import ContainerBottom from '../Drawer/ContainerBottom';
import { viewEvent, closeEvent } from '../../ducks/calendar';
import { DialogTitle } from '@mui/material';
import ContainerTop from '../Drawer/ContainerTop';
import EventTitle from './EventTitle';
import EventForm from './EventForm';
import Close from './Close';
import CalendarIcon from '../icons/Calendar';
import ArrowLong from '../icons/ArrowLong';
import LinkIcon from '../icons/Link';
import MarketIcon from '../icons/Market';
import CountryIcon from '../icons/Country';
import BoutiqueIcon from '../icons/Boutique';
import EditIcon from '../icons/Edit';
import Chips from '../Chips/Chips';
import useMarkets from '../../hooks/useMarkets';
import useCountries from '../../hooks/useCountries';
import useBoutiques from '../../hooks/useBoutiques';
import BlackButton from '../BlackButton/BlackButton';
import useIndexedData from '../../hooks/useIndexedData';
import useDepartments from '../../hooks/useDepartments';
import useEditEvent from '../../hooks/useEditEvent';
import Dialog from './Dialog';
import useDeleteEvent from '../../hooks/useDeleteEvent';
import useUserGroups from '../../hooks/useUserGroups';
import useGroup from '../../hooks/useGroup';
import { getCanEditEvent, getIsAdminGroup, getPermissions } from '../../lib/permissions';
import { cleanIdRegex } from '../../utils';
import AssociatedGroupIcon from '../icons/AssociatedGroup';

const Wrapper = styled(Box)(() => ({
  display: 'inline-flex',
  minHeight: 29,
}));

const Typography400 = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: 14,
}));

const getItems = (indexedItems) =>
  R.reduce(
    (acc, id) =>
      R.ifElse(R.has(id), R.pipe(R.prop(id), R.append(R.__, acc)), R.always(acc))(indexedItems),
    [],
  );

const EventDetails = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const [isDeletePending, setIsDeletePending] = useState(false);
  const [isEditPending, setIsEditPending] = useState(false);
  const event = useSelector(selectEvent);
  const indexedMarkets = useIndexedData(useMarkets);
  const indexedCountries = useIndexedData(useCountries);
  const indexedBoutiques = useIndexedData(useBoutiques);
  const indexedDepartment = useIndexedData(useDepartments);
  const editEvent = useEditEvent();
  const deleteEvent = useDeleteEvent();

  const { data: userGroups = [] } = useUserGroups();
  const permissions = getPermissions(userGroups);

  const [openVisualization, setVisualizationOpen] = useState(false);
  const {
    title,
    comment,
    departmentId: department,
    start,
    end,
    link,
    marketIds: markets = [],
    countryIds: countries = [],
    storeIds: boutiques = [],
    isHQ,
    groupId,
    id,
  } = R.evolve({
    departmentId: (id) => R.prop(id, indexedDepartment),
    marketIds: getItems(indexedMarkets),
    countryIds: getItems(indexedCountries),
    storeIds: getItems(indexedBoutiques),
  })(event);

  const userGroup = R.find(R.propEq('id', groupId))(userGroups);
  const idGroupCreator = R.isNil(userGroup) ? groupId : null;
  const { data: eventGroup, isLoading: isEventGroupLoading } = useGroup({ id: idGroupCreator });
  const isAdmin = getIsAdminGroup(userGroups);
  const canEditHQEvent = (getCanEditEvent(permissions) && userGroup) || isAdmin;

  useEffect(() => {
    if (R.isEmpty(event)) {
      return setVisualizationOpen(false);
    }
    return setVisualizationOpen(true);
  }, [event]);

  const handleClose = () =>{ dispatch(viewEvent({}));  dispatch(closeEvent(true))};
  const groupCreatorLabel = userGroup?.shortLabel || eventGroup?.shortLabel;
  const isEMEAEvent = R.all(R.isEmpty)([markets, countries, boutiques]);

  return (
    <>
      <Dialog
        open={openVisualization}
        onClose={handleClose}
        maxWidth="md"
        sx={{ top: '10%', width: '70%', height: '80%', left: '15%' }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Close onClose={handleClose} />

          <Box px={8} >
            <Typography variant="h6" sx={{fontSize:'1.5vh'}}>Associated group:</Typography>
            <Typography variant="h6" sx={{ display: 'flex', alignItems: 'initial', fontSize:'1.5vh' }}>
              <AssociatedGroupIcon sx={{ mr: 0.5, mt: 0.25 }} />
              {R.isNil(groupCreatorLabel) || isEventGroupLoading ? (
                <CircularProgress size={13} />
              ) : (
                groupCreatorLabel
              )}
            </Typography>
            <EventTitle title={title} item={department} isHQ={isHQ} />
          </Box>
        </DialogTitle>
        <ContainerTop>
          <Box px={8} pb={2} sx={{ borderRadius: 0 }}>
            <Box >
              <Wrapper sx={{ mt: 5 }}>
                <CalendarIcon sx={{ mr: 2 }} />
                <Typography400 mr={2} variant="body1">
                  {dayjs(start).format('MMMM D, YYYY')}
                </Typography400>
                <ArrowLong sx={{ top: 4, position: 'relative' }} />
                <Typography400 ml={2} variant="body1">
                  {dayjs(end).format('MMMM D, YYYY')}
                </Typography400>
              </Wrapper>
            </Box>
            {link && (

              <Wrapper sx={{ mb: 3, lineBreak: 'anywhere' }}>
                <LinkIcon sx={{ fill: '#fff', mr: 2 }} />
                <Typography400 variant="body1">
                  <Link target="_blank" rel="noopener" href={link} variant="inherit">
                    {link}
                  </Link>
                </Typography400>
              </Wrapper>

            )}
          </Box>
          <Divider variant="fullWidth" />
          <Box px={8} pt={5}>
            {(isEMEAEvent || !R.isEmpty(markets)) && (
              <Box display="flex">
                <Box display="flex">
                  <MarketIcon sx={{ fill: 'grey.500', mr: 2 }} />
                  <Typography variant='body2' sx={{ fontSize: 16, whiteSpace: 'nowrap', color: 'grey.500' }}>
                    Impacted markets
                  </Typography>

                </Box>
                {isEMEAEvent
                  ? <Box sx={{ ml: 2, mt: 1, position: 'relative', bottom: 20 }}><Chips items={[{ label: "EMEA" }]} /></Box>
                  : <Box sx={{ ml: 2, mt: 1, position: 'relative', bottom: 20 }}><Chips items={markets} /></Box>
                }
              </Box>
            )}
            {R.map(
              ({ Icon, label, artefacts } = {}) => {
                if (R.isEmpty(artefacts)) return;
                return (
                  <Box key={`${label}-visualize-event`} pt={3}>
                    <Box display="inline-flex">
                      <Icon sx={{ color: 'grey.500', mr: 2 }} />
                      <Typography variant="body2" sx={{ fontSize: 14, color: 'grey.500' }}>
                        {label}
                      </Typography>
                    </Box>
                    {!R.isEmpty(artefacts) && (
                      <Typography variant="body1" sx={{ fontWeight: 400, ml: 5, fontSize: 14, }}>
                        {R.intersperse(
                          ', ',
                          R.map(
                            ({ id, label }) => <Fragment key={id}>{label}</Fragment>,
                            R.values(artefacts),
                          ),
                        )}
                      </Typography>
                    )}
                  </Box>
                );
              },
              [
                { Icon: CountryIcon, label: 'Impacted countries', artefacts: countries },
                { Icon: BoutiqueIcon, label: 'Impacted boutiques', artefacts: boutiques },
              ],
            )}
           {comment && <Box sx={{
              mt: 3,
              ml: 0.5,
            }}>
              <fieldset className="comment" aria-hidden="true" >
                <legend className='legend'>
                  <Typography variant="body2" sx={{ fontSize: 14, color: 'grey.500' }}>Comment</Typography>
                </legend>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 400,
                    fontSize: 14,
                    whiteSpace: 'pre-line',
                    ml: 3
                  }}>

                  <span dangerouslySetInnerHTML={{ __html: comment }} />
                </Typography>
              </fieldset>
            </Box>}
          </Box>
        </ContainerTop>
        {canEditHQEvent && (
          <ContainerBottom>
            <BlackButton
              onClick={() => {
                setOpen(true);
                setVisualizationOpen(false);
              }}
              startIcon={<EditIcon />}
            >
              Edit event
            </BlackButton>
          </ContainerBottom>
        )}
      </Dialog>

      <Dialog open={open} onClose={() => { setOpen(false) }}
        fullWidth
        sx={{ top: '10%', width: '75%', height: '85%', left: '12.5%' }}
        maxWidth="xl"
      >
        <EventForm
          editProps={event}
          variant="edit"
          onClose={() => { setOpen(false), setVisualizationOpen(true) }}
          onEdit={(form) => {
            setIsEditPending(true);
            const departmentId = R.replace(cleanIdRegex, '', form.departementId);
            const marketsIds = R.map(R.replace(cleanIdRegex, ''))(form.marketIds);
            const countriesIds = R.map(R.replace(cleanIdRegex, ''))(form.countryIds);
            const storesIds = R.map(R.replace(cleanIdRegex, ''))(form.boutiqueIds);
            const groupId = R.replace(cleanIdRegex, '', form.groupId);

            editEvent.mutate(
              {
                id: event.id,
                resourcesIds: form.ids,
                hq: form.isHQ,
                groupId: Number(groupId),
                eventTitle: form.title,
                comment: form.comment,
                eventAttachedLink: form.link,
                startDate: dateFns.formatISO(new Date(form.startDate)),
                endDate: dateFns.formatISO(new Date(form.endDate)),
                departmentId: Number(departmentId),
                marketsIds: R.map(Number)(marketsIds),
                countriesIds: R.map(Number)(countriesIds),
                storesIds: R.map(Number)(storesIds),
              },
              {
                onSuccess: () => {
                  setOpen(false);
                  dispatch(viewEvent({}));
                  setIsEditPending(false);
                },
                onError: (err) => {
                  /* eslint-disable no-console */
                  console.error('error:', err.message);
                  setIsEditPending(false);
                },
              },
            );
          }}
          idGroupCreator={idGroupCreator}
          isDeletePending={isDeletePending}
          isEditPending={isEditPending}
          onDelete={() => {
            setIsDeletePending(true);
            deleteEvent.mutate(
              {
                id,
                deletedAt: new Date().toISOString(),
              },
              {
                onSuccess: () => {
                  setOpen(false);
                  dispatch(viewEvent({}));
                  setIsDeletePending(false);
                },
                onError: (err) => {
                  /* eslint-disable no-console */
                  console.log('error:', err.message);
                  setIsDeletePending(false);
                },
              },
            );
          }}
        />
      </Dialog>
    </>
  );
};

export default EventDetails;
