import MuiDialog from '@mui/material/Dialog';

const Dialog = (props) => (
  <MuiDialog
    fullWidth={true}
    maxWidth='xl'
    open={props.open}
    onClose={props.onClose}
    sx={{ top: 158, ...props.sx }}
    // todo height
    PaperProps={{ sx: { height: props.height || '100%' }}}
  >
    {props.children}
  </MuiDialog>
);


export default Dialog


