import { QueryClient } from 'react-query';

export const initClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: true,
        // retryDelay 1000 | 4000 | 16000 | max 30000 ms
        retryDelay: (attempt) => Math.min(attempt > 1 ? Math.pow(2, attempt) * 1000 : 1000, 30 * 1000),
        staleTime: 5 * 60 * 1000,
      },
    },
  });
};