import { Fragment } from 'react'
import * as R from 'ramda';
import { Button as MuiButton, Container, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import { styled } from '@mui/system';

const WIDTH = '330px';
export const MARKET = "market";
export const COUNTRY = "country"
export const STORE = "store";
export const DEPARTMENT = "department";
export const GROUPS = "groups";

const menu = [
  {
    "id": "Administration",
    "label": "Administration",
    "children": [
      {
        "id": MARKET,
        "label": "Market"
      },
      {
        "id": COUNTRY,
        "label": "Country"
      },
      {
        "id": STORE,
        "label": "Boutique"
      },
      {
        "id": DEPARTMENT,
        "label": "Department"
      }
    ]
  },
  {
    "id": "SECURITY",
    "label": "SECURITY",
    "children": [
      {
        "id": GROUPS,
        "label": "Groups"
      }
    ]
  }
];

const Button = styled(MuiButton)(({ theme, isSelected }) => ({
  color: theme.palette.common.white,
  textTransform: 'capitalize',
  justifyContent: 'flex-start',
  borderStyle: 'solid',
  borderBottomWidth: '2px',
  borderColor: isSelected ? theme.palette.secondary.main : 'transparent',
  borderRadius: 0
}));

const SideBar = ({ changeView, view }) => {
  const theme = useTheme();
  return (
    <Container disableGutters sx={{ padding: theme.spacing(0, 4), backgroundColor: 'common.black', width: WIDTH, height: '88vh' }}>
      {R.map(({ id, label, children = [] }) => (
        <Fragment key={id}>
          <Typography variant='h3' color='secondary' sx={{ textTransform: 'uppercase', padding: theme.spacing(4, 0, 3, 0)}}>
            {label}
          </Typography>
          <Container>
            {R.map(({ id, label }) => (
              <Grid container item key={id}>
                <Button
                  isSelected={id === view}
                  variant="text"
                  onClick={() => changeView(R.toLower(id))}
                  sx={{
                    color: 'common.white',
                    textTransform: 'capitalize',
                    justifyContent: 'flex-start'
                  }}>
                    {label}
                </Button>
              </Grid>
            ))(children)}
          </Container>
        </Fragment>
      ))(menu)}
    </Container>
  )
}

export default SideBar