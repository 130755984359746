import { styled } from '@mui/material';
import { Box, Typography, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { getHQTitle } from '../Calendars/utils';


const Wrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  grid: '1fr / auto 1fr',
  gap: theme.spacing(3),
  padding: '12px 0',
  minHeight: 29,
  alignItems: 'self-end',
}));

const Tag = styled(Box)({
  height: 55,
  width: 8,
  marginBottom: 13,
  borderRadius: 13,
});

const EventTitle = ({ onChange, title, children, item, isHQ }) => {
  return (
    <Wrapper>
      <Tag sx={{ background: item?.color, height: '95%', top: 8, position: 'relative' }} />
      <Box component="form" noValidate autoComplete="off">
        {onChange ? (
          <>
            <TextField
              placeholder="ADD EVENT TITLE"
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      display: !isHQ && 'none',
                      "& .MuiTypography-root": {
                        fontSize: '3vh',
                        fontWeight: 500,
                        width:'100%',
                        lineHeight: '42px',
                        padding: 0,
                        color: '#000000'
                      }
                    }}>
                    {isHQ && getHQTitle({ isHQ })}
                  </InputAdornment>
                ),
                disableUnderline: true,
                sx: { fontSize: "3vh", fontWeight: 500, lineHeight: '42px', padding: 0, width: {md: '50vw' } }
              }}
              onChange={e => onChange(e?.target?.value)}
              value={title}
            />
          </>
        ) : (
          <Typography variant="h1" sx={{ pb: 0.5, fontSize:'3vh' }}>{getHQTitle({ isHQ, title })}</Typography>
        )}
        <Box>
          {children || (
           <Typography variant="body2" sx={{ pb: 1.125 }}>
                {item?.label}
              </Typography>
          )}
        </Box>
      </Box>
    </Wrapper>
  );
};

export default EventTitle;
