import * as R from 'ramda';
import { format } from 'date-fns'
import currentWeekNumber from "current-week-number";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, styled } from "@mui/material";
import Divider from '@mui/material/Divider';
import MuiTypography from '@mui/material/Typography';
import { selectEmeaId, selectView, selectEventClosed} from "../../selectors/calendar";
import Dialog from '../EventForm/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import ContainerTop from '../Drawer/ContainerTop';
import ContainerBottom from '../Drawer/ContainerBottom';
import { openDrawer, viewEvent, closeEvent } from "../../ducks/calendar";
import { getCountriesStores, getHQTitle, getMarketsLabel, sortDates } from './utils';
import useIndexedData from '../../hooks/useIndexedData';
import useBoutiques from '../../hooks/useBoutiques';
import useCountries from '../../hooks/useCountries';
import Close from '../EventForm/Close';
import useMarkets from '../../hooks/useMarkets';

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  grid: '1fr / auto 1fr',
  gap: theme.spacing(1),
  padding: '5px 8px',
  width: '100%',
  float:'left',
  minHeight: 29,
  borderRadius: 12
}));

const Placeholder = styled(Box)(() => ({
  backgroundColor: 'rgb(241 241 241)',
  height: "70vh",
  marginTop: "22px",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Typography = styled(MuiTypography)({
  padding: "3px"
});

const Tag = styled(Box)({
  height: '100%',
  width: 6,
  borderRadius: 13,
  paddingRight: 4,
});

export const getListEventDescription = ({ item, indexedCountries, indexedBoutiques, indexedMarkets: markets, emeaId }) => {
  const indexedMarkets = R.dissoc(emeaId, markets);
  const countriesStores = getCountriesStores(item, indexedCountries, indexedBoutiques)
  if (R.isEmpty(countriesStores)) {
    const marketsLabel = getMarketsLabel(item, emeaId, indexedMarkets)
    if (R.isEmpty(marketsLabel)) return 'EMEA';
    if (R.length(marketsLabel) === R.length(R.values(indexedMarkets)) && R.length(marketsLabel) > 1) return 'All markets';

    return R.pipe(
      R.reject(R.isNil),
      R.map(R.toLower),
      R.intersperse(' • ')
    )(marketsLabel)
  }
  return R.pipe(
    R.map(({ label, storeIds = [] }) => `${R.toLower(label)} (${R.length(storeIds)})`),
    R.intersperse(' • '),
  )(countriesStores)
}

const List = (props) => {
  const { list, viewTitle, isWeek, isOpen, activeRange, totalEvents, children } = props;
  const indexedBoutiques = useIndexedData(useBoutiques);
  const indexedCountries = useIndexedData(useCountries);
  const indexedMarkets = useIndexedData(useMarkets);
  const emeaId = useSelector(selectEmeaId);
  const eventClosed = useSelector(selectEventClosed);

  const view = useSelector(selectView)
  const dispatch = useDispatch();
  const handleEventClick = (event) => {
    dispatch(viewEvent(event));
    dispatch(closeEvent(false))
  };
  const datesKeys = sortDates(R.keys(list));
  return (
    <Dialog maxWidth="sm" sx={{display: !eventClosed ? 'none' : "block"}} open={isOpen} onClose={() => dispatch(openDrawer(view, false))}>
      < >
        <Close onClose={() => dispatch(openDrawer(view, false))} />
        <DialogTitle sx={{ ml: 2, mb: 0 }}>
          {isWeek
            ? <Typography variant="caption" sx={{ fontFamily: 'Futura Medium' }}>{viewTitle}</Typography>
            : <Typography>{viewTitle}</Typography>
          }
          {isWeek && <Typography >WEEK {currentWeekNumber(activeRange?.start)}</Typography>}
          {(
            R.keys(list).length > 1
              ? <Typography variant="caption" sx={{ color: 'grey.500' }}>{`${totalEvents} ${isWeek ? "events starting this week" : "events starting this month"}`}</Typography>
              : <Typography variant="caption" sx={{ color: 'grey.500' }}>{`${totalEvents} ${isWeek ? "event starting this week" : "event starting this month"}`}</Typography>)
          }

        </DialogTitle>
        <ContainerTop height={'50vh'}>

          {totalEvents === 0
            && (
              <Placeholder>
                <Typography>No event to display</Typography>
              </Placeholder>
            )
          }
          {R.addIndex(R.map)((dateKey, index) => (
            <div key={`${index}-grouped`}>
              {isWeek
                ? <Typography style={{ marginTop: "20px", fontSize: "20px" }}>
                  {format(new Date(dateKey), 'eeee') + ', ' + format(new Date(dateKey), 'dd')}
                </Typography>
                : <Typography style={{ marginTop: "20px", fontSize: "20px" }}>
                  Week {currentWeekNumber(dateKey)}
                </Typography>
              }
              <Divider sx={{ marginTop: "3px", mb: 1 }} />
              {R.addIndex(R.map)((item, index) => {
                const isHQ = item.event?.extendedProps?.isHQ
                return (
                  <Button key={`${index}-event-list`} fullWidth sx={{textAlign: "start"}} onClick={()=>{handleEventClick(item.event?.extendedProps)}}>
                    <Wrapper>
                        <Tag sx={{ background: item.event?.tagColor }} />
                        <Box style={{ backgroundColor: "#F8F8F8" }} sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Typography sx={{ color: '#000000', fontSize: "15px" }}>{getHQTitle({ isHQ, title: item?.event?.title })}</Typography>
                          <Typography variant="caption" sx={{ color: 'grey.500', textTransform: 'capitalize' }}>
                            {getListEventDescription({
                              item:item.event,
                              indexedCountries,
                              indexedBoutiques,
                              indexedMarkets,
                              emeaId,
                            })}
                          </Typography>
                          <Typography sx={{ color: '#000000', fontSize: "10px" }}>
                            {!isWeek && `Starting ${format(new Date(item?.event?.start || ''), 'dd/MM/yyyy')} - `}
                            Ending {format(new Date(item?.event?.end || ''), 'dd/MM/yyyy')}
                          </Typography>

                        </Box>
                    </Wrapper>
                  </Button>

                );
              }, R.prop(dateKey, list))}
            </div>
          ), datesKeys)}
        </ContainerTop>
        <ContainerBottom sx={{ display: 'flex', justifyContent: 'center', m: 2 }}>
          {children}
        </ContainerBottom>
      </>
    </Dialog >
  )
}

export default List;