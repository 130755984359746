import SvgIcon from "@mui/material/SvgIcon";

export default props => (
  <SvgIcon {...props}>
    <path
      d="M21.3223 6.52225C21.2068 6.40957 21.0512 6.34656 20.889 6.34656C20.7267 6.34656 20.5723 6.40957 20.4556 6.52225L8.57787 18.4L3.54454 13.3667C3.48899 13.3083 3.4212 13.2617 3.34675 13.2299C3.27231 13.198 3.1923 13.1817 3.11119 13.1817C3.03008 13.1817 2.95008 13.198 2.87563 13.2299C2.80119 13.2617 2.7334 13.3083 2.67784 13.3667C2.56562 13.4809 2.50342 13.6345 2.50342 13.7945C2.50342 13.9545 2.56562 14.108 2.67784 14.2223L8.14452 19.7C8.26119 19.8127 8.41565 19.8757 8.57787 19.8757C8.7401 19.8757 8.89563 19.8127 9.01119 19.7L21.3223 7.38891C21.4345 7.27281 21.4979 7.11738 21.4979 6.95558C21.4979 6.79379 21.4345 6.63836 21.3223 6.52225Z"
      fill="white"
    />
  </SvgIcon>
);
