import * as R from "ramda";
import { styled } from '@mui/material/styles';
import { TextField, InputAdornment } from "@mui/material";


const CustomTextfield = styled(TextField)({
  '& label.Mui-focused': {
    color: 'gray',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'gray',
  },
});

const LefttIcon = ({ Icon }) => <Icon />;

const CustomInput = ({
  label,
  placeholder,
  leftIcon,
  value,
  type,
  onChange,
  fullWidth,
  inputProps,
  margin = "dense",
  ...rest
}) => {

  const startAdornment = R.isNil(leftIcon) ? null : (
    <InputAdornment position="start">
      <LefttIcon Icon={leftIcon} />
    </InputAdornment>
  );

  return (
    <CustomTextfield
      margin={margin}
      fullWidth={fullWidth}
      type={type}
      label={label}
      value={value}
      variant="standard"
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
      InputProps={{
        startAdornment,
      }}
      inputProps={inputProps}
      {...rest}
      sx={{
        "& .MuiInput-input": {
          paddingBottom: "10px"
        }
      }}
    />
  );
};

export default CustomInput;