import * as R from 'ramda';
import axios from 'axios';
import { diorEndpoint } from './settings';
import { artefactParser, eventParser, userGroupsParser, normalParser, groupParser } from './utils'

const endpoint = path => `${diorEndpoint}${path}`;

const _patch = path => ({ params, headers }) => {
  return axios.patch(endpoint(path(params)), params, { headers }).then(R.identity);
};

const _get = (path, parser) => ({ params, headers }) => {
  if (R.is(Function, path)) return axios.get(endpoint(path(params)), { params, headers }).then(parser);
  return axios.get(endpoint(path), { params, headers }).then(parser);
};

const _post = path => ({ params, headers }) => {
  return axios.post(endpoint(path), params, { headers}).then(R.identity);
};

const methods = {
  getUserGroups: _get('/authentication_token', userGroupsParser),
  getGroup: _get(({ id }) =>`/groups/${id}`, groupParser),
  getGroups: _get('/groups', normalParser('group')),
  postGroups: _post('/groups'),
  patchGroups: _patch(({ id }) => `/groups/${id}`),
  getMarkets: _get('/markets', artefactParser('markets')),
  postMarket: _post('/markets'),
  patchMarket: _patch(({ id }) => `/markets/${id}`),
  getCountries: _get('/countries', artefactParser('countries')),
  postCountry: _post('/countries'),
  patchCountry: _patch(({ id }) => `/countries/${id}`),
  getBoutiques: _get('/stores', artefactParser('stores')),
  postBoutiques: _post('/stores'),
  patchBoutiques: _patch(({ id }) => `/stores/${id}`),
  getDepartments: _get('/departments', artefactParser('departments')),
  postDepartments: _post('/departments'),
  patchDepartments: _patch(({ id }) => `/departments/${id}`),
  getEvents: _get('/events', normalParser('events')),
  getEvent: _get(({ id }) => `/events/${id}`, eventParser),
  postEvent: _post('/events/populate'),
  patchEvent: _patch(({ id }) => `/events/${id}/populate/only`),
  deleteEvent: _patch(({ id }) => `/events/${id}/populate/only`)
};

const error = method => () => {
  throw new Error(`Unkown method: ${method}`);
};

const main = ({ method, ...rest }) => (methods[method] || error(method))(rest);

R.compose(
  R.forEach(([name, fn]) => (main[name] = fn)),
  R.toPairs,
)(methods);

export default main;
