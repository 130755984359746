import { useState, useEffect } from "react";
import * as R from "ramda";
import { Typography, Input, Box, InputAdornment, TextField } from '@mui/material';
import Puce from '../../Puce/Puce';
import BlackButton from '../../BlackButton/BlackButton';
import useEditDepartment from "../../../hooks/useEditDepartment";
import useAddDepartment from "../../../hooks/useAddDepartment";
import useDepartments from "../../../hooks/useDepartments";
import { getCleanedId } from "../../../utils";
import Check from "../../icons/Check";

const DepartmentForm = ({ editProps = [], variant = "add", changePage }) => {
  const isAdd = variant === "add";
  const { data: departments = [] } = useDepartments();
  const label = R.head(editProps);
  const department = isAdd ? {} : R.prop(label, R.indexBy(R.prop("label"), departments));
  const [formDisabled, setFormDisabled] = useState(true);
  const [title, setTitle] = useState(department?.label || "");
  const [color, setColor] = useState(department?.color || "");
  const [isSavePending, setIsSavePending] = useState(false);
  const [isAddPending, setIsAddPending] = useState(false);
  const editDepartment = useEditDepartment();
  const addDepartment = useAddDepartment();

  useEffect(() => {
    if (R.isEmpty(title, color)) {
      return setFormDisabled(true);
    }
    setFormDisabled(false)
  }, [color])

  const onEdit = params => {
    setIsSavePending(true)
    editDepartment.mutate(params, {
      onSuccess: () => {
        setIsSavePending(false);
        changePage();
      },
      onError: (err) => {
        /* eslint-disable no-console */
        console.error('error:', err.message);
        setIsSavePending(false);
      }
    });
  };

  const onAdd = params => {
    setIsAddPending(true)
    addDepartment.mutate(params, {
      onSuccess: () => {
        setIsAddPending(false);
        changePage();
      },
      onError: (err) => {
        /* eslint-disable no-console */
        console.error('error:', err.message);
        setIsAddPending(false);
      }
    });
  };

  const onReset = () => {
    setTitle(department?.label || "");
    setColor(department?.color || "");
    changePage();
  }

  return (
    <Box maxWidth={405}>
      {isAdd && <Typography variant="h2">Add Department</Typography>}
      {!isAdd && <Typography variant="h2">Edit Department</Typography>}
      <Box my={5}>
        <Input
          fullWidth={true}
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          placeholder="*Add title"
          inputProps={{ maxLength: 30 }}
          variant="standard"
        />
      </Box>
      <Box my={5}>
        <TextField
          fullWidth={true}
          onChange={(e) => setColor(e.target.value)}
          value={color}
          inputProps={{ maxLength: 7 }}
          placeholder='#000000'
          label='*Add Color'
          variant="standard"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Puce color={color} />
              </InputAdornment>
            )
          }}
          sx={{
            "& label": {
              color: 'grey.400',
              "&.Mui-focused": {
                color: 'grey.400'
              }
            }
          }}
        />
      </Box>
      <Box sx={{ display: "flex", gap: "16px" }}>
      <BlackButton
          variant="outlined"
          onClick={onReset}
        >
          CANCEL
        </BlackButton>
        {isAdd && (
          <BlackButton
            startIcon={<Check />}
            disabled={formDisabled}
            onClick={() => onAdd({
              name: title,
              colorCode: color,
              scopeType: "STORES"
            })}
            isLoading={isAddPending}
          >
            CREATE DEPARTMENT
          </BlackButton>
        )}
        {!isAdd && (
          <BlackButton
            startIcon={<Check />}
            disabled={formDisabled}
            onClick={() => onEdit({
              id: getCleanedId(department.id),
              name: title,
              colorCode: color,
            })}
            isLoading={isSavePending}
          >
            SAVE
          </BlackButton>
        )}
      </Box>
    </Box>
  )
}

export default DepartmentForm