import * as R from 'ramda';
import Button from "@mui/material/Button";
import { styled } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

export default styled((props) => (
  <Button
    variant="contained"
    {...R.dissoc('isLoading', props)}
    startIcon={props.isLoading 
      ? <CircularProgress size={16} sx={{ color: 'white'}}  /> 
      : props.startIcon
    }
    disabled={props.isLoading || props.disabled}
    onClick={props.isLoading ? null : props.onClick}
  >
    {props.children}
  </Button>
))`
  border-radius: 35px;
  margin-bottom: 16px;
  font-size: 13px;
  letter-spacing: -0.34px;
  text-transform: uppercase;
`;