import agendaApi from '../api/agenda';
import { useQueryClient, useMutation } from 'react-query';
import useAuth from './useAuth';
import { onEventSuccess } from './utils';

export default () => {
  const headers = useAuth({});
  const queryClient = useQueryClient();

  const ctx = (params) => ({
    method: 'patchEvent',
    params,
    headers,
  });

  return useMutation(params => agendaApi(ctx(params)), { onSuccess: onEventSuccess(queryClient) });
};
