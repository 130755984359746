import * as R from 'ramda';
import { Tabs, Tab as MuiTab, Box, AppBar, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeTab } from "../../ducks/app";
import { selectTab } from "../../selectors/app";
import useUserGroups from '../../hooks/useUserGroups';
import { getIsAdminGroup as getIsAdmin } from '../../lib/permissions';

export const Tab = (props) => {
  const theme = useTheme();
  return <MuiTab sx={{ ...theme.typography.h5, ...props.sx }} {...R.omit(['sx'], props)} />;
};

const Navbar = () => {
  const dispatch = useDispatch();
  const tab = useSelector(selectTab);
  const { data: userGroups = [] } = useUserGroups();

  const isActive = getIsAdmin(userGroups);
  
  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "white", boxShadow: 0, padding: 0 }}
    >
      <Box sx={{ display: "flex", alignItems: "center", userSelect: 'none' }}>
        <img src="/images/dior-star.png"  height='63px' alt="Dior logo" />
        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: { xs: "end", sm: "center" } }}>
          <Tabs
            value={tab}
            onChange={(e, tab) => dispatch(changeTab(tab))}
            textColor="primary"
            indicatorColor="secondary"
          >
            <Tab
              value="agenda"
              label="AGENDA"
            />
            { isActive && 
              <Tab
              value="config"
              label="CONFIGURATION"
              />
            }
          </Tabs>
        </Box>
      </Box>
    </AppBar>
  );
};

export default Navbar;