import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import BlackButton from '../BlackButton/BlackButton';
import Reset from '../icons/Reset';
import Check from '../icons/Check';

const Header = ({ title, label, onClick, onClose }) => {
  return (
    <Box sx={{ display: 'flex', padding: 3, alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography>{title}</Typography>
      <Box>
        <BlackButton
          startIcon={<Check />}
          onClick={onClose}
          sx={{ mr: 1, padding: 2.5 }}
        >
          APPLY
        </BlackButton>
        <BlackButton
          variant='outlined'
          startIcon={<Reset />}
          onClick={onClick}
          sx={{ mr: 1, padding: 2.5 }}
        >
          {label}
        </BlackButton>
      </Box>
    </Box>
  )
}

export default Header