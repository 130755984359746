import { Box, Grid } from '@mui/material'
import Filters from '../Filters/Filters'
import CalendarButtons from '../CalendarButtons/CalendarButtons'
import EventSearch from '../Filters/EventSearch/EventSearch'
import useMediaQuery from '@mui/material/useMediaQuery';

const SubNavBar = () => {
  const breakpoints = useMediaQuery('(min-width:1300px)');

  return (
    <Box>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Filters />
        </Grid>
        <Grid item>
          <CalendarButtons />
        </Grid>
      </Grid>
      {!breakpoints &&
        <Grid item>
          <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '-20px' }}>
            <EventSearch></EventSearch>
          </Box>
        </Grid>}
    </Box>
  )
}

export default SubNavBar


