import { useEffect, useState } from "react";
import * as R from 'ramda';
import { useTheme, Box, TableCell } from "@mui/material";
import { Container } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import Header from './Header';
import Table from "./Table";
import CellEdit from "./CellEdit";
import SideBar, { MARKET, COUNTRY, DEPARTMENT, STORE, GROUPS } from "./SideBar";
import MarketForm from "./Market/MarketForm";
import useMarkets from "../../hooks/useMarkets";
import useEditMarket from "../../hooks/useEditMarket";
import CountryForm from "./Country/CountryForm";
import useEditCountry from "../../hooks/useEditCountry";
import useCountries from "../../hooks/useCountries";
import StoreForm from "./Store/StoreForm";
import useBoutiques from "../../hooks/useBoutiques";
import useEditBoutique from "../../hooks/useEditBoutique";
import DepartmentForm from "./Department/DepartmentForm";
import useDepartments from "../../hooks/useDepartments";
import useEditDepartment from "../../hooks/useEditDepartment";
import GroupsForm from "./Groups/GroupsForm";
import useGroups from "../../hooks/useGroups";
import useEditGroup from "../../hooks/useEditGroup";
import ConfirmDialog from "./Dialog/ConfirmDialog";
import Puce from "../Puce/Puce";

export const DEFAULT_ACTION = 'default';

const Switch = props => {
  const { test, children } = props

  return children.find(child => {
    return child.props.value === test
  })
}

const getMarketRows = markets => {
  if (R.isEmpty(markets)) {
    return []
  }
  const indexedMarkets = R.indexBy(R.prop('id'), markets);

  return R.pipe(
    R.map(({ label, parentId, countryIds }) => [label, R.path([parentId, 'label'], indexedMarkets), R.length(countryIds)]),
    R.prepend(["Market Name", "Relative", "Number of countries"]),
  )(markets);
}

const getCountriesRows = (countries, markets) => {
  if (R.isEmpty(countries)) {
    return []
  }
  const indexedMarkets = R.indexBy(R.prop('id'), markets);
  return R.pipe(
    R.map(({ label, parentId, storeIds }) => [
      label,
      R.path([parentId, 'label'], indexedMarkets),
      R.length(storeIds)
    ]),
    R.prepend(["Country Name", "Relative", "Number of stores"]),
  )(countries);
}

const getStoreRows = (stores, countries) => {
  if (R.isEmpty(stores)) {
    return []
  }
  const indexedCountries = R.indexBy(R.prop('id'), countries);
  return R.pipe(
    R.map(({ label, parentId }) => [label, R.path([parentId, 'label'], indexedCountries)]),
    R.prepend(["Store Name", "Relative"]),
  )(stores);
}

const getDepartmentRows = (departments) => {
  if (R.isEmpty(departments)) {
    return []
  }
  return R.pipe(
    R.map(({ label, color }) => [label, <><Puce color={color} />{color}</> ]),
    R.prepend(["Department Name", "Color"]),
  )(departments);
}
const getGroupsRows = (groups) => {
  if (R.isEmpty(groups)) {
    return []
  }
  const indexedGroups = R.indexBy(R.prop('id'), groups);
  return R.pipe(
    R.map(({ label, id }) => [label, R.path([id, 'permission'], indexedGroups)]),
    R.prepend(["Groups Name", "Permission"]),
  )(groups);
}

const marketDataView = {
  rows: [],
  title: "Market management",
  buttonLabel: "add market",
  page: MARKET,
};
const countryDataView = {
  rows: [],
  title: "Country management",
  buttonLabel: "Add country",
  page: COUNTRY,
};
const storeDataView = {
  rows: [],
  title: "Boutique management",
  buttonLabel: "Add Boutique",
  page: STORE,
};
const departmentDataView = {
  rows: [],
  title: "Department management",
  buttonLabel: "Add Department",
  page: DEPARTMENT,
};
const groupDataView = {
  rows: [],
  title: "Group management",
  buttonLabel: "Add Group",
  page: GROUPS,
};

const Config = () => {
  const theme = useTheme();
  const [dialogOpen, setDialogOpen] = useState(false);

  const { data: markets = [], isLoading: isMarketsLoading } = useMarkets();
  const { data: countries = [], isLoading: isCountriesLoading } = useCountries();
  const { data: stores = [], isLoading: isStoresLoading } = useBoutiques();
  const { data: departments = [], isLoading: isDepartmentsLoading } = useDepartments();
  const { data: groups = [], isLoading: isGroupsLoading } = useGroups();

  const [data, setData] = useState(R.set(R.lensProp('rows'), getMarketRows(markets), marketDataView));
  const [page, setPage] = useState(MARKET);
  const [action, setAction] = useState(DEFAULT_ACTION);
  const [artefact, setArtefact] = useState(null);

  const handleAction = ({ row, action }) => {
    setPage(null);
    setAction(action);
    setArtefact(row);
  }

  const onDelete = ({ row }) => {
    setDialogOpen(true)
    setArtefact(row);
  }

  useEffect(() => {
    if (R.isNil(page)) return setData(null)

    setAction(DEFAULT_ACTION);
    switch (page) {
      case MARKET:
        return setData(R.set(R.lensProp('rows'), getMarketRows(markets), marketDataView))
      case COUNTRY:
        return setData(R.set(R.lensProp('rows'), getCountriesRows(countries, markets), countryDataView))
      case STORE:
        return setData(R.set(R.lensProp('rows'), getStoreRows(stores, countries), storeDataView))
      case DEPARTMENT:
        return setData(R.set(R.lensProp('rows'), getDepartmentRows(departments), departmentDataView))
      case GROUPS:
        return setData(R.set(R.lensProp('rows'), getGroupsRows(groups), groupDataView))
      default:
        return setData(R.set(R.lensProp('rows'), getMarketRows(markets), marketDataView))
    }
  }, [page, R.isEmpty(markets), R.isEmpty(countries), R.isEmpty(stores), R.isEmpty(departments), R.isEmpty(groups), dialogOpen]);

  const deleteAction = `${page}_DELETE`;

  const isLoading = isMarketsLoading || isCountriesLoading || isStoresLoading || isDepartmentsLoading || isGroupsLoading
  return (
    <Box sx={{ display: "grid", grid: "1fr / auto 1fr", gap: theme.spacing(6) }}>
      <SideBar changeView={setPage} view={page} />
      <Container disableGutters={true} maxWidth={false}>
        {isLoading
          ? <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: '100%' }}>
            <CircularProgress />
          </Box>
          : (<Switch test={action}>
            <div value={MARKET}>
              <MarketForm variant={artefact ? "edit" : 'add'} editProps={artefact} changePage={() => setPage(MARKET)} />
            </div>
            <div value={COUNTRY}>
              <CountryForm variant={artefact ? "edit" : 'add'} editProps={artefact} changePage={() => setPage(COUNTRY)} />
            </div>
            <div value={STORE}>
              <StoreForm variant={artefact ? "edit" : 'add'} editProps={artefact} changePage={() => setPage(STORE)} />
            </div>
            <div value={DEPARTMENT}>
              <DepartmentForm variant={artefact ? "edit" : 'add'} editProps={artefact} changePage={() => setPage(DEPARTMENT)} />
            </div>
            <div value={GROUPS}>
              <GroupsForm variant={artefact ? "edit" : 'add'} editProps={artefact} changePage={() => setPage(GROUPS)} />
            </div>
            <div value={DEFAULT_ACTION}>
              {data && (
                <>
                  <Header title={data.title} buttonLabel={data.buttonLabel} onClick={() => handleAction({ action: data.page })} />
                  <Table
                    id={page}
                    rows={data.rows}
                    EndComponent={(props) => {
                      if (R.includes('BWT-EMEA-SharedCalendarAdmin', props.row)) return <TableCell></TableCell>;
                      return (
                        <CellEdit
                          {...props}
                          onDelete={() => onDelete({ row: props.row })}
                          onEdit={() => handleAction({ row: props.row, action: data.page })}
                        />
                      )
                    }}
                  />
                  {R.addIndex(R.map)(({ page, useEdit, useData }, index) => {
                    return (
                      <ConfirmDialog
                        key={`${page}-${index}`}
                        open={dialogOpen && `${page}_DELETE` === deleteAction}
                        useEdit={useEdit}
                        useData={useData}
                        onClose={() => setDialogOpen(false)}
                        label={R.head(artefact || [])}
                      />
                    )
                  }, [
                    { page: MARKET, useEdit: useEditMarket, useData: useMarkets },
                    { page: COUNTRY, useEdit: useEditCountry, useData: useCountries },
                    { page: STORE, useEdit: useEditBoutique, useData: useBoutiques },
                    { page: DEPARTMENT, useEdit: useEditDepartment, useData: useDepartments },
                    { page: GROUPS, useEdit: useEditGroup, useData: useGroups}
                  ])}
                </>
              )}
            </div>
          </Switch>
          )
        }
      </Container>
    </Box>
  );
};

export default Config;
