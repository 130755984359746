import * as R from 'ramda';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { selectDepartmentSelection } from '../../selectors/calendar';
import Puce from '../Puce/Puce';
import Typography from '@mui/material/Typography';
import useDepartments from '../../hooks/useDepartments';
import useIndexedData from '../../hooks/useIndexedData';

const Legend = () => {
  const { data: departments = [] } = useDepartments();
  const indexedDepartments = useIndexedData(useDepartments);
  const departmentIds = useSelector(selectDepartmentSelection)

  return (
    <Box id ="legend" sx={{ display: 'flex', flexWrap: 'wrap', px: 0, pt: 1.5 }}>
      {R.map((id) => {
        const item = R.prop(id, indexedDepartments);
        return (
          <Box key={item.id} component='span' sx={{ alignItem: 'center', paddingRight: 2 }}>
            <Puce color={item.color}/>
            <Typography variant='caption' sx={{ whiteSpace: 'nowrap' }}>{item.label}</Typography>
          </Box>
        )
      }, R.isEmpty(departmentIds) ? R.map(R.prop('id'), departments) : departmentIds)}
    </Box>
  )
}

export default Legend