import SvgIcon from '@mui/material/SvgIcon';

export default (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M16.3503 2.72005L16.3506 2.72013C18.6661 3.39239 20.6076 5.33381 21.2798 7.64937L21.2801 7.65035C21.8192 9.49313 21.7118 10.5776 21.2 11.26L21.1996 11.2606C20.8861 11.6798 20.4328 11.9296 19.9093 12.0743C19.3821 12.22 18.8155 12.25 18.32 12.25H15C13.8492 12.25 13.0676 11.9269 12.5642 11.3632C12.0513 10.7889 11.75 9.87963 11.75 8.54996V5.67996C11.75 5.18449 11.78 4.61742 11.9247 4.08971C12.0683 3.56606 12.3159 3.11323 12.7295 2.80032C13.4232 2.28706 14.4998 2.18153 16.3503 2.72005ZM13.0393 3.20047C12.7239 3.4361 12.5346 3.79487 12.4221 4.1981C12.3089 4.6041 12.26 5.10185 12.26 5.67996V8.55996C12.26 9.72115 12.4784 10.5632 13.0074 11.1007C13.5424 11.6443 14.2808 11.76 15.01 11.76H18.33C18.9036 11.76 19.3987 11.711 19.8044 11.5978C20.2089 11.485 20.5669 11.2961 20.8063 10.9848L20.8079 10.9827C21.0827 10.6215 21.1662 10.1413 21.1523 9.63502C21.1382 9.11974 21.0213 8.50693 20.8204 7.81119L20.8196 7.80875C20.1926 5.67888 18.368 3.84053 16.2338 3.22105C15.472 2.9846 14.8195 2.85996 14.28 2.85996C13.8217 2.85996 13.3742 2.94649 13.0393 3.20047Z" fill="black" stroke="black"/>
<path d="M9.54221 22.1268L9.53966 22.1263C5.66173 21.5006 2.49917 18.3468 1.87362 14.4703L1.8736 14.4702C1.06591 9.47198 4.28535 4.76712 9.2074 3.76039C9.34667 3.73407 9.47676 3.81911 9.51054 3.95277C9.53388 4.0912 9.4476 4.21941 9.31365 4.25136C4.66122 5.20841 1.61428 9.66063 2.38648 14.3902C2.98103 18.0626 5.95763 21.0391 9.63012 21.6335L9.63084 21.6336C14.3825 22.3956 18.8264 19.3351 19.7701 14.6589L19.7708 14.6557C19.7969 14.5215 19.9298 14.4338 20.0619 14.4602L20.0642 14.4607C20.1982 14.4868 20.2858 14.6192 20.2599 14.7511C19.3578 19.1836 15.4863 22.25 11.07 22.25C10.565 22.25 10.0527 22.2118 9.54221 22.1268Z" fill="black" stroke="black"/>
</svg>
  </SvgIcon>
);
