import * as R from 'ramda';
import { format } from 'date-fns'
import { useDispatch, useSelector } from "react-redux";
import MuiTypography from '@mui/material/Typography';
import { selectEmeaId } from "../../../selectors/calendar";
import { viewEvent } from "../../../ducks/calendar";
import { getHQTitle, sortAscEndDates, sortAscStartDates, sortDscStartDates } from '../../Calendars/utils';
import { getListEventDescription } from '../../Calendars/List';
import useIndexedData from '../../../hooks/useIndexedData';
import './EventSearch.css'
import useBoutiques from '../../../hooks/useBoutiques';
import useCountries from '../../../hooks/useCountries';
import { searchEvents, removeDuplicatesEvents } from '../../../utils';
import useMarkets from '../../../hooks/useMarkets';
import { useMemo, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { Box, List, Button, Paper, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import useAllEvents from '../../../hooks/useAllEvents';
import dayjs from 'dayjs';


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(0, 0, 0, 0),
    border: '1px solid #ccc',
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    fontWeight: 100,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '40ch',
      height: '30px',
    },
  },
}));
const CustomList = styled(List)(() => ({
  bgcolor: 'background.paper',
  position: 'relative',
  overflow: 'auto',
  maxHeight: 550,
  "&::-webkit-scrollbar": {
    width: 7
  },
  "&::-webkit-scrollbar-track": {
    marginLeft: 10
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "gray",
    borderRadius: 6
  },
  '& ul': { padding: 0 },
}))
const Wrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  grid: '1fr / auto 1fr',
  gap: theme.spacing(1),
  padding: '5px 8px',
  width: '100%',
  float: 'left',
  minHeight: 29,
  borderRadius: 12
}));

const Typography = styled(MuiTypography)({
  padding: "3px"
});

const Tag = styled(Box)({
  height: '100%',
  width: 6,
  borderRadius: 13,
  paddingRight: 4,
});


const EventSearch = () => {

  const indexedBoutiques = useIndexedData(useBoutiques);
  const indexedCountries = useIndexedData(useCountries);
  const indexedMarkets = useIndexedData(useMarkets);
  const { data: allEvents = [], isLoading: isEventsLoading } = useAllEvents();
  const emeaId = useSelector(selectEmeaId);
  const [term, setTerm] = useState("");
  const searchResult = searchEvents(removeDuplicatesEvents(allEvents), term);
  const endedEvents = sortAscEndDates(R.filter((event) => dayjs(event.end).isBefore(new Date()), searchResult))
  const upComingEvents = sortDscStartDates(R.filter((event) => dayjs(event.start).isAfter(new Date()), searchResult))
  const ongoingEvents = sortAscStartDates(R.filter((event) => dayjs(new Date()).isBetween(event.start, event.end), searchResult))

  const sortSearchedEventByDate = R.concat(R.concat(ongoingEvents, upComingEvents), endedEvents)

  const dispatch = useDispatch();
  const handleEventClick = (event) => {
    dispatch(viewEvent(event));
  };

  const SearchResultList = useMemo(() => {
    if (R.length(term) > 0) {
      return (
        <Paper
          className="search-container"
          sx={{ position: 'absolute', boxShadow: '0px 0px 14px 0px rgb(0 0 0 / 25%)', minWidth: '100%', zIndex: '10' }}
          autoFocus={false}
        >
          <CustomList>
            {isEventsLoading ?
              <CircularProgress
                size={16}
                sx={{
                  margin: 'auto',
                  position: 'relative',
                  display: 'flex'
                }} /> :
              !isEventsLoading && !R.length(searchResult) > 0 ?
                <Typography
                  variant='caption'
                  component='span'
                  sx={{
                    justifyContent: 'center',
                    position: 'relative',
                    display: 'flex'
                  }}
                >
                  {`No results found for "${term}"`}
                </Typography> :
                R.addIndex(R.map)((item, index) => {
                  const isHQ = item.extendedProps?.isHQ
                  return (
                    <Button key={`${index}-event-list`} fullWidth sx={{ textAlign: "start" }} onClick={() => { handleEventClick(item?.extendedProps) }}>
                      <Wrapper>
                        <Tag sx={{ background: item?.tagColor }} />
                        <Box style={{ backgroundColor: "#F8F8F8" }} sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Typography sx={{ color: '#000000', fontSize: "15px" }}>{getHQTitle({ isHQ, title: item?.title })}</Typography>
                          <Typography variant="caption" sx={{ color: 'grey.500', textTransform: 'capitalize' }}>
                            {getListEventDescription({
                              item,
                              indexedCountries,
                              indexedBoutiques,
                              indexedMarkets,
                              emeaId,
                            })}
                          </Typography>
                          <Typography sx={{ color: '#000000', fontSize: "10px" }}>
                            {`Starting ${format(new Date(item?.start || ''), 'dd/MM/yyyy')} - `}
                            Ending {format(new Date(item?.end || ''), 'dd/MM/yyyy')}
                          </Typography>
                        </Box>
                      </Wrapper>
                    </Button>
                  );
                }, sortSearchedEventByDate)}
          </CustomList >
        </Paper>)
    }
  }, [term, isEventsLoading])

  return (
    <>
      {R.length(term) > 0 && <div
        className="search-backdrop"
        onClick={() => {
          setTerm('')
        }}
      ></div>}

      <Search className="search-container">
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search an event ..."
          inputProps={{ 'aria-label': 'search' }}
          id="auto-complete"
          onChange={(event) => setTerm(event.target.value)}
        />
        {SearchResultList}
      </Search>
    </>

  )

}
export default EventSearch