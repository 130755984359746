import Box from '@mui/material/Box';

const ContainerBottom = ({ children, props }) => {
  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'center', mb: 2, mt: 2 }}
      {...props}
    >
      {children}
    </Box>
  );
}

export default ContainerBottom;