import { Box, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import BlackButton from "../BlackButton/BlackButton";

const Header = ({title, buttonLabel, onClick}) => {
  return (
    <Box
      className="Table Header"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="h2" fontSize={24}>{title}</Typography>
      {buttonLabel && (
        <BlackButton
          startIcon={<AddIcon />}
          onClick={onClick}
        >
          {buttonLabel}
        </BlackButton>
      )}
    </Box>
  );
};

export default Header;
