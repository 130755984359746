import * as R from 'ramda';
import currentWeekNumber from 'current-week-number';
import dayjs from 'dayjs';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { getRecursiveChildrenByIds, prepareDataRecursiveIds } from '../../utils';

export const sortDates = R.sort((a, b) => new Date(a) - new Date(b));
export const sortDscStartDates = R.sort((a, b) => new Date(a.start) - new Date(b.start));
export const sortAscStartDates = R.sort((b, a) => new Date(a.start) - new Date(b.start));
export const sortAscEndDates = R.sort((b, a) => new Date(a.end) - new Date(b.end));
export const HQ = 'HQ';
export const getHQTitle = ({ isHQ, title }) => isHQ ? `[${HQ}] ${title || ""}` : title;
const moment = extendMoment(Moment);

export const getCountriesStores = (item, indexedCountries, indexedBoutiques) => R.pipe(
  R.pick(item?.extendedProps?.storeIds),
  R.values,
  R.groupBy(R.prop('parentId')),
  R.mapObjIndexed((values, key) => ({
    ...R.prop(key, indexedCountries),
    storeIds: R.pluck('id', values)
  })),
  R.mergeRight(R.pick(item?.extendedProps?.countryIds)),
  R.values
)(indexedBoutiques)

export const getMarketsLabel = (item, emeaId, indexedMarkets) => R.pluck('label',
  R.map(marketId =>
    R.prop(marketId, indexedMarkets) && R.pick(['label'], R.prop(marketId, indexedMarkets))
  )(R.pipe(R.path(['extendedProps', 'marketIds']), R.flip(R.difference)([emeaId]))(item))
);

export const getActivePeriod = (activeRange, active, inclusive = '[]') => R.pipe(
  R.indexBy(R.path(['extendedProps', 'id'])),
  R.values,
  R.map((event) => ({
    day: R.prop('start', event),
    week: currentWeekNumber(R.prop('start', event)),
    event: event,
  })),

  R.groupBy(R.prop("day")),
  (groupedByDay) => {
    const startDate = new Date(activeRange?.start);
    const endDate = new Date(activeRange?.end);
    return R.pipe(
      R.keys,
      R.filter(day => dayjs(new Date(day)).isBetween(moment(startDate).subtract('1','day'), moment(endDate).subtract('1','day'))),
      sortDates,
      R.flip(R.pick)(groupedByDay)
    )(groupedByDay)
  }
)

const getCountryDescription = (length) => [`Boutiques (${length})`];

const getMarketDescription = (_, { children = [] }, ids) => {
  return R.reduce((acc, { id, label, storeIds = [] }) => {
    if (!ids.has(id)) {
      const boutiqueIds = R.filter(id => ids.has(id))(storeIds);
      if (R.isEmpty(boutiqueIds)) return acc;
      return R.append(
        `${label} (${R.length(boutiqueIds)})`,
        acc
      );
    }
    return R.append(`${label} (All)`, acc)
  }, [])(children);
};

const getCollapseEventsFromMarket = ({ markets, countries, boutiques, events }) => (ids, getDescription) => {
  const { indexedItems, groupedItems } = prepareDataRecursiveIds({ markets, countries, boutiques });
  const groupedEvents = R.groupBy(R.prop('resourceId'))(events);

  const newEvents = R.pipe(
    R.reduce((acc, id) => {
      const childrenEvents = R.pick(getRecursiveChildrenByIds(id, groupedItems, indexedItems, []), groupedEvents || []);
      return {
        ...acc,
        [id]: R.pipe(
          R.values,
          R.flatten,
          R.map(v => {
            return R.pipe(
              R.assoc('resourceId', id),
              R.assoc('children', R.propOr({}, id, groupedItems)),
            )(v)
          })
        )(childrenEvents)
      };
    }, []),
    R.mapObjIndexed((events) => R.groupBy(R.prop('eventId'), events)),
    R.map(R.values),
  )(ids);
  return R.pipe(
    R.map((id) => {
      const events = R.prop(id, newEvents);
      return R.map((events) => {
        const length = R.length(events);
        const event = R.head(events);
        const validStoreIds = new Set(R.filter(R.includes('/api/stores/'))(event.extendedProps?.resourceIds || []));
        const validCountryIds = new Set(R.filter(R.includes('/api/countries/'))(event.extendedProps?.resourceIds || []));
        const description = R.join(', ', getDescription(length, event, new Set([...validStoreIds, ...validCountryIds])));
        return R.assoc('description', description, event)
      }, events)
    }),
    R.flatten,
  )(ids)
}

export const getEnhancedEvents = ({ markets, countries, boutiques, events, collapseItems }) => {
  const collapsedIds = R.keys(R.filter(R.identity)(collapseItems))
  const [marketIds, countryIds] = [
    R.filter(R.includes('/api/markets/'))(collapsedIds),
    R.filter(R.includes('/api/countries/'))(collapsedIds)
  ]
  const getCollapseEvents = getCollapseEventsFromMarket({ markets, countries, boutiques, events });
  const collapsedCountriesEvents = getCollapseEvents(countryIds, getCountryDescription)
  const collapsedMarketEvents = getCollapseEvents(marketIds, getMarketDescription)
  return [...events, ...collapsedCountriesEvents, ...collapsedMarketEvents]
}
