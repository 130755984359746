import { useState, useEffect } from "react";
import * as R from 'ramda';
import List from './List';
import { getActivePeriod } from "./utils";

const WeekListView = (props) => {
  const { api, children, openDrawerContainer, meta, isEventsLoading} = props;
 
  const data = api?.currentDataManager?.data;
  const [activeRange, setActiveRange] = useState({});
  const [viewTitle, setViewTitle] = useState('');
  
  const activeDays = getActivePeriod(activeRange, "week")(meta);

  useEffect(() => {
    setViewTitle(data?.viewTitle);
    setActiveRange(data?.dateProfile?.activeRange);
  }, [R.length(meta) != 0 ? openDrawerContainer : R.length(meta), isEventsLoading])

  return (
    <List 
      isWeek
      viewTitle={viewTitle}
      list={activeDays}
      isOpen={openDrawerContainer}
      activeRange={activeRange}
      totalEvents={R.length(R.flatten(R.values(activeDays)))}
    >
      {children}
    </List>
  );
}

export default WeekListView;
