import * as R from 'ramda';
import { format } from '../lib/dates';

const departmentApiId = '/api/departments/';
const marketApiId = "/api/markets/";
const storeApiId = "/api/stores/";
const countryApiId = "/api/countries/";
const groupApiId = "/api/groups/";

const getFormattedId = prefix => R.map((id) => {
  if (id === '*') return id;
  return R.concat(prefix, id)
})

const getEvent = props => ({
  id: R.prop('id', props),
  title: R.prop('title', props),
  comment: R.prop('comment', props),
  start: R.pipe(R.prop('dateFrom'), stringDate => format(stringDate))(props),
  end: R.pipe(R.prop('dateTo'), stringDate => format(stringDate))(props),
  link: R.prop('attachedLink', props),
  resourceIds: R.prop('dataFrontResourcesIds', props),
  marketIds: R.pipe(R.propOr([], 'markets'), R.pluck('@id'))(props),
  countryIds: R.pipe(R.propOr([], 'countries'), R.pluck('@id'))(props),
  storeIds: R.pipe(R.propOr([], 'stores'), R.pluck('@id'))(props),
  departmentId: R.pipe(R.propOr('', 'department'))(props),
  isHQ: R.prop('hq', props),
  groupId: R.prop('createdBy', props),
})

const mapping = ({
  markets: props => ({
    id: R.prop('@id', props),
    label: R.prop('name', props),
    parentId: R.path(['parentMarket', '@id'], props),
    countryIds: R.propOr([], 'countries', props),
  }),
  countries: props => ({
    id: R.prop('@id', props),
    label: R.prop('name', props),
    parentId: R.prop('market', props),
    storeIds: R.propOr([], 'stores', props),
  }),
  stores: props => ({
    id: R.prop('@id', props),
    label: R.prop('name', props),
    parentId: R.prop('country', props),
  }),
  departments: props => ({
    id: R.prop('@id', props),
    label: R.prop('name', props),
    color: R.prop('colorCode', props),
    scopeType: R.prop('scopeType', props)
  }),
  events: props => ({
    resourceIds: R.prop('dataFrontResourcesIds', props),
    title: R.prop('title', props),
    comment: R.prop('comment', props),
    start: R.pipe(R.prop('dateFrom'), stringDate => format(stringDate), R.flip(R.concat)("T00:00:00"))(props),
    end: R.pipe(R.prop('dateTo'), stringDate => format(stringDate), R.flip(R.concat)("T23:59:59"))(props),
    extendedProps: getEvent(props)
  }),
  event: getEvent,
  group: props => ({
    id: R.concat(groupApiId, `${R.prop('id', props)}`),
    label: R.prop('name', props),
    permission: R.prop('permissionType', props),
    markets: R.pipe(R.prop('markets'), getFormattedId(marketApiId))(props),
    countries: R.pipe(R.prop('countries'), getFormattedId(countryApiId))(props),
    stores: R.pipe(R.prop('stores'), getFormattedId(storeApiId))(props),
    departments: R.pipe(R.prop('departments'), getFormattedId(departmentApiId))(props),
  })
})

export const artefactParser = (mappingKey) => R.pipe(
  R.pathOr([], ['data', 'hydra:member']),
  R.map(R.prop(mappingKey, mapping)),
  R.sortBy(R.prop('label')),
  R.addIndex(R.map)((item, index) => R.assoc('order', R.add(1, index), item)),
  R.map(item => {
    if (item.label === 'EMEA') {
      return R.assoc('order', 0, item)
    }
    return item
  }),
)

export const normalParser = mappingKey => R.pipe(
  R.pathOr([], ['data', 'hydra:member']),
  R.map(R.prop(mappingKey, mapping)),
)

export const eventParser = R.pipe(
  R.propOr({},'data'),
  R.prop('event', mapping)
);

export const userGroupsParser = R.pipe(
  R.pathOr([], ['data', 'groups']),
  R.map(R.prop('group', mapping))
);

export const groupParser = R.pipe(
  R.prop('data'),
  R.prop('group', mapping)
);
