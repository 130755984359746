import { useMemo } from 'react';
import * as R from 'ramda';

export default (useData, key = 'id') => {
  const { data = [], isLoading } = useData();
  
  return useMemo(() => {
    if (isLoading) return null

    return R.indexBy(R.prop(key), data)
  }, [isLoading, data])
};
