import { useState, useEffect, createRef } from "react";
import * as R from 'ramda';
import * as dateFns from 'date-fns';
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import momentPlugin from '@fullcalendar/moment';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { Box, useTheme } from "@mui/material";
import dayjs from 'dayjs';
import { useDispatch, useSelector } from "react-redux";
import "./calendar.css";
import { MONTH, WEEK, DAY } from "../../utils/constants";
import { changeView } from './api';
import FullCalendarHeader from './Header';
import Event, { ListEvent } from './Event';
import { selectStart, selectTerritorySelection, selectView, selectDrawer, selectEmeaId, selectEnd, selectRangeDateSelection} from "../../selectors/calendar";
import { changeDate, viewEvent } from "../../ducks/calendar";
import { getTree } from '../../utils';
import useMarkets from "../../hooks/useMarkets";
import useCountries from "../../hooks/useCountries";
import useBoutiques from "../../hooks/useBoutiques";
import useEvents from "../../hooks/useEvents";
import useUserGroups from "../../hooks/useUserGroups";
import ListWeek from './WeekListView';
import ListMonth from './MonthListView'
import AddEvent from '../Buttons/AddEvent';
import { getEnhancedEvents } from './utils';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import currentWeekNumber from 'current-week-number';

const SIDE_WIDTH = '200px';

const getResources = ({ territoriesSelection, markets, countries, boutiques }) => {
  const selection = new Set(territoriesSelection);
  const getSelectedItems = R.isEmpty(territoriesSelection) ? R.identity : R.filter(item => selection.has(item.id));
  
  const groupedList = R.pipe(
    R.map(
      R.pipe(
        getSelectedItems,
        R.groupBy(R.propOr("root", "parentId"))
      )
    ),
    R.ifElse(
      R.pipe(R.head, R.has('root')),
      R.identity,
      (groupedList) => ([{ root: R.pipe(R.head, R.values, R.unnest)(groupedList) }, ...R.drop(1, groupedList)])
    ),
  )([R.map(R.dissoc('parentId'))(markets), countries, boutiques]);
  return R.map(R.dissoc('parentId'), getTree(groupedList))
};

const getInitialCollapseState = ({ markets, countries, isCollapse }) => {
  if (R.not(isCollapse)) return {};
  return R.pipe(
    R.reduce((acc, market) => ({ ...acc, [market.id]: true }), {}),
    (memo) => R.reduce((acc, country) => ({ ...acc, [country.id]: true }), memo)(countries)
  )(markets);
}

const getResourcesInitiallyExpanded = (userGroups) => {
  return R.pipe(
    R.pluck('stores'),
    R.flatten,
    R.find((id) => id !== '*'),
    R.ifElse(R.isNil, R.always(false), R.always(true)),
  )(userGroups)
}

const Calendar = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const calendarRef = createRef();
  const [api, setApi] = useState(null);
  const mondayDate = dayjs().day(1).$d;
  const view = useSelector(selectView)
  const rangeDate = useSelector(selectRangeDateSelection)
  const territoriesSelection = useSelector(selectTerritorySelection);
  const startDate = useSelector(selectStart);
  const moment = extendMoment(Moment);

  const [filterResourcesWithEvents, setFilterResourcesWithEvents] = useState(false);
  const { data: userGroups = [], isLoading: isUserGroupsLoading } = useUserGroups();
  const { data: markets = [], isLoading: isMarketsloading } = useMarkets();
  const { data: countries = [] } = useCountries();
  const { data: boutiques = [] } = useBoutiques();
  const { data: events = [], isLoading: isEventsLoading } = useEvents();
  const [isExpanded, setIsExpanded] = useState(getResourcesInitiallyExpanded(userGroups));
  const isCollapse = !isExpanded;
  const [collapseItems, setCollapse] = useState(getInitialCollapseState({ markets, countries, isCollapse }));

  const emeaId = useSelector(selectEmeaId);
  const openDrawerContainer = useSelector(selectDrawer(view));

  const [viewTitle, setViewTitle] = useState('');
  const [resources, setResources] = useState();

  const eventsWithDescription = R.map(i => (!i.children && R.split('/', i.resourceId)[2] == "markets" && R.split('/', i.resourceId)[3] != "1")
    ? R.assoc('description', "Market (All)", i)
    : (!i.children && R.split('/', i.resourceId)[2] == "countries")
      ? R.assoc('description', "Boutiques (All)", i)
      : i,
    getEnhancedEvents({
      markets,
      countries,
      boutiques,
      events,
      collapseItems,
    }))

  useEffect(() => {
    if (calendarRef.current) {
      const api = calendarRef.current?.getApi();
      setApi(api);
      setViewTitle(api.getCurrentData().viewTitle);
    }
  }, [calendarRef.current, view]);

  useEffect(() => {
    if (api) {
      api.dispatch(changeView(view));
      setViewTitle(api.getCurrentData().viewTitle)
    }
  }, [view, api, rangeDate[0]?.startDate])

  useEffect(() => {
    if (isUserGroupsLoading) return;
    setIsExpanded(getResourcesInitiallyExpanded(userGroups))
  }, [R.isEmpty(userGroups)])


  useEffect(() => {
    if (isMarketsloading) return;
    setResources(getResources({
      territoriesSelection: R.ifElse(R.isEmpty, R.identity, R.append(emeaId))(territoriesSelection),
      markets,
      countries,
      boutiques,
    }));
    setCollapse(getInitialCollapseState({ markets, countries, isCollapse }));
  }, [territoriesSelection, R.isEmpty(markets), R.isEmpty(countries), R.isEmpty(boutiques), isCollapse, R.isEmpty(userGroups)])

  useEffect(() => {
    const sidebarPlus = document.getElementsByClassName("fc-icon-plus-square");
    const sidebarMinus = document.getElementsByClassName("fc-icon-minus-square");
    // check HTLMCollection is not empty
    if (sidebarPlus.length === 0 || sidebarMinus.length === 0) return;
    // setCollapse(getInitialCollapseState({ markets, countries, isCollapse }));
  });

  const updateCalendarData = () => {
    if (api) {
      setViewTitle(api.getCurrentData().viewTitle)
    }
  };

  useEffect(() => {
    const sidebar = R.head(document.querySelectorAll("tbody .fc-scroller"))

    const checkScrolledToEnd = (e) => {
      const eventTree = e.composedPath()
      const firstEl = R.nth(0, eventTree);
      if (firstEl.className === "fc-icon fc-icon-plus-square" || firstEl.className === "fc-icon fc-icon-minus-square") {
        const element = R.find((el) => {
          return !R.isNil(R.path(['attributes', 'data-resource-id', 'nodeValue'], el));
        }, e.composedPath())
        const id = R.path(['attributes', 'data-resource-id', 'nodeValue'], element);

        setCollapse(R.over(R.lensProp(id), R.not))
      }
    }

    sidebar.addEventListener("click", checkScrolledToEnd);
    return () => {
      sidebar.removeEventListener("click", checkScrolledToEnd);
    };
  }, [api]);

  const renderEventContent = (eventContent) => (
    view === 'listWeek' ? <ListEvent data={eventContent} /> : <Event data={eventContent} />
  );

  const handleEventClick = (data) => {
    const { extendedProps: event } = data.event;
    dispatch(viewEvent(event));
  };

  const handledateSet = (info) => {
    if (dateFns.isEqual(startDate, info.view.currentStart)) return;
    dispatch(changeDate({ start: info.view.currentStart, end: info.view.currentEnd }));
  }
 
  const List = view === WEEK ? ListWeek  : ListMonth;

  return (
    <Box id='calendar' className={(view == MONTH && currentWeekNumber(startDate) % 2 == 0 ) ? "view-month-even" : (view == MONTH && currentWeekNumber(startDate) % 2 != 0 ) ? "view-month-odd":''} pb={2}>
      <Box  id="calendarHeader">
      <FullCalendarHeader
     
        next={view!=DAY ? () => updateCalendarData(api.next()) : null}
        prev={view!=DAY ? () => updateCalendarData(api.prev()) : null}
        today={view!=DAY ? () => updateCalendarData(api.gotoDate(mondayDate)) : null}
        date={viewTitle}
        isEventsLoading={isEventsLoading}
        filterResourcesWithEvents={filterResourcesWithEvents}
        setFilterResourcesWithEvents={setFilterResourcesWithEvents}
        sideWidth={SIDE_WIDTH}
      />
      </Box>
    
      <FullCalendar
        resourcesInitiallyExpanded={isExpanded}
        filterResourcesWithEvents={filterResourcesWithEvents}
        firstDay={view === MONTH ? 0 : 1}
        resourceOrder="order"
        ref={calendarRef}
        height="65vh"
        datesSet={handledateSet}
        eventClick={handleEventClick}
        eventContent={renderEventContent}
        resourceAreaHeaderContent=' '
        plugins={[timeGridPlugin, resourceTimelinePlugin, momentPlugin]}
        schedulerLicenseKey='0840727137-fcs-1665321778'
        events={eventsWithDescription}
        initialEvents={{
          color: theme.palette.common.white,
          textColor: theme.palette.common.black,
        }}
        resources={resources}
        headerToolbar={false}
        initialView={WEEK}
        eventOrderStrict={true}
        views={{
          [MONTH]: {
            type: 'resourceTimelineMonth',
            duration: { months: 1 },
            slotDuration: { day: 1 }, // must be one week
            slotLabelFormat: ['[W] W','DD'],
            titleFormat: { year: 'numeric', month: 'long' },
            slotMinWidth: 0,
            weekNumberCalculation: "ISO",
          },
          [WEEK]: {
            type: 'resourceTimelineWeek',
            slotDuration: { hour: 24 },
            slotLabelFormat: ['ddd DD'],
            displayEventTime: false,
            titleFormat: { year: 'numeric', month: 'long' },
            slotMinWidth: 0,
            weekNumberCalculation: "ISO"
          },
          [DAY]:{
            type: 'resourceTimeline',
            visibleRange:{
              start: rangeDate[0]?.startDate,
              end:moment(rangeDate[0]?.endDate)?.add('1','day')._d
            },
            stickyFooterScrollbar:true,
            slotDuration: { hour: 24 },
            slotLabelFormat: ['ddd DD MMM'],
            displayEventTime: false,
            titleFormat: { year: 'numeric', month: 'long' },
            slotMinWidth: 180,
            weekNumberCalculation: "ISO"
          }, 
        }}
        scrollTime="00:00:00"
        allDaySlot={false}
        resourceAreaWidth={SIDE_WIDTH}
        weekends={true}
        weekNumbers={true}
        nowIndicator={true}
      />
      {openDrawerContainer && (
        <List meta={events} api={api} isEventsLoading={isEventsLoading} openDrawerContainer={openDrawerContainer} territoriesSelection={territoriesSelection}>
          <Box >
            <AddEvent />
          </Box>
        </List>
      )}
    </Box>
  );
}

export default Calendar;
