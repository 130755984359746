import { useState, useEffect } from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import MuiList from '@mui/material/List';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowDown from "../icons/ArrowDown";
import Dialog from './Dialog';
import Header from './Header';
import Puce from '../Puce/Puce';
import { selectDepartmentSelection } from '../../selectors/calendar';
import { changeDepartmentSelection } from '../../ducks/calendar';
import useDepartments from '../../hooks/useDepartments';

const List = styled(MuiList)({
  padding: 0
})

const Item = styled((props) => (
  <Button
    component='li'
    fullWidth={true}
    {...R.omit(['isSelected', 'color'])(props)}
  >
    <Puce color={props.color} />
    {props.isSelected && <DoneIcon sx={{ marginRight: 1 }} />}
    {props.children}
  </Button>
))(({ theme, isSelected }) => ({
  ...theme.typography.button,
  fontSize: 14,
  justifyContent: 'start',
  fontWeight: isSelected ? '700' : '400'
}))

const Departments = () => {
  const [open, setOpen] = useState(false);
  const selection = useSelector(selectDepartmentSelection);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(selection)
  const [selectedLength, setSelectedLength] = useState(0);
  const { data: departments = [], isLoading } = useDepartments();

  useEffect(() => {
    R.length(R.values(selected)) === 0
    ? setSelectedLength("All")
    : setSelectedLength(R.length(R.values(selected)));
  }, [selected])

  const handleSelection = (id, isSelected) => {
    if (isSelected) {
      return setSelected(R.omit([id], selected))
    }
    return setSelected(R.assoc(id, true, selected))
  }

  const handleClose = () => {
    dispatch(changeDepartmentSelection(R.keys(selected)))
    setOpen(false);
  }
  return (
    <>
      <Button size='medium' sx={{ py: 0 }} onClick={() => setOpen(true)} endIcon={isLoading ? <CircularProgress size={14} sx={{ ml: '5px'}} /> : <ArrowDown />}>
        Department ({selectedLength})
      </Button>
      <Dialog open={open} onClose={handleClose}  sx={{ maxHeight: 450, top: 130 }}>
        <Header 
          title={`${selectedLength} selected`}
          label='Reset all'
          onClick={() => setSelected([])}
          onClose={handleClose}
        />
        <Divider sx={{ mb: 4 }} />
        <List sx={{ columnCount: 3, px: 3 }}>
          {R.map(({ id, label, color }) => {
            const isSelected = R.propOr(false, id, selected);
            return (
              <Item
                id={id}
                key={id}
                color={color}
                isSelected={isSelected}
                onClick={() => handleSelection(id, isSelected)}
              >
                {label}
              </Item>
            );
          }, departments)}
        </List>
      </Dialog>
    </>
  );
}

export default Departments;