import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { saveAs } from 'file-saver';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material';
import useAllEvents from '../../hooks/useAllEvents';
import ExcelExportIcon from '../icons/ExcelExport';
import ExcelExportDisabledIcon from '../icons/ExcelExportDisabled';
import useBoutiques from '../../hooks/useBoutiques';
import useCountries from '../../hooks/useCountries';
import useGroups from '../../hooks/useGroups';
import useMarkets from '../../hooks/useMarkets';
import useDepartments from '../../hooks/useDepartments';
import MuiIconButton from '@mui/material/IconButton';
import XlsxPopulate from 'xlsx-populate';
import {
  selectDepartmentSelection,
  selectTerritorySelection,
  selectRangeDateSelection,
  selectEmeaId,
} from '../../selectors/calendar';
import { getFilteredTerritoriesEvents, filterByDepartment } from '../../utils/index';
import dayjs from 'dayjs';

const IconButton = styled(MuiIconButton)(
  ({ theme }) => `
  height: 35px;
  width: 36px;
  margin: 3px;
`,
);
const IconButtonDisabled = styled(MuiIconButton)(
  ({ theme }) => `
  height: 35px;
  width: 36px;
  margin: 3px;
  cursor: auto;
`,
);

const ExcelExport = (props) => {
  const { data: markets = [] } = useMarkets();
  const { data: countries = [] } = useCountries();
  const { data: stores = [] } = useBoutiques();
  const { data: departments = [] } = useDepartments();
  const { data: groups = [] } = useGroups();
  const { data: events = [], isLoading: isEventsLoading } = useAllEvents();
  const departmentSelection = useSelector(selectDepartmentSelection);
  const territoriesSelection = useSelector(selectTerritorySelection);
  const rangeDate = useSelector(selectRangeDateSelection);
  const emeaId = useSelector(selectEmeaId);
  const allEventsFiltred = filterByDepartment(departmentSelection || [])(
    getFilteredTerritoriesEvents(territoriesSelection, emeaId)(events),
  );

  function getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : '';
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }

  var excelData = R.map(
    (event) => {
      let associatedGroupId = R.path(['extendedProps', 'groupId'], event);
      let associatedGroupLabel = R.prop('label', R.find(R.propEq('id', associatedGroupId))(groups));
      let DepartmentId = R.path(['extendedProps', 'departmentId'], event);
      let DepartmentLabel = R.prop('label', R.find(R.propEq('id', DepartmentId))(departments));
      let territoryId = R.prop('resourceId', event);
      let marketLabel =
        R.prop('label', R.find(R.propEq('id', territoryId))(markets)) == 'EMEA'
          ? 'All EMEA'
          : R.prop('label', R.find(R.propEq('id', territoryId))(markets));
      let countryLabel = R.prop('label', R.find(R.propEq('id', territoryId))(countries));
      let storeLabel = R.prop('label', R.find(R.propEq('id', territoryId))(stores));

      return {
        'ASSOCIATED GROUP': associatedGroupLabel,
        DEPARTMENT: DepartmentLabel,
        'EVENT TITLE': event.extendedProps.isHQ ? `[HQ]${event.title}` : event.title,
        'START DATE': new Date(dayjs(event.start).format('YYYY, MM, DD')),
        'END DATE': new Date(dayjs(event.end).format('YYYY, MM, DD')),
        MARKET: marketLabel,
        COUNTRY: countryLabel,
        STORE: storeLabel,
      };
    },
    R.isEmpty(departmentSelection) && R.isEmpty(territoriesSelection) && R.isEmpty(rangeDate)
      ? events
      : (!R.isEmpty(departmentSelection) || !R.isEmpty(territoriesSelection)) &&
        R.isEmpty(rangeDate)
        ? allEventsFiltred
        : !R.isEmpty(rangeDate) && props.events,
  );
  const filtredExcelData = R.reject(
    (item) => item.COUNTRY == undefined && item.MARKET == undefined && item.STORE == undefined,
    excelData,
  );
  let header = [
    'ASSOCIATED GROUP',
    'DEPARTMENT',
    'EVENT TITLE',
    'START DATE',
    'END DATE',
    'MARKET',
    'COUNTRY',
    'STORE',
  ];
  async function saveAsExcel(data, header) {
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(data, header);
      const totalColumns = sheetData[0].length;

      sheet1.cell('A1').value(sheetData);
      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style('bold', true);
      sheet1.column('A').width(40);
      sheet1.column('B').width(20);
      sheet1.column('C').width(50);
      sheet1.column('D').width(30);
      sheet1.column('E').width(30);
      sheet1.column('F').width(20);
      sheet1.column('G').width(20);
      sheet1.column('H').width(30);
      sheet1.range('A1:' + endColumn + '1').style({
        fontColor: 'ffffff',
        horizontalAlignment: 'center',
      });
      sheet1.range('A1:E1').style('fill', '6612d0');
      range.autoFilter();
      sheet1.range('F1:H1').style('fill', '00d0ff');
      sheet1.column("D").style("numberFormat", "dd/mm/yyy");
      sheet1.column("E").style("numberFormat", "dd/mm/yyy");
      sheet1.column("D").style("horizontalAlignment", "left");
      sheet1.column("E").style("horizontalAlignment", "left");
      range.style('border', true);
      return workbook.outputAsync().then((res) => {
        saveAs(res, 'Shared Calendar.xlsx');
      });
    });
  }

  return R.length(filtredExcelData) > 0 ? (
    <IconButton onClick={() => saveAsExcel(filtredExcelData, header)}>
      <ExcelExportIcon title="Export Excel" />
    </IconButton>
  ) : (
    <IconButtonDisabled>
      <ExcelExportDisabledIcon title="Export Excel Disabled" />
    </IconButtonDisabled>
  );
};

export default ExcelExport;
