import { useQuery } from 'react-query';
import agendaApi from '../api/agenda';
import useAuth from './useAuth';

export default () => {
  const headers = useAuth({});

  const ctx = {
    method: 'getDepartments',
    params: {
      _pagination: false
    },
    headers
  };

  const queryKey = `@@agenda/${ctx.method}`;
  return useQuery(queryKey, () => agendaApi(ctx));
};
