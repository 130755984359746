import CloseIcon from "../icons/Close";
import { Box, IconButton } from "@mui/material";


const Close = ({ onClose }) => {
  return (
    <Box display="flex" justifyContent="flex-end" sx={{ mt: 2, mr:2 }}>
      <Box>
        <IconButton onClick={onClose} sx={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

export default Close;