import SvgIcon from '@mui/material/SvgIcon';

export default (props) => (
  <SvgIcon viewBox="8 11 25 13"  {...props}>
      <rect width="51" height="51" rx="40.5" fill="#ffffff00" />
      <path
        d="M25.5 22.5L20.5 27.5L15.5 22.5"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5 18.5L20.5 27.5"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 13.5V20.5"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.5 13.5V20.5"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.5 13.5L12.5 13.5"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
  </SvgIcon>
);
