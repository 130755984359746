import React, { useEffect } from 'react';
import * as R from 'ramda';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Done from '@mui/icons-material/Done';

const Select = ({
  id,
  value,
  label,
  labelAccessor = R.prop('label'),
  items,
  isMultiple,
  onChange,
  selectedValues,
  StartIcon,
  SelectIcon,
  textFieldProps,
  selectProps,
  inputProps,
  disableUnderline
}) => {
  const ref = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [width, setWidth] = React.useState(0);

  const handleChange = (event) => {
    if (R.is(Function)(onChange)) {
      onChange(event.target.value);
    }
  };

  useEffect(() => {
    if (ref.current && open) {
      setWidth(ref.current.clientWidth);
    }
  }, [open]);

  return (
    <TextField
      select
      id={id}
      disabled={R.isEmpty(items)}
      label={label}
      value={isMultiple ? value || [] : value || ""}
      onChange={handleChange}
      variant="standard"
      size="small"
      fullWidth
      InputProps={{
        disableUnderline: disableUnderline,
        startAdornment: StartIcon && (
          <InputAdornment position="start">
            <StartIcon />
          </InputAdornment>
        ),
        ...inputProps,
      }}
      SelectProps={{
        IconComponent: SelectIcon || KeyboardArrowDownIcon,
        ref,
        open,
        autoWidth: true,
        multiple: isMultiple,
        onOpen: () => setOpen(true),
        onClose: () => setOpen(false),
        MenuProps: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
          PaperProps: {
            style: { width },
          },
        },
        sx: {
          "& .MuiInput-input:focus": {
            backgroundColor: 'transparent',
          },
        },
        ...selectProps
      }}
      {...textFieldProps}
    >
      { items && 
        R.map((item) => {
          const id = item.id;
          const label = labelAccessor(item);
          const isSelected = R.has(id, selectedValues);
          return (
            <MenuItem
              key={`select-id-${id}`}
              id={id}
              value={id}
              dense
              aria-label={label}
              title={label}
            >
              {isSelected && <Done sx={{ paddingRight: 1 }} />}
              {label}
            </MenuItem>
          );
        })(items)
      }
    </TextField>
  );
};

export default Select;