import MuiDialog from '@mui/material/Dialog';

const Dialog = (props) => (
  <MuiDialog
    fullWidth={true}
    {...props}
  >
    {props.children}
  </MuiDialog>
);


export default Dialog


