import { useQuery } from 'react-query';
import * as R from 'ramda';
import md5 from 'md5';
import agendaApi from '../api/agenda';
import useDepartments from './useDepartments';
import useIndexedData from './useIndexedData';
import useAuth from './useAuth';
import useUserGroups from './useUserGroups';

const addDepartmentColor = (departments) =>
  R.map((item) =>
    R.set(
      R.lensProp('tagColor'),
      R.pipe(
        R.path(['extendedProps', 'departmentId']),
        (departmentId) => R.prop(departmentId, departments),
        R.prop('color'),
      )(item),
      item,
    ),
  );

const filterByDepartment =
  (departmentIds) =>
  (data = []) => {
    if (R.isEmpty(departmentIds)) return data;
    return R.filter((item) =>
      R.includes(R.path(['extendedProps', 'departmentId'], item), departmentIds),
    )(data);
  };

const removeEmeaHqEvents = (isUserEmea) => (events) =>
  R.filter(
    (event) =>
      !(
        R.split('/', event.resourceId)[2] == 'markets' &&
        R.split('/', event.resourceId)[3] == '1' &&
        !isUserEmea &&
        event?.extendedProps?.isHQ
      ),
    events,
  );

const resourceIdsToResourceId = R.reduce((acc, event) => {
  const today = +new Date();
  const eventId = md5(
    `${today}${R.join(' ', event.resourceIds)}${R.path(['extendedProps', 'title'], event)}${R.path(['extendedProps', 'start'], event)}`,
  );
  return [
    ...acc,
    ...R.map((resourceId) => ({ eventId, resourceId, ...R.dissoc('resourceIds')(event) }))(
      event.resourceIds,
    ),
  ];
}, []);

const method = 'getEvents';


export default ({ enabled = true } = {}) => {
  const headers = useAuth({});

  const ctx = () => {
    return {
      method,
      params: {
        _pagination: false,
        includeHq: true,
      },
      headers,
    };
  };

  const queryKey = `@@agenda/${method}`;
  const departments = useIndexedData(useDepartments);

  const data = useQuery([queryKey], () => agendaApi(ctx()), {
    enabled: enabled,
    refetchOnWindowFocus: true,
  });

  useQuery([queryKey], () => agendaApi(ctx()), {
    enabled: enabled,
  });
  const userGroups = useUserGroups();
  const isUserEmea = R.includes(
    'EMEA',
    R.map((group) => R.split(' ', group.shortLabel)[0], userGroups.data),
  );

  return R.evolve({
    data: R.pipe(
      filterByDepartment([]),
      addDepartmentColor(departments || {}),
      resourceIdsToResourceId,
      removeEmeaHqEvents(isUserEmea),
    ),
  })(data);
};
// import axios from 'axios';
// import { diorEndpoint } from '../api/settings';
// import useAuth from './useAuth';
// export const useAllEvents = () => {
//   const headers = useAuth({});
//   axios.get(`${diorEndpoint}/events`, { headers }).then((res) => {
//     console.log(res.data);
//   });
// };
