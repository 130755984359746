import { useEffect, useState } from "react";
import * as R from 'ramda';
import * as dateFns from 'date-fns';
import md5 from "md5";
import Drawer from "../Drawer/Drawer";
import EventForm from "../EventForm/EventForm";
import BlackButton from '../BlackButton/BlackButton';
import useAddEvent from "../../hooks/useAddEvent";
import AddIcon from "@mui/icons-material/Add";
import useMarkets from "../../hooks/useMarkets";
import useCountries from "../../hooks/useCountries";
import useBoutiques from "../../hooks/useBoutiques";
import useEvents from "../../hooks/useEvents";
import useGroups from '../../hooks/useGroups';
import useUserGroups from "../../hooks/useUserGroups";
import { cleanIdRegex } from '../../utils';
import { getCanCreateEvent, getPermissions } from "../../lib/permissions";
import { Dialog } from "@mui/material";

const AddEvent = () => {
  const [open, setOpen] = useState({});
  const [id, setId] = useState();

  const handleOpen = id => isOpen => {
    setId(id);
    setOpen(openDrawers => ({ ...openDrawers, [id]: isOpen }))
  };

  useEffect(() => {
    if (id) {
      setOpen(R.filter(R.identity, open))
    }
  }, [id]);

  const { isLoading: isMarketsloading } = useMarkets();
  const { isLoading: isCountriesloading } = useCountries();
  const { isLoading: isBoutiquesloading } = useBoutiques();
  const { isLoading: isEventsLoading } = useEvents();
  const { isLoading: isGroupsLoading } = useGroups();
  const addEvent = useAddEvent();

  const { data: userGroups = [] } = useUserGroups();
  const permissions = getPermissions(userGroups);
  const hasCreateEvent = getCanCreateEvent(permissions);

  const onAddEvent = id => form => {
    if (R.isNil(form.title)) return;
    const departmentId = R.replace(cleanIdRegex, '', form.departementId)
    const marketsIds = R.map(R.replace(cleanIdRegex, ''))(form.marketIds)
    const countriesIds = R.map(R.replace(cleanIdRegex, ''))(form.countryIds)
    const storesIds = R.map(R.replace(cleanIdRegex, ''))(form.boutiqueIds)
    const groupId = R.replace(cleanIdRegex, '', form.groupId)

    addEvent.mutate({
      resourcesIds: form.ids,
      hq: form.isHQ,
      groupId: Number(groupId),
      eventTitle: form.title,
      comment: form.comment,
      eventAttachedLink: form.link,
      startDate: dateFns.formatISO(form.startDate),
      endDate: dateFns.formatISO(form.endDate),
      departmentId: Number(departmentId),
      marketsIds: R.map(Number)(marketsIds),
      countriesIds: R.map(Number)(countriesIds),
      storesIds: R.map(Number)(storesIds),
    }, {
      onSuccess: () => {
        handleOpen(id)(false);
      }
    });
  }

  if (!hasCreateEvent) return null

  return (
    <>
      <BlackButton startIcon={<AddIcon />} onClick={() => handleOpen(md5(new Date()))(true)}>
        Add event
      </BlackButton>
      <Dialog
        fullWidth
        sx={{ top: '10%', width: '75%', height: '85%', left: '12.5%' }}
        maxWidth="xl"
        open={R.prop(id, open) || false}
        onClose={() => handleOpen(id)(false)}
        isloading={isMarketsloading || isCountriesloading || isBoutiquesloading || isEventsLoading || isGroupsLoading || undefined}
      >
        <EventForm variant='create' onClose={() => handleOpen(id)(false)} onAddEvent={onAddEvent(id)} />
      </Dialog>
    </>
  );
}

export default AddEvent;
