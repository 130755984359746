import * as R from 'ramda';
import { useQuery } from 'react-query';
import agendaApi from '../api/agenda';
import useAuth from './useAuth';
import { cleanIdRegex } from '../utils';
import { getEvolvedGroup } from './utils';

export default ({ id = '', isCleanerApply = true }) => {
  const headers = useAuth({});
  const _id = isCleanerApply && !R.isNil(id) ? R.replace(cleanIdRegex, '', id): id;

  const ctx = {
    method: 'getGroup',
    params: {
      id: _id,
    },
    headers
  };

  const queryKey = `@@agenda/${ctx.method}`;
  const data = useQuery([queryKey, _id], () => agendaApi(ctx), { enabled: !!_id });
  return R.evolve({
    data: getEvolvedGroup
  })(data)
};
