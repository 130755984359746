import agendaApi from '../api/agenda';
import { useQueryClient, useMutation } from 'react-query';
import useAuth from './useAuth';

export default () => {
  const headers = useAuth({});
  const queryClient = useQueryClient();
  
  const onSuccess = () => {
    return queryClient.invalidateQueries(`@@agenda/getDepartments`);
  }

  const ctx = (params) => ({
    method: 'patchDepartments',
    params,
    headers,
  });

  return useMutation(params => agendaApi(ctx(params)), { onSuccess });
};
