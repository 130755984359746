import { useState, useEffect } from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import MuiList from '@mui/material/List';
import ArrowDown from "../icons/ArrowDown";
import Dialog from './Dialog';
import Header from './Header';
import CircularProgress from '@mui/material/CircularProgress';
import { selectEmeaId, selectTerritorySelection } from '../../selectors/calendar';
import { changeTerritorySelection } from '../../ducks/calendar';
import { getIds } from './utils';
import { intersperse } from '../../utils';
import useMarkets from '../../hooks/useMarkets';
import useCountries from '../../hooks/useCountries';
import useBoutiques from '../../hooks/useBoutiques';

const Wrapper = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '0.5fr 0.5fr 1fr',
  gap: 16
})

const List = styled(MuiList)(({ theme }) => ({
  paddingTop: theme.spacing(2) 
}))

const Item = styled((props) => (
  <Button
    component='li'
    fullWidth={true}
    startIcon={props.isSelected && <DoneIcon />}
    {...R.omit(['isSelected'])(props)}
  >
    {props.children}
  </Button>
))(({ theme, isSelected }) => ({
  ...theme.typography.button,
  fontSize: 14,
  justifyContent: 'start',
  fontWeight: isSelected ? '700' : '400'
}))

const getGroupedArtefact = children => R.pipe(
  R.pluck('id'),
  (ids) => {
    const groupedChildren = R.groupBy(R.prop('parentId'), children);
    return R.map(id => R.prop(id, groupedChildren))(ids);
  },
)

const Territories = () => {
  const [open, setOpen] = useState(false);
  const selection = useSelector(selectTerritorySelection);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(selection)
  const [selectedLength, setSelectedLength] = useState(0);
  const { data = [], isLoading: isMarketsloading } = useMarkets();
  const { data: countries = [], isLoading: isCountriesloading } = useCountries();
  const { data: boutiques = [], isLoading: isBoutiquesloading } = useBoutiques();
  const emeaId = useSelector(selectEmeaId);
  const markets = R.reject(({ id }) => emeaId === id, data);

  useEffect(() => {
    setSelected(R.reduce((acc, id) => {
      return ({ ...acc, [id]: true })
    }, {}, selection));
  }, [selection])

  useEffect(() => {
    R.length(R.values(selected)) === 0
      ? setSelectedLength("All")
      : setSelectedLength(R.length(R.values(selected)));
  }, [selected])
  
  const handleSelection = (selectedId, isSelected) => {
    const ids = getIds(selectedId, { markets, countries, boutiques }, { isSelected, selected });
    if (isSelected) {
      return setSelected(R.omit(ids, selected))
    }
    return setSelected(R.reduce((acc, selectedId) => R.assoc(selectedId, true, acc), selected, ids))
  }

  const handleClose = () => {
    dispatch(changeTerritorySelection(R.keys(selected)))
    setOpen(false);
  }
  const groupedCountries =  R.reject(R.isNil)(getGroupedArtefact(countries)(markets));
  const groupedBoutiques = R.reject(R.isNil)(getGroupedArtefact(boutiques)(R.flatten(groupedCountries)));
  const isLoading = R.any(loading => loading === true, [isMarketsloading, isCountriesloading, isBoutiquesloading])
  return (
    <>
      <Button size='medium' sx={{ py: 0 }} onClick={() => setOpen(true)} endIcon={isLoading ? <CircularProgress size={14} sx={{ ml: '5px'}} /> : <ArrowDown />}>
        Territory ({selectedLength})
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <Header 
          title={`${selectedLength} selected`}
          label='Reset all'
          onClick={() => setSelected([])}
          onClose={handleClose}
        />
        <Divider sx={{ mb: 4 }} />
        {isLoading
          ? <Box display='flex' justifyContent='center'><CircularProgress size={40} /></Box>
          : (
            <Wrapper sx={{ px: 3 }}>
              <Box>
                <Typography variant="h4" sx={{ paddingLeft: 1 }}>
                  MARKETS
                </Typography>
                <List>
                  <Item
                    id='allmarket'
                    key='allmarket'
                    isSelected={R.isEmpty(selected)}
                    onClick={() => setSelected([])}
                  >
                    All markets
                  </Item>
                  {R.map(({ id, label }) => {
                    const isSelected = R.propOr(false, id, selected);
                    return (
                      <Item
                        id={id}
                        key={`markets-${id}`}
                        isSelected={isSelected}
                        onClick={() => handleSelection(id, isSelected)} 
                      >
                        {label}
                      </Item>
                    );
                  }, markets)}
                </List>
              </Box>
              <Box>
                <Typography variant="h4" sx={{ paddingLeft: 1 }}>
                  COUNTRIES
                </Typography>
                <List>
                  {intersperse(index => <Divider key={`${index}-countries}`} />, R.map((country) => {
                    return R.map(({ id, label }) => {
                      const isSelected = R.propOr(false, id, selected);
                      return (
                        <Item
                          id={id}
                          key={`country-${id}`}
                          isSelected={isSelected}
                          onClick={() => handleSelection(id, isSelected)}
                        >
                          {label}
                        </Item>
                      );
                    }, country)
                  }, groupedCountries))}
                </List>
              </Box>
              <Box>
                <Typography variant="h4" sx={{ paddingLeft: 1 }}>
                  BOUTIQUES
                </Typography>
                <List sx={{ columnCount: 2 }}>
                  {intersperse(index => <Divider key={`${index}-Boutiques}`} />, R.map((boutique) => {
                    return R.map(({ id, label }) => {
                      const isSelected = R.propOr(false, id, selected);
                      return (
                        <Item
                          id={id}
                          key={`boutique-${id}`}
                          isSelected={isSelected}
                          onClick={() => handleSelection(id, isSelected)}
                        >
                          {label}
                        </Item>
                      );
                    }, boutique)
                  }, groupedBoutiques))}
                </List>
              </Box>
            </Wrapper>
          )
        }
      </Dialog>
    </>
  );
}

export default Territories;