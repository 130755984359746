
import SvgIcon from '@mui/material/SvgIcon';

export default (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M12 22.25C6.34614 22.25 1.75 17.6539 1.75 12C1.75 6.34614 6.34614 1.75 12 1.75C17.6539 1.75 22.25 6.34614 22.25 12C22.25 17.6539 17.6539 22.25 12 22.25ZM12 2.25C6.62386 2.25 2.25 6.62386 2.25 12C2.25 17.3761 6.62386 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62386 17.3761 2.25 12 2.25Z" fill="black" stroke="black"/>
<path d="M7.99218 20.7499L8.64276 20.734L8.45983 20.1094C6.91671 14.8412 6.91671 9.15878 8.45983 3.89055L8.64276 3.26602L7.99218 3.25015C7.85525 3.24681 7.74999 3.14037 7.74999 3C7.74999 2.86614 7.86613 2.75 7.99999 2.75H8.99999C9.07538 2.75 9.15545 2.78938 9.20396 2.85197C9.25364 2.92609 9.26165 3.00702 9.23774 3.07574L9.2377 3.07572L9.23556 3.08214C7.32144 8.83471 7.32152 15.1654 9.23548 20.9276L9.23543 20.9276L9.23774 20.9343C9.26103 21.0012 9.25403 21.0798 9.2077 21.1523C9.15741 21.2106 9.07531 21.25 8.99999 21.25H7.99999C7.86613 21.25 7.74999 21.1339 7.74999 21C7.74999 20.8596 7.85525 20.7532 7.99218 20.7499Z" fill="black" stroke="black"/>
<path d="M14.9356 21.2418L14.9269 21.2386L14.9181 21.2357C14.784 21.1909 14.7191 21.0446 14.7606 20.9289L14.7608 20.929L14.7645 20.9179C16.6786 15.1653 16.6785 8.83462 14.7645 3.07239L14.7644 3.07188C14.7202 2.93925 14.7946 2.79661 14.9165 2.75491C15.0605 2.70912 15.1947 2.78516 15.2357 2.90811L15.2358 2.90835C17.1915 8.76566 17.1914 15.2144 15.2358 21.0614L15.2336 21.0682L15.2315 21.075C15.1984 21.1844 15.1001 21.25 15 21.25C14.9624 21.25 14.945 21.2454 14.9356 21.2418Z" fill="black" stroke="black"/>
<path d="M12 17.2C9.21 17.2 6.43 16.81 3.75 16.02C3.74 16.42 3.41 16.75 3 16.75C2.59 16.75 2.25 16.41 2.25 16V15C2.25 14.76 2.37 14.53 2.56 14.39C2.76 14.25 3.01 14.21 3.24 14.29C8.89 16.17 15.12 16.17 20.77 14.29C21 14.21 21.25 14.25 21.45 14.39C21.65 14.53 21.76 14.76 21.76 15V16C21.76 16.41 21.42 16.75 21.01 16.75C20.6 16.75 20.27 16.43 20.26 16.02C17.57 16.81 14.79 17.2 12 17.2Z" fill="black"/>
<path d="M21 9.74999C20.92 9.74999 20.84 9.73999 20.76 9.70999C15.11 7.82999 8.88003 7.82999 3.23003 9.70999C2.83003 9.83999 2.41003 9.62999 2.28003 9.23999C2.16003 8.83999 2.37003 8.41999 2.76003 8.28999C8.72003 6.29999 15.28 6.29999 21.23 8.28999C21.62 8.41999 21.84 8.84999 21.7 9.23999C21.61 9.54999 21.31 9.74999 21 9.74999Z" fill="black"/>
</svg>
  </SvgIcon>
);