import SvgIcon from '@mui/material/SvgIcon';

export default (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 20 16" fill="none">
      <path
        d="M8.66177 4.54546C8.66177 6.09256 7.41418 7.34093 5.88234 7.34093C4.3505 7.34093 3.10291 6.09256 3.10291 4.54546C3.10291 2.99836 4.3505 1.75 5.88234 1.75C7.41418 1.75 8.66177 2.99836 8.66177 4.54546Z"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M0.75 12.5C0.75 11.2574 1.75736 10.25 3 10.25H7C7.59509 10.25 8.72652 10.5383 9.72125 11.0465C10.2103 11.2964 10.6185 11.576 10.8939 11.8571C11.1746 12.1437 11.25 12.36 11.25 12.5V14.75H0.75V12.5Z"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M7.74988 11.5C7.74988 10.8096 8.30952 10.25 8.99988 10.25H19.2499V14.75H7.74988V11.5Z"
        fill="white"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M16.897 4.54546C16.897 6.09256 15.6494 7.34093 14.1176 7.34093C12.5857 7.34093 11.3381 6.09256 11.3381 4.54546C11.3381 2.99836 12.5857 1.75 14.1176 1.75C15.6494 1.75 16.897 2.99836 16.897 4.54546Z"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M8.74988 12.5C8.74988 11.2574 9.75724 10.25 10.9999 10.25H16.9999C18.2425 10.25 19.2499 11.2574 19.2499 12.5V14.75H8.74988V12.5Z"
        stroke="black"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
);
