import SvgIcon from "@mui/material/SvgIcon";

export default props => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M12.2366 3.83C10.4288 3.83647 8.66536 4.39447 7.18282 5.42947L7.38443 4.51548L7.62638 3.17139C7.65326 3.01467 7.61829 2.85342 7.52824 2.72258C7.43684 2.59176 7.29842 2.5019 7.1425 2.47246C7.06454 2.4569 6.98255 2.4571 6.90459 2.47307C6.82664 2.48902 6.75136 2.52042 6.68549 2.56545C6.61829 2.61046 6.56185 2.6682 6.51884 2.7353C6.47448 2.80241 6.4449 2.87753 6.43011 2.95634L5.62367 7.28432L10.0726 8.10421C10.2124 8.10081 10.3482 8.04971 10.4557 7.95932C10.5632 7.86893 10.6371 7.74464 10.664 7.6069C10.6801 7.52831 10.6788 7.44741 10.664 7.36889C10.6479 7.29038 10.6156 7.21581 10.5713 7.14953C10.5256 7.08325 10.4678 7.02659 10.4006 6.98281C10.3347 6.93903 10.2594 6.90903 10.1801 6.89453L8.83605 6.65259L7.84142 6.50474C9.18013 5.51591 10.8132 5.00598 12.4758 5.05692C14.1398 5.10788 15.738 5.71675 17.0135 6.78566C18.2904 7.85457 19.1694 9.32144 19.5108 10.9505C19.8522 12.5795 19.6358 14.276 18.8966 15.7674C18.1587 17.2587 16.9396 18.4582 15.4356 19.1731C13.9329 19.888 12.2326 20.0766 10.6102 19.7088C8.98658 19.341 7.53361 18.438 6.48522 17.1451C5.43817 15.8521 4.85485 14.2443 4.83066 12.58C4.83066 12.4041 4.7621 12.2352 4.63845 12.1095C4.51479 11.9839 4.34812 11.9115 4.17205 11.908C3.99328 11.908 3.82258 11.9788 3.69623 12.1048C3.57123 12.2309 3.5 12.4018 3.5 12.58C3.5 14.3112 4.01345 16.0034 4.97582 17.4427C5.93819 18.8819 7.30512 20.0035 8.90459 20.6653C10.5041 21.3271 12.2648 21.4997 13.9624 21.161C15.66 20.8223 17.2192 19.9876 18.4423 18.7625C19.6654 17.5374 20.4974 15.9771 20.8334 14.2788C21.1694 12.5806 20.9947 10.8209 20.3307 9.22222C19.6654 7.62359 18.5418 6.25795 17.1009 5.29811C15.6614 4.33825 13.9678 3.82733 12.2366 3.83Z" fill="black"/>
  </SvgIcon>
);




