import * as R from 'ramda';
import { useQuery } from 'react-query';
import agendaApi from '../api/agenda';
import useAuth from './useAuth';
import useBoutiques from './useBoutiques';

export default () => {
  const headers = useAuth({});

  const ctx = {
    method: 'getCountries',
    params: {
      _pagination: false
    },
    headers
  };

  const queryKey = `@@agenda/${ctx.method}`;
  const { data: boutiques = [] } = useBoutiques();
  const data = useQuery(queryKey, () => agendaApi(ctx));
  const groupedStores = R.groupBy(R.prop('parentId'), boutiques);

  return R.evolve({
    data: (data = []) => R.map((country) => R.assoc('storeIds', R.pipe(R.propOr([], country.id), R.pluck('id'))(groupedStores), country))(data)
  })(data)
};
