import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';

const ContainerTop = ({ children, props, height }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        height,
        padding: theme.spacing(0, 2, 4, 4),
        mr: 1,
        overflowY: 'auto',
        "&::-webkit-scrollbar": {
          width: 7
        },
        "&::-webkit-scrollbar-track": {
          marginLeft: 10
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "gray",
          borderRadius: 6
        }
      }}
      {...props}
    >
      {children}
    </Box>
  );
}

export default ContainerTop;