import { useState, useEffect } from 'react';
import * as React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { Typography, Box, Button, CircularProgress, ButtonGroup, TextField } from '@mui/material';
import dayjs from 'dayjs';
import Backdrop from '@mui/material/Backdrop';
import Divider from '@mui/material/Divider';
import DeleteForeverIcon from '@mui/icons-material/DeleteForeverOutlined';
import DialogActions from '@mui/material/DialogActions';
import Menu from '@mui/material/Menu';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import AddCircle from '../icons/EditIcons/AddCircle';
import DialogTitle from '@mui/material/DialogTitle';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import DatePicker from '../DatePicker/DatePicker';
import AssociatedGroupIcon from '../icons/AssociatedGroup';
import Slide from '@mui/material/Slide';
import Puce from '../Puce/Puce';
import Input from '../Input/Input';
import BlackButton from '../BlackButton/BlackButton';
import EventTitle from './EventTitle';
import Chips from '../Chips/Chips';
import LinkIcon from '../icons/EditIcons/AttachLinkIcon';
import Check from '../icons/Check';
import ArrowLong from '../icons/ArrowLong';
import MarketIcon from '../icons/EditIcons/marketIconEdit';
import CountryIcon from '../icons/EditIcons/CountriesIconEdit';
import BoutiqueIcon from '../icons/EditIcons/BoutiquesIconEdit';
import ArrowIcon from '@mui/icons-material/ArrowBackIos';
import Close from './Close';
import Dialog from './Dialog';
import { getLeaves } from '../../utils';
import useMarkets from '../../hooks/useMarkets';
import useCountries from '../../hooks/useCountries';
import useBoutiques from '../../hooks/useBoutiques';
import useGroups from '../../hooks/useGroups';
import { URL_Format } from '../../utils/constants';
import useDepartments from '../../hooks/useDepartments';
import { selectEmeaId } from '../../selectors/calendar';
import ContainerBottom from '../Drawer/ContainerBottom';
import useUserGroups from '../../hooks/useUserGroups';
import { styled } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import MuiArrowRight from '../icons/ArrowRight';
import MuiArrowRightGray from '../icons/EditIcons/ArrowRight';
import MuiList from '@mui/material/List';
import { svgCss } from '../icons';
import {
  getCanCreateHQEvent,
  getWritePermissions,
  getWriteGroups,
  getIsAdminGroup,
  getAdminId,
} from '../../lib/permissions';
import moment from 'moment';

const List = styled(MuiList)({
  padding: 0,
});

const ArrowRight = styled(MuiArrowRight)(svgCss);
const ArrowRightGray = styled(MuiArrowRightGray)(svgCss);
const Item = styled((props) => (
  <Button
    component='li'
    fullWidth={true}
    {...R.omit(['isSelected', 'color'])(props)}
  >
    {props.isSelected && <DoneIcon sx={{ marginRight: 1 }} />}
    {props.children}
  </Button>
))(({ theme, isSelected }) => ({
  ...theme.typography.button,
  fontSize: '1.7vh',
  justifyContent: 'start',
  fontWeight: isSelected ? '700' : '400'
}))

const getDefaultState = (ids, scopeIds = [], items = [], selectFirstItem = false) => {
  const indexedItems = R.indexBy(R.prop('id'), items);
  const list = R.head(scopeIds) === '*' ? indexedItems : R.pick(scopeIds, indexedItems);
  if (R.isEmpty(list)) {
    return {
      selected: {},
      list: {},
      disabled: true,
    };
  }
  // mode edit
  if (!R.isEmpty(ids)) {
    return {
      selected: R.pick(ids, list),
      list,
    };
  }

  // mode create
  if (R.length(R.keys(list)) === 1 && selectFirstItem) {
    const item = R.head(R.values(list));
    return {
      selected: { [item.id]: item },
      list,
      disabled: true,
    };
  }
  return {
    selected: {},
    list,
  };
};

const getPartialCollection = (emeaId) => (selected) =>
  R.pipe(R.groupBy(R.prop('parentId')), R.pick([...R.keys(selected), emeaId]), R.values, R.unnest);


const EventForm = ({
  onClose,
  onAddEvent,
  onDelete,
  idGroupCreator,
  isDeletePending,
  isEditPending,
  onEdit,
  variant,
  editProps = {},
}) => {
  const { departmentId, marketIds = [], countryIds = [], storeIds: boutiqueIds = [] } = editProps;
  const isCreate = variant === 'create';
  const isEdit = variant === 'edit';
  const [formDisabled, setFormDisabled] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [title, setTitle] = useState(R.defaultTo('', editProps.title));
  const [comment, setComment] = useState(R.defaultTo('', editProps.comment));
  const [isHQ, setIsHQ] = useState(R.defaultTo(false, editProps.isHQ));
  const [confirmeOpen, setConfirmeOpen] = useState(false);

  const [startDate, setStartDate] = useState(R.defaultTo(null, editProps.start));
  const [endDate, setEndDate] = useState(R.defaultTo(null, editProps.end));
  const [link, setLink] = useState(R.defaultTo('', editProps.link));

  const { data: userGroups = [] } = useUserGroups();
  const { data: allGroups = [] } = useGroups();
  const isAdmin = getIsAdminGroup(userGroups);
  const adminId = getAdminId(userGroups);
  const groups = getWriteGroups(isAdmin ? allGroups : userGroups);
  const permissions = getWritePermissions(groups);
  const canCreateHQEvent = getCanCreateHQEvent(permissions);

  const [groupId, setGroupId] = useState(
    isAdmin ? idGroupCreator || adminId : R.pipe(R.head, R.prop('id'))(groups),
  );
  const slectedGroup = R.find(R.propEq('id', groupId))(groups)?.shortLabel;

  const group = R.find(R.propEq('id', groupId))(allGroups);

  const { data: defaultMarkets = [] } = useMarkets();
  const { data: defaultCountries = [] } = useCountries();
  const { data: defaultBoutiques = [] } = useBoutiques();
  const { data: defaultDepartments = [] } = useDepartments();

  const [markets, setMarkets] = useState(
    getDefaultState(marketIds, R.prop('markets')(group), defaultMarkets, true),
  );
  const [countries, setCountries] = useState(
    getDefaultState(countryIds, R.prop('countries')(group), defaultCountries),
  );
  const [boutiques, setBoutiques] = useState(
    getDefaultState(boutiqueIds, R.prop('stores')(group), defaultBoutiques),
  );

  const departementScopeIds = R.prop('departments')(group);
  const indexedDepartments = R.indexBy(R.prop('id'), defaultDepartments);
  const departmentsList =
    R.head(departementScopeIds) === '*'
      ? indexedDepartments
      : R.pick(departementScopeIds, indexedDepartments);
  const [departments, setSelectedDepartements] = useState({
    selected: R.defaultTo(R.head(R.values(departmentsList)), R.prop(departmentId, departmentsList)),
    list: departmentsList,
  });

  const [marketsDialogOpen, setMarketsDialogOpen] = useState(false);
  const [boutiquesDialogOpen, setBoutiquesDialogOpen] = useState(false);
  const [countriesDialogOpen, setCountriesDialogOpen] = useState(false);


  const handleMarketsDialogOpen = () => {
    setMarketsDialogOpen(true);
  };

  const handleMarketsDialogClose = () => {
    setMarketsDialogOpen(false);
  };
  const handleCountriesDialogOpen = () => {
    setCountriesDialogOpen(true);
  };

  const handleCountriesDialogClose = () => {
    setCountriesDialogOpen(false);
  };
  const handleBoutiquesDialogOpen = () => {
    setBoutiquesDialogOpen(true);
  };

  const handleBoutiquesDialogClose = () => {
    setBoutiquesDialogOpen(false);
  };


  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleGroupDialogOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleGroupDialogClose = () => {
    setAnchorEl(null);
  };
  const [anchorElDepartments, setAnchorElDepartments] = useState(null);
  const openDepartmentsDialog = Boolean(anchorElDepartments);
  const handleDepartmentsDialogOpen = (event) => {
    setAnchorElDepartments(event.currentTarget);
  };
  const handleDepartmentsDialogClose = () => {
    setAnchorElDepartments(null);
  };

  useEffect(() => {
    setMarkets(getDefaultState(marketIds, R.prop('markets')(group), defaultMarkets, true));
    setCountries(getDefaultState(countryIds, R.prop('countries')(group), defaultCountries));
    setBoutiques(getDefaultState(boutiqueIds, R.prop('stores')(group), defaultBoutiques));
    setSelectedDepartements({
      selected: R.defaultTo(
        R.head(R.values(departmentsList)),
        R.prop(departmentId, departmentsList),
      ),
      list: departmentsList,
    });
  }, [groupId]);

  useEffect(() => {
    if (R.isNil(departments.selected)) {
      setSelectedDepartements({ ...departments, selected: R.head(defaultDepartments) });
    }
  }, [defaultDepartments]);

  const handleMultiSelected = (set, artefact, id) => {
    const selected = artefact.selected;
    if (R.has(id, selected)) {
      return set({ ...artefact, selected: R.omit([id], selected) });
    }
    return set({ ...artefact, selected: R.assoc(id, R.prop(id, artefact.list), selected) });
  };
  const handleMultiSelectedChips = (set, artefact) => (id) => {
    const selected = artefact.selected;
    if (R.has(id, selected)) {
      return set({ ...artefact, selected: R.omit([id], selected) });
    }
    return set({ ...artefact, selected: R.assoc(id, R.prop(id, artefact.list), selected) });
  };

  const handleSingleSelected = (set, artefact, id) => {
    return set({ ...artefact, selected: R.prop(id, artefact.list) });
  };

  const urlPatternValidation = (link) => {
    const regex = URL_Format;
    return regex.test(link);
  };

  useEffect(() => {
    if (R.isEmpty(title)) {
      return setFormDisabled(true);
    }
    if (R.isNil(startDate) || R.isNil(endDate)) {
      return setFormDisabled(true);
    }
    if (startDate > endDate) {
      return setFormDisabled(true);
    }
    if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
      return setFormDisabled(true);
    }
    if (!urlPatternValidation(link) && link != '') {
      return setFormDisabled(true);
    }
    return setFormDisabled(false);
  }, [title, markets.selected, boutiques.selected, link, countries.selected, startDate, endDate]);

  const selection = {
    ...R.pick(R.keys(markets.selected), markets.list),
    ...R.pick(R.keys(countries.selected), countries.list),
    ...R.pick(R.keys(boutiques.selected), boutiques.list),
  };

  const emeaId = useSelector(selectEmeaId);
  const getSelectedItems = getPartialCollection(emeaId);
  const leavesSelection = getLeaves([...defaultMarkets, ...defaultCountries, ...defaultBoutiques])(
    selection,
  );
  const form = {
    title,
    comment,
    startDate,
    endDate,
    link,
    isHQ,
    groupId,
    ids: R.isEmpty(leavesSelection) ? [emeaId] : R.keys(leavesSelection),
    color: departments?.selected?.color,
    departementId: departments?.selected?.id,
    marketIds: R.keys(markets?.selected),
    countryIds: R.keys(countries?.selected),
    boutiqueIds: R.keys(boutiques?.selected),
  };

  const items = R.filter(({ departments: groupDeparments }) => {
    const departementsSet = new Set(groupDeparments);
    return departementsSet.has(departments?.selected?.id) || departementsSet.has('*');
  })(groups);

  var mapIndexed = R.addIndex(R.map);
  const splitedGroups = R.splitEvery(10, items);
  const splitedDepartments = R.splitEvery(5, R.values(departments.list));
  const heightMarketsSelect = document.getElementById('select-markets')?.clientHeight;
  const heightCoutriesSelect = document.getElementById('select-coutries')?.clientHeight;
  const heightBoutiquesSelect = document.getElementById('select-boutiques')?.clientHeight;
  return (
    <>
      <DialogTitle sx={{ ml: 3 }}>
        {!isCreate ? (
          <Box display="flex" justifyContent="flex-start" sx={{ mb: 2, }}>
            <Button onClick={onClose} sx={{ fontSize: '1.7vh' }} startIcon={<ArrowIcon />}>
              Back
            </Button>
          </Box>
        ) :
          <Close onClose={onClose} />}

        <Box>
          <Typography variant="h6" sx={{ fontSize: '1.5vh' }}>Associated group:
          </Typography>
          <Button
            startIcon={<AssociatedGroupIcon sx={{ mr: 0.5, mt: 0.5 }} />}
            endIcon={<KeyboardArrowDownSharpIcon />}
            sx={{ mt: 0, display: 'flex', fontSize: '1.5vh' }}
            onClick={handleGroupDialogOpen}
            aria-haspopup="true"
            id="fade-button"
          >
            {slectedGroup}
          </Button>
          <Menu
            anchorEl={anchorEl}
            id="fade-menu"
            open={open}
            onClose={handleGroupDialogClose}
          >
            <Box sx={{ display: 'table', minWidth: "10rem" }}>
              {mapIndexed((group, grpNbr) => {
                return (
                  <ButtonGroup
                    key={grpNbr}
                    sx={{ alignItems: 'baseline' }}
                    orientation="vertical"
                  >
                    {mapIndexed(
                      (item, index) => {
                        return (
                          <Button
                            onClick={() => {
                              setGroupId(item.id);
                              handleGroupDialogClose();
                            }}
                            variant="string"
                            key={index}
                            sx={{ justifyContent: "left", fontSize: '1.5vh' }}
                            fullWidth
                          >
                            {item?.shortLabel}
                          </Button>
                        );
                      },
                      group,
                    )}
                  </ButtonGroup>
                );
              }, splitedGroups)}
            </Box>
          </Menu>
        </Box>
        <EventTitle onChange={setTitle} title={title} item={departments.selected} isHQ={isHQ}>
          <Button
            onClick={handleDepartmentsDialogOpen}
            sx={{ width: '40%', justifyContent: "space-between", fontSize: '1.5vh' }}
            aria-haspopup="true"
            id="fade-department"
          >
            {departments.selected.label}
            <AddCircle />

          </Button>
          <Menu
            anchorEl={anchorElDepartments}
            id="fade-department"
            open={openDepartmentsDialog}
            onClose={handleDepartmentsDialogClose}
            PaperProps={{
              style: {
                minWidth: '24%',
              },
            }}
          >

            <Box sx={{ display: 'table' }}>
              {mapIndexed((departement, index) => {
                return (
                  <ButtonGroup
                    key={index}
                    sx={{ alignItems: 'baseline' }}
                    orientation="vertical"
                  >
                    {R.map(({ id, label, color }) => {
                      return (
                        <Button
                          onClick={() => { handleSingleSelected(setSelectedDepartements, departments, id); handleDepartmentsDialogClose() }}
                          variant="string"
                          key={id}
                          sx={{ fontSize: '1.5vh' }}
                        >
                          <Puce color={color} />
                          {label}
                        </Button>
                      );
                    },
                      departement
                    )}
                  </ButtonGroup>
                )

              }, splitedDepartments)}

            </Box>
          </Menu>

        </EventTitle>
      </DialogTitle>
      <Box
        id="selects"
        sx={{
          height: '35vh',
          overflowY: 'auto',
          mr: 1,
          display: 'flex',
          "&::-webkit-scrollbar": {
            width: 7
          },
          "&::-webkit-scrollbar-track": {
            marginLeft: 10
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "gray",
            borderRadius: 6
          }
        }}>
        <Box pl={6} pr={3} pb={2} sx={{ width: '40Vw' }} >
          <Box sx={{ mt: 3 }} display="flex" gap={1} >
            <DatePicker
              onChange={setStartDate}
              value={startDate}
              label="*Starting date"
              InputProps={{ sx: { fontSize: '1.7vh' } }}
            />
            <ArrowLong sx={{ mt: 3 }} />
            <DatePicker
              minDate={startDate}
              onChange={setEndDate}
              InputProps={{ sx: { fontSize: '1.7vh' } }}
              value={endDate}
              label="*Ending date"
              defaultCalendarMonth={dayjs(startDate || new Date())}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <Input
              fullWidth={true}
              error={!urlPatternValidation(link) && link !== ''}
              leftIcon={LinkIcon}
              onChange={setLink}
              value={link}
              InputLabelProps={{
                style: {
                    fontSize: '1.7vh',
                }
           }}
              InputProps={{ sx: { fontSize: '1.7vh' } }}
              type="url"
              label="Attach link"
              placeholder="Attach link"
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <TextField
              fullWidth={true}
              value={comment}
              multiline
              sx={{
                '& .MuiInputLabel-root': {
                  fontSize: '1.9vh'
                },
              }}
              variant="outlined"
              InputProps={{ sx: { fontWeight: "200", fontSize: '1.7vh' } }}
              InputLabelProps={{ shrink: true }}
              label="Comment"
              placeholder="Comment"
              onChange={e => setComment(e?.target?.value)}
            />
          </Box>
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'start' }}>
            {canCreateHQEvent && (
              <FormControlLabel
                sx={{
                  mt: 1,
                  '& .MuiTypography-root': {
                    fontWeight: 500,
                    fontSize: '1.7vh'
                  },
                }}
                control={<Checkbox onChange={() => setIsHQ(!isHQ)} checked={isHQ} />}
                label={'EVENT FOR HQ ONLY'}

                disabled={
                  (!R.isEmpty(boutiques.selected) || !R.isEmpty(countries.selected)) && true
                }
              />
            )}
          </Box>
        </Box>

        <Divider
          orientation="vertical"
          sx={{ position: 'relative', top: '10%', height: heightBoutiquesSelect + heightCoutriesSelect + heightMarketsSelect }}
          flexItem
        />

        <Box pl={2} pr={2} pt={2} sx={{ width: '43Vw' }}>
          {R.map(
            ({ id, label, artefact, set, Icon, items = [], selectedParentIds }) => {
              const selectedItems = getSelectedItems(selectedParentIds)(items);
              return (
                <Box key={id} id={id} mb={3}>
                  <Box sx={{ display: 'block' }}>
                    <Button onClick={handleMarketsDialogOpen} sx={{ textTransform: 'none', display: 'flex', width: '100%', justifyContent: 'space-between' }} disabled={R.isEmpty(selectedItems) || artefact.disabled} variant="string">
                      <Box sx={{ display: 'inline-flex', fontSize: '1.7vh' }}>
                        <Icon style={{ color: "#FFFFF", fontSize: '2.5vh', }} sx={{ mr: 2, pb: 0 }} />
                        {label}
                      </Box>
                      {R.isEmpty(selectedItems) || artefact.disabled ? <ArrowRightGray sx={{ fontSize: '1.7vh' }} /> : <ArrowRight sx={{ fontSize: '1.7vh' }} />
                      }
                    </Button>
                    <Divider variant="middle" sx={{ borderColor: 'black' }} />

                    <Dialog
                      open={marketsDialogOpen}
                      onClose={handleMarketsDialogClose}
                      fullWidth
                      hideBackdrop={true}
                      sx={{
                        ".MuiDialog-container .MuiPaper-root": {
                          boxShadow: 'none'
                        }, top: '10%', width: '75%', height: '85%', left: '12.5%'
                      }}
                      maxWidth="xl"
                      aria-describedby="alert-dialog-slide-description"
                    >
                      <Box sx={{ overflowX: 'hidden', height: "550px" }}>
                        <Slide direction="left" in={true} timeout={{ enter: 300 }} mountOnEnter unmountOnExit>
                          <Box>
                            <Box px={3} pb={2} pt={4}>
                              {
                                <Box display="flex" justifyContent="flex-start" sx={{ mb: 1.5 }}>
                                  <Button sx={{ fontSize: '1.7vh' }} onClick={handleMarketsDialogClose} startIcon={<ArrowIcon />}>
                                    Back
                                  </Button>
                                  <Divider />
                                </Box>
                              }

                              <Typography ml={1} sx={{ fontSize: '1.7vh' }}>SELECT IMPACTED MARKETS</Typography>

                            </Box>
                            <Divider sx={{ mb: 4 }} />
                            <List sx={{ columnCount: 3, px: 3 }}>
                              {R.map(({ id, label }) => {
                                const isSelected = R.propOr(false, id, artefact.selected);
                                return (
                                  <Item
                                    id={id}
                                    key={id}
                                    isSelected={isSelected}
                                    onClick={() => handleMultiSelected(set, artefact, id)}
                                  >
                                    {label}
                                  </Item>
                                );
                              }, selectedItems)}
                            </List>
                          </Box>
                        </Slide>
                      </Box>

                      <ContainerBottom>
                        <Box sx={{ overflowX: 'hidden' }}>
                          <Slide direction="left" in={true} timeout={{ enter: 300 }} mountOnEnter unmountOnExit>
                            <Box>
                              <BlackButton
                                isLoading={isEditPending}
                                onClick={handleMarketsDialogClose}
                                startIcon={<Check />}
                              >
                                <div>
                                  VALIDATE MARKETS
                                </div>
                              </BlackButton>
                            </Box>
                          </Slide>
                        </Box>
                      </ContainerBottom>

                    </Dialog>

                  </Box>
                  <Chips
                    items={R.values(artefact?.selected)}
                    onDelete={artefact.disabled ? null : handleMultiSelectedChips(set, artefact)}
                  />
                </Box>
              );
            },
            [
              {
                id: 'select-markets',
                label: 'Select impacted markets',
                set: setMarkets,
                artefact: markets,
                Icon: MarketIcon,
                items: defaultMarkets,
              },
            ],
          )}

          {R.map(({ id, label, artefact, set, Icon, items = [], selectedParentIds }) => {
            const selectedItems = getSelectedItems(selectedParentIds)(items);
            return (
              <Box key={id} id={id} sx={{ pb: 2 }}>
                <Box sx={{ display: 'block', alignItems: 'start' }} >
                  <Button
                    onClick={id == 'select-boutiques' ? handleBoutiquesDialogOpen : handleCountriesDialogOpen}
                    sx={{ width: '100%', justifyContent: 'space-between', fontSize: 16, textTransform: 'none', }}
                    disabled={isHQ || R.isEmpty(selectedItems) || artefact.disabled}
                    variant="string">
                    <Box sx={{ display: 'inline-flex', fontSize: '1.7vh' }}>
                      <Icon sx={{ mr: 2, fontSize: '2.5vh' }} />
                      {label}
                    </Box>
                    {isHQ || R.isEmpty(selectedItems) || artefact.disabled ? <ArrowRightGray sx={{ fontSize: '1.7vh' }} /> : <ArrowRight sx={{ fontSize: '1.7vh' }} />}
                  </Button>
                  <Divider variant="middle" sx={{ borderColor: 'black' }} />
                  <Dialog
                    open={id == 'select-boutiques' ? boutiquesDialogOpen : countriesDialogOpen}
                    onClose={id == 'select-boutiques' ? handleBoutiquesDialogClose : handleCountriesDialogClose}
                    fullWidth
                    hideBackdrop={true}
                    sx={{
                      ".MuiDialog-container .MuiPaper-root": {
                        boxShadow: 'none'
                      }, top: '10%', width: '75%', height: '85%', left: '12.5%'
                    }}
                    maxWidth="xl"
                    aria-describedby="alert-dialog-slide-description"
                  >
                    <Box sx={{ overflowX: 'hidden', height: "550px" }} >
                      <Slide direction="left" in={true} timeout={{ enter: 300 }}>
                        <Box >
                          <Box px={3} pb={2} pt={4}>
                            {
                              <Box display="flex" justifyContent="flex-start" sx={{ mb: 1.5 }}>
                                <Button
                                  sx={{ fontSize: '1.7vh' }}
                                  onClick={id == 'select-boutiques' ? handleBoutiquesDialogClose : handleCountriesDialogClose}
                                  startIcon={<ArrowIcon />}
                                >
                                  Back
                                </Button>
                              </Box>
                            }{id == 'select-boutiques' ?
                              <Typography ml={1} sx={{ fontSize: '1.7vh' }}>SELECT IMPACTED BOUTIQUES</Typography> :
                              <Typography ml={1} sx={{ fontSize: '1.7vh' }}>SELECT IMPACTED COUNTRIES</Typography>

                            }
                          </    Box >
                          <Divider sx={{ mb: 4 }} />
                          <List sx={{ columnCount: 3, px: 3 }}>
                            {R.map(({ id, label }) => {
                              const isSelected = R.propOr(false, id, artefact.selected);
                              return (
                                <Item
                                  id={id}
                                  key={id}
                                  isSelected={isSelected}
                                  onClick={() => { handleMultiSelected(set, artefact, id) }}
                                >
                                  {label}
                                </Item>
                              );
                            }, selectedItems)}
                          </List>
                        </Box>
                      </Slide>
                    </Box>
                    <ContainerBottom>
                      <Box sx={{ overflowX: 'hidden' }}>
                        <Slide direction="left" in={true} timeout={{ enter: 300 }} mountOnEnter unmountOnExit>
                          <Box>
                            <BlackButton
                              isLoading={isEditPending}
                              onClick={id == 'select-boutiques' ? handleBoutiquesDialogClose : handleCountriesDialogClose}
                              startIcon={<Check />}
                            >
                              {id == 'select-boutiques' ? 'VALIDATE BOUTIQUES ' : 'VALIDATE COUNTRIES'}
                            </BlackButton>
                          </Box>
                        </Slide>
                      </Box>
                    </ContainerBottom>
                  </Dialog>
                </Box>
                <Chips
                  items={R.values(artefact.selected)}
                  onDelete={artefact.disabled ? null : handleMultiSelectedChips(set, artefact)}
                />
              </Box>
            );
          }, [
            {
              id: 'select-coutries',
              label: 'Select impacted countries',
              set: setCountries,
              artefact: countries,
              Icon: CountryIcon,
              items: defaultCountries,
              selectedParentIds: markets.selected
            },
            {
              id: 'select-boutiques',
              label: 'Select impacted boutiques',
              set: setBoutiques,
              artefact: boutiques,
              Icon: BoutiqueIcon,
              items: defaultBoutiques,
              selectedParentIds: R.indexBy(R.prop('id'), getSelectedItems(markets.selected)(defaultCountries))
            }
          ])}

        </Box>

      </Box>
      <ContainerBottom>
        {isCreate ? (
          <BlackButton
            startIcon={<Check />}
            isLoading={isLoading}
            disabled={formDisabled}
            onClick={() => {
              setLoading(true);
              onAddEvent(form);
            }}
          >
            CREATE EVENT(S)
          </BlackButton>
        ) : (
          <Box sx={{ ml: '0.8vh' }}>
            <BlackButton
              variant="outlined"
              onClick={() => setConfirmeOpen(true)}
              sx={{ mr: 2, fontSize: '1.7vh' }}
              startIcon={<DeleteForeverIcon />}
            >
              Delete event
            </BlackButton>
            <BlackButton
              isLoading={isEditPending}
              disabled={formDisabled}
              sx={{ fontSize: '1.7vh' }}
              onClick={() => onEdit(form)}
              startIcon={<Check />}
            >
              Save modification
            </BlackButton>
          </Box>
        )}
        <Dialog open={confirmeOpen} onClose={() => setConfirmeOpen(false)}>
          <DialogTitle>Delete Event</DialogTitle>
          <DialogContent>
            <DialogContentText>Do you want to delete this event ?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => setConfirmeOpen(false)}>
              Cancel
            </Button>
            <Button variant="outlined" onClick={isDeletePending ? null : () => onDelete()}>
              {isDeletePending ? <CircularProgress size={23} /> : 'Delete'}
            </Button>
          </DialogActions>
        </Dialog>
      </ContainerBottom>
    </>
  );
};

export default EventForm;
