import * as R from 'ramda';
import { getRecursiveChildrenByIds, getRecursiveParentByIds, prepareDataRecursiveIds } from '../../utils';

export const getIds = (id, { markets, countries, boutiques }, { isSelected = false } = {}) => {
  const {
    indexedItems,
    groupedItems,
  } = prepareDataRecursiveIds({ markets, countries, boutiques });

  const parentId = R.path([id, 'parentId'], indexedItems)
  const hasParentId = R.has(parentId, indexedItems)
  
  if (isSelected) {
    return [
      ...getRecursiveChildrenByIds(id, groupedItems, indexedItems, [id]),
    ];
  }

  return [
    ...getRecursiveChildrenByIds(id, groupedItems, indexedItems, [id]),
    ...getRecursiveParentByIds(parentId, indexedItems, (parentId && hasParentId) ? [parentId] : [])
  ]; 
}