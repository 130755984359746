import { useState, useEffect } from "react";
import * as R from 'ramda';
import List from './List';
import { getActivePeriod } from "./utils";

const MonthListView = (props) => {
  const { api, children, openDrawerContainer, meta =[], isEventsLoading} = props;

  const [activeRange, setActiveRange] = useState({});
  const [viewTitle, setViewTitle] = useState('');

  const data = api?.currentDataManager?.data;
  const activeWeeks = R.pipe(
    getActivePeriod(activeRange, 'month'),
    R.values,
    R.flatten,
    R.groupBy(R.prop("week")),
    R.values,
    R.reduce((acc, values) => {
      const day = R.pipe(R.head, R.prop('day'))(values)
      return ({ ...acc, [day]: values });
    }, {}),
  )(meta);


  useEffect(() => {
    setViewTitle(data?.viewTitle);
    setActiveRange(data?.dateProfile?.activeRange)
  }, [R.length(meta) != 0 ? openDrawerContainer : R.length(meta), isEventsLoading])

  return (
    <List 
      viewTitle={viewTitle}
      list={activeWeeks}
      isOpen={openDrawerContainer}
      activeRange={activeRange}
      totalEvents={R.length(R.flatten(R.values(activeWeeks)))}
    >
      {children}
    </List>
  );
}

export default MonthListView;
