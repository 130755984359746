import createTheme from "@mui/material/styles/createTheme";

export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          color: '#000000',
          "&:hover:not(.Mui-disabled):before": {
            borderBottomWidth: 1,
            transition: 'unset',
          },
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          color: '#000000',
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#000000'
    },
    secondary: {
      main: '#B6975A',
    },
    grey: {
      200: '#F8F8F8',
      300: '#EDEDED',
      500: '#858585',
      900: '#000000'
    }
  },
  typography: {
    fontFamily: ['"Futura Light BT"', '"Futura Medium"'].join(","),
    h1: {
      fontFamily: "Futura Medium",
      fontSize: 32,
      fontWeight: 500,
    },
    h2: {
      fontFamily: "Futura Medium",
      fontSize: 20,
      fontWeight: 500,
      color: '#000000',
      fontStyle: 'normal',
    },
    h3: {
      fontFamily: "Futura Medium",
      fontStyle: 'normal',
      fontSize: 16,
      fontWeight: 500,
    },
    h4: {
      fontFamily: "Futura Medium",
      fontStyle: 'normal',
      fontSize: 16,
      fontWeight: 500,
    },
    h5: {
      fontFamily: "Futura Medium",
      fontSize: 14,
      fontWeight: 500,
    },
    h6: {
      fontFamily: "Futura Medium",
      fontSize: 12,
      fontWeight: 500,
    },
    body1: {
      fontFamily: "Century Gothic",
      fontSize: 16,
      fontWeight: 600,
    },
    body2: {
      fontFamily: "Futura Light BT",
      fontSize: 14,
      fontWeight: 400,
    },
    button: {
      fontFamily: "Century Gothic",
      fontSize: 13,
      fontWeight: 400,
      textTransform: 'capitalize',
    },
    caption: {
      fontFamily: "Century Gothic",
      fontStyle: 'normal',
      fontSize: 13,
    }
  },
});
