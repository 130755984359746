import * as R from "ramda";
import { useState } from "react";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { Button, Menu, MenuItem, TableCell } from "@mui/material";

const CellEdit = ({ onEdit, onDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <TableCell sx={{
      textAlign: "right",
    }}>
      <Button onClick={e => setAnchorEl(e.currentTarget)}>
        <MoreHorizOutlinedIcon fontSize="small" />
      </Button>
      <Menu
        id="basic-s"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={onEdit}>Edit</MenuItem>
        <MenuItem onClick={onDelete}>Delete</MenuItem>
      </Menu>
    </TableCell>
  );
};

export default CellEdit;
