import * as R from 'ramda';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Box from "@mui/material/Box"

export const INPUT_CHARACTERS = '20ch';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme, value }) => ({
  fontSize: '14px',
  fontWeight: 500,
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: R.isEmpty(value) ? '0ch' : INPUT_CHARACTERS,
    cursor: 'pointer',
    '&:focus': {
      width: INPUT_CHARACTERS,
      cursor: 'unset',
    },
  },
}));

const Spotlight = ({ onChange, term }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "end" }}>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          value={term}
          placeholder="Search…"
          inputProps={{ 'aria-label': 'search' }}
          onChange={(event) => onChange(event.target.value)}
        />
      </Search>
    </Box>
  );
}

export default Spotlight