import * as R from 'ramda';
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';
import Departments from './Departments'
import Territories from './Territories'
import useEvents from '../../hooks/useEvents'
import EventSearch from './EventSearch/EventSearch';
import useMediaQuery from '@mui/material/useMediaQuery';

const Filters = () => {
  const { data: events = [] } = useEvents();
  const eventsNumber = R.length(R.keys(R.groupBy(R.prop('eventId'), events)))
  const breakpoints = useMediaQuery('(min-width:1300px)');
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', }}>
        <Territories />
        <Departments />
        <Divider orientation='vertical' flexItem sx={{ mx: 2, my: 0.5, borderColor: "#000000" }} />
        <Typography variant='caption' component='span'>{eventsNumber < 2 ? eventsNumber + " event found" : eventsNumber + " events found"} </Typography>
        {breakpoints ? <EventSearch></EventSearch> : ""}
      </Box>
    </>
  )
}

export default Filters

