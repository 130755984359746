import { AGENDA } from '../utils/constants';

// https://medium.com/@sidathasiri/flux-and-redux-f6c9560997d7

const model = () => ({
  tab: AGENDA, // or CONFIG
});

const CHANGE_TAB = '@APP/CHANGE_TAB';
// ---------------------------------------------------------------ActionCreator
export const changeTab = (tab) => ({
  type: CHANGE_TAB,
  payload: { tab }
});

//----------------------------------------------------------------------Reducer
export default (state = model(), action) => {
  switch (action.type) {
    case CHANGE_TAB:
      return { ...state, tab: action.payload.tab };
    default:
      return state;
  }
};
