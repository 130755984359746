import { createSelector } from 'reselect';
import * as R from 'ramda';

//------------------------------------------------------------------------------------------------#0
const getCalendar = R.prop('calendar');

//------------------------------------------------------------------------------------------------#1
export const selectView = createSelector(getCalendar, R.prop('view'));
export const selectTerritorySelection = createSelector(getCalendar, R.propOr([], 'territorySelection'));
export const selectDepartmentSelection = createSelector(getCalendar, R.propOr([], 'departmentSelection'));
export const selectRangeDateSelection = createSelector(getCalendar, R.propOr([], 'rangeDateSelection'));
export const selectDrawer = (id) => createSelector(getCalendar, R.pathOr(false, ['openDrawer', id]))
export const selectStart = createSelector(getCalendar, R.prop('start'))
export const selectEventClosed = createSelector(getCalendar, R.prop('closed'))
export const selectEnd = createSelector(getCalendar, R.prop('end'))
export const selectEvent = createSelector(getCalendar, R.prop('event'));
export const selectEmeaId = createSelector(getCalendar, R.prop('emeaId'));