import { useQuery } from 'react-query';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import md5 from 'md5';
import * as dateFns from 'date-fns';
import { selectStart, selectEnd, selectTerritorySelection, selectEmeaId, selectView } from '../selectors/calendar';
import agendaApi from '../api/agenda';
import useDepartments from './useDepartments';
import useIndexedData from './useIndexedData';
import { selectDepartmentSelection } from '../selectors/calendar';
import useAuth from './useAuth';
import { WEEK } from '../utils/constants';
import useUserGroups from './useUserGroups';
import dayjs from 'dayjs';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const addDepartmentColor = departments => R.map(item => R.set(
  R.lensProp('tagColor'),
  R.pipe(R.path(['extendedProps', 'departmentId']), departmentId => R.prop(departmentId, departments), R.prop('color'))(item),
  item
));

const filterByDepartment = departmentIds => (data = []) => {
  if (R.isEmpty(departmentIds)) return data;
  return R.filter(item => R.includes(R.path(['extendedProps', 'departmentId'], item), departmentIds))(data);
};

const getFilteredPeriodEvents = ({ startDate, endDate }) => R.filter(({ start, end }) => {
  const startEvent = new Date(start);
  const endEvent = new Date(end);

  if (startDate <= startEvent && startEvent <= endDate) return true; // b starts in a
  if (startDate <= endEvent   && endEvent   <= endDate) return true; // b ends in a
  if (startEvent <  startDate && endDate    <  endEvent) return true; // a in b
  return false;
})

const getFilteredTerritoriesEvents = (territoriesSelection, emeaId) => events => {
  if (R.isEmpty(territoriesSelection)) return events;
  return R.filter(({ resourceId }) => {
    const indexedTerritories = new Set([...territoriesSelection, emeaId]);
    return indexedTerritories.has(resourceId);
  }, events)
}

const removeEmeaHqEvents=(isUserEmea)=>events=>R.filter(event =>
  !(R.split('/', event.resourceId)[2] == "markets" && R.split('/', event.resourceId)[3] == "1" && !isUserEmea && event?.extendedProps?.isHQ),
  events)

const resourceIdsToResourceId = R.reduce((acc, event) => {
  const today = +new Date();
  const eventId = md5(`${today}${R.join(' ', event.resourceIds)}${R.path(['extendedProps', 'title'], event)}${R.path(['extendedProps', 'start'], event)}`);
  return [...acc, ...R.map((resourceId) => ({ eventId, resourceId, ...R.dissoc('resourceIds')(event) }))(event.resourceIds)]
}, [])

const method = 'getEvents';

const staleTime = 5 * 1000;
export default ({ enabled = true } = {}) => {
  const headers = useAuth({});
  const startDate = useSelector(selectStart);
  const endDate = useSelector(selectEnd);
  const isMonth = useSelector(selectView) !== WEEK;
  const duration = isMonth ? { months: 1 } : { weeks: 1 };
  const moment = extendMoment(Moment);
  const ctx = (startDate, endDate) => {
    return ({
      method,
      params: {
        _pagination: false,
        includeHq: true,
        dateFrom: startDate ? dayjs(new Date(startDate)).format('YYYY-MM-DD') : null,
        dateTo: endDate ? dayjs(new Date(moment(endDate).subtract('1','day'))).format('YYYY-MM-DD') : null,
      },
      headers,
    });
  }
  const emeaId = useSelector(selectEmeaId);
  const territoriesSelection = useSelector(selectTerritorySelection);

  const queryKey = `@@agenda/${method}`;
  const departments = useIndexedData(useDepartments);

  const data = useQuery([queryKey, startDate, endDate], () => agendaApi(ctx(startDate, endDate)), { enabled: enabled && !!startDate && !!endDate, refetchOnWindowFocus: true, staleTime });

  // load the next request
  const nextStartDate = dateFns.add(startDate, duration);
  const nextEndDate = dateFns.add(endDate, duration);
  useQuery([queryKey, nextStartDate, nextEndDate], () => agendaApi(ctx(nextStartDate, nextEndDate)), { enabled: enabled && !!startDate && !!endDate, staleTime  });
  const userGroups = useUserGroups();
  const isUserEmea = R.includes("EMEA", R.map(group => R.split(" ", group.shortLabel)[0], userGroups.data))
  const departmentIds = useSelector(selectDepartmentSelection);

  return R.evolve({
    data: R.pipe(
      filterByDepartment(departmentIds || []),
      addDepartmentColor(departments || {}),
      getFilteredPeriodEvents({ startDate, endDate }),
      resourceIdsToResourceId,
      getFilteredTerritoriesEvents(territoriesSelection, emeaId),
      removeEmeaHqEvents(isUserEmea)
    )
  })(data)
};
