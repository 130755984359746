import { useMemo, useState } from 'react';
import React from 'react';
import Grid from '@mui/material/Grid';
import * as R from 'ramda';
import MuiIconButton from '@mui/material/IconButton';
import MuiBox from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, styled } from '@mui/material';
import useAllEvents from '../../hooks/useAllEvents';
import useDepartments from '../../hooks/useDepartments';
import { selectView } from '../../selectors/calendar';
import M from '../icons/M';
import W from '../icons/W';
import CalendarDateRange from '../CalendarDateRange/CalendarDateRange';
import IconList from '../icons/List';
import { WEEK, MONTH, DAY } from '../../utils/constants';
import { changeView, openDrawer,changeDateRangeSelection } from '../../ducks/calendar';
import useEvents from '../../hooks/useEvents';
import ExcelExport from '../ExcelExport/excelExport';

// todo import svgCss from /icons
const svgCss = ({ theme }) => `
  font-size: 14px;
  font-weight: 500;
  font-family: ${theme.typography.fontFamily};
`;

const IconM = styled(M)(svgCss);
const IconW = styled(W)(svgCss);

const ExcelIconButton = styled(MuiIconButton)(
  ({ theme }) => `
  height: 35px;
  width: 36px;
  margin: 3px;
  color: #ffffff;
`,
);
const IconButton = styled(MuiIconButton)(
  ({ theme }) => `
  height: 35px;
  width: 36px;
  margin: 3px;
  &:hover {
    background-color: ${theme.palette.secondary.main};
  }
`,
);
const PersonalizeButton = styled(MuiIconButton)(
  ({ theme }) => `
  height: 35px;
  width: 100px;
  color:#000000;
  font-size:16px;
  margin: 3px;
  &:hover {
    border-radius:50px;
    background-color: ${theme.palette.secondary.main};
  }
`,
);
// &:hover svg {
//   fill: #FFF;
// }

const Box = styled(MuiBox)(
  ({ theme }) => `
  background-color: ${theme.palette.grey[300]};
  border-radius: 50px;
  height: 41px;
`,
);

const CalendarButtons = () => {
  const { data: departments } = useDepartments();
  const dispatch = useDispatch();
  const [openRange, setOpenRange] = useState(false);
  const view = useSelector(selectView);
  const isWeek = view === WEEK;
  const isMonth = view === MONTH;
  const isDay = view === DAY;
  const { data: events = [] } = useEvents();
  const eventsNumber = R.length(R.keys(R.groupBy(R.prop('eventId'), events)));
  const { data: allEvents = [], isLoading: isEventsLoading } = useAllEvents();
  
  // const exportPDF = useMemo(() => {
  //   const handlePDFPrint = () => {
  //     var headerBtns = document.getElementById('headerBtns');
  //     if (headerBtns) headerBtns.hidden = true;
  //     const input = document.getElementById('calendar');
  //     const legend = document.getElementById('legend');
  //     var calendarScrollBar = document.getElementsByClassName(
  //       'fc-scroller fc-scroller-liquid-absolute',
  //     )[0];
  //     if (calendarScrollBar) calendarScrollBar.style.position = 'initial';
  //     const body = document.body;
  //     const html = document.documentElement;
  //     const height = Math.max(
  //       body.scrollHeight,
  //       body.offsetHeight,
  //       html.clientHeight,
  //       html.scrollHeight,
  //       html.offsetHeight,
  //     );
  //     if (
  //       document.getElementsByClassName('fc-timeline-slot fc-timeline-slot-lane')[0].offsetHeight >
  //       1500
  //     ) {
  //       var imgFormat = 'p';
  //     } else {
  //       imgFormat = 'l';
  //     }
  //     const pdf = new jsPDF(imgFormat, 'mm', 'a4');

  //     html2canvas(legend, {
  //       logging: true,
  //       letterRendring: 1,
  //       useCORS: true,
  //       scale: 3,
  //       height: height,
  //       windowHeight: height,
  //     }).then((canvas) => {
  //       const imgLegend = canvas.toDataURL('img/png');
  //       var width = pdf.internal.pageSize.getWidth();
  //       pdf.setFontSize(10);
  //       imgFormat == 'p'
  //         ? pdf.text(100, 4, `${eventsNumber} events`)
  //         : pdf.text(140, 20, `${eventsNumber} events`);
  //       imgFormat == 'p'
  //         ? pdf.addImage(imgLegend, 'JPEG', 5, 5, width, 0, 'alias1')
  //         : pdf.addImage(imgLegend, 'JPEG', 12, 25, width - 20, 0, 'alias1');
  //     });
  //     html2canvas(input, {
  //       logging: true,
  //       letterRendring: 1,
  //       useCORS: true,
  //       scale: 3,
  //       height: height - 100,
  //       windowHeight: height,
  //     }).then((canvas) => {
  //       const imgData = canvas.toDataURL('img/png');
  //       var width = pdf.internal.pageSize.getWidth();
  //       var height = pdf.internal.pageSize.getHeight();
  //       imgFormat == 'p'
  //         ? pdf.addImage(imgData, 'JPEG', 5, 15, width - 7, height, 'alias2')
  //         : pdf.addImage(imgData, 'JPEG', 12, 50, width - 20, height - 50, 'alias2');
  //       pdf.save('Shared Calendar.pdf');
  //     });
  //     if (calendarScrollBar) calendarScrollBar.style.position = 'absolute';
  //     if (headerBtns) headerBtns.hidden = false;
  //   };

  //   return R.length(events) > 0 ? (
  //     <IconButton onClick={() => handlePDFPrint()}>
  //       <PDFDownload />
  //     </IconButton>
  //   ) : (
  //     ''
  //   );
  // }, [R.length(events)]);

  const rangeButton = (() => {
    const personalizeBtn = (
      <>
        <CalendarDateRange openRange={openRange} setOpenRange={setOpenRange} />
        <Grid item>
          <Box sx={{}}>
            <PersonalizeButton
              sx={{
                borderRadius: '50px',
                backgroundColor: isDay ? '#B6975A' : 'transparent',
                color: isDay ? '#ffffff' : '#000000',
              }}
              onClick={() => {
                setOpenRange(!openRange);
              }}
            >
              <span>Personalize</span>
            </PersonalizeButton>
          </Box>
        </Grid>
      </>
    );
    return departments ? (
      <>{personalizeBtn}</>
    ) : (
      <Grid item>
        <Box>
          <IconButton>
            <CircularProgress size={16} />
          </IconButton>
        </Box>
      </Grid>
    );
  })();

  return (
    <Grid container item spacing={1} zIndex={3}>
      {/* <Grid item>
        <Box>{exportPDF}</Box>
      </Grid> */}
      <Grid item>
        <Box>
          <ExcelIconButton>
            {!isEventsLoading ? (
              <ExcelExport events={events}></ExcelExport>
            ) : (
              <CircularProgress size={16} />
            )}
          </ExcelIconButton>
        </Box>
      </Grid>

      {rangeButton}

      <Grid item>
        <Box>
          <IconButton
            onClick={() => {
              dispatch(changeView(WEEK));
              dispatch(changeDateRangeSelection(null));
              setOpenRange(false);
            }}
            sx={{
              backgroundColor: isWeek ? '#B6975A' : 'transparent',
              marginRight: 0,
            }}
          >
            <IconW sx={{ fill: isWeek ? 'white' : 'black' }} />
          </IconButton>
          <IconButton
            onClick={() => {
              dispatch(changeView(MONTH));
              dispatch(changeDateRangeSelection(null));
              setOpenRange(false);
            }}
            sx={{
              backgroundColor: isMonth ? '#B6975A' : 'transparent',
              marginLeft: 0,
            }}
          >
            <IconM sx={{ fill: isMonth ? 'white' : 'black' }} />
          </IconButton>
        </Box>
      </Grid>
      <Grid item>
        <Box>
          <IconButton
            onClick={() => {
              dispatch(openDrawer(view, true));
              setOpenRange(false);
            }}
          >
            <IconList />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CalendarButtons;
