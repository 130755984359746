import MuiToolTip from '@mui/material/Tooltip';
import { styled } from "@mui/material/styles";

const Tooltip = styled(({ children, className, ...props }) => (
  <MuiToolTip
    placement="top"
    classes={{ popper: className }}
    enterNextDelay={100}
    {...props}
  >
    {children}
  </MuiToolTip>
))(() => ({
  "& .MuiTooltip-tooltip": {
    fontSize: 13,
  },
}));

export default Tooltip;
