import * as R from 'ramda';

export const getEvolvedGroup = group => R.set(
  R.lensProp('shortLabel'),
  R.pipe(
    R.propOr('', 'label'),
    R.replace('BWT-', ''),
    R.replace('-Contributors', ''),
    R.replace('-', ' '),
  )(group)
)(group)

export const getEvolvedGroups = (data = []) => R.map(getEvolvedGroup)(data);

const getQueryKeysBetweenDates = (dateFrom, dateTo) => R.pipe(
  R.prop('queries'),
  R.map(R.prop('queryKey')),
  R.filter(R.includes('@@agenda/getEvents')),
  R.filter(([, startDate, endDate]) => {
    const start = +new Date(startDate);
    const end = +new Date(endDate);
    const dateFromStart = +new Date(dateFrom);
    const dateToEnd = +new Date(dateTo);
    return start <= dateFromStart && dateFromStart <= end || start <= dateToEnd && dateToEnd <= end;
  }),
)

export const onEventSuccess = (queryClient) => ({ data: { dateFrom, dateTo }}) => {
  const queryCache = queryClient.getQueryCache();
  R.pipe(
    getQueryKeysBetweenDates(dateFrom, dateTo),
    R.map((queryKey) => queryClient.invalidateQueries(queryKey))
  )(queryCache);
}