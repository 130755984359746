import qs from 'qs';
import * as R from 'ramda';
import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';

export default (headers) => {
  const SSOToken = window?.location?.search.split('token=')[1] == undefined && JSON.parse(localStorage.getItem('okta-token-storage'))?.idToken?.idToken;
  const { token } = qs.parse(R.replace(/&amp;/g, '&', window?.location?.search || ''), {
    ignoreQueryPrefix: true,
  });
  const decoded = token && jwt_decode(token);
  const tokenExpired = token && dayjs().isAfter(dayjs.unix(decoded.exp));
  if ((R.isNil(token) && R.isNil(SSOToken) ) || tokenExpired ) return headers;
  return !R.isNil(token)
    ? R.assoc('Authorization', `Bearer ${token}`, headers)
    : R.assoc('Authorization', `Bearer ${SSOToken}`, headers);
};
