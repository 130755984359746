import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import MuiTypography from '@mui/material/Typography';
import Tooltip from '../Tooltip/Tooltip';
import { getHQTitle } from './utils';

const Wrapper = styled(Box)(({ theme}) => ({
  display: 'grid',
  grid: '1fr / auto 1fr',
  gap: theme.spacing(1),
  padding: '2px 4px',
  minHeight: 29,
  borderRadius: 12
}));

const Typography = styled(MuiTypography)({
  whiteSpace: 'nowrap'
});

const Tag = styled(Box)({
  height: '100%',
  width: 6,
  borderRadius: 13,
  paddingRight: 4,
});

const Event = ({ data }) => {
  const { title, extendedProps } = data.event;
  const { description, tagColor, isHQ } = extendedProps;
  return (
    <Tooltip
      title={
        <Box>
          <MuiTypography variant="caption">{title}</MuiTypography>
          <br />
          <MuiTypography sx={{ textTransform: 'capitalize' }} variant="caption">{description}</MuiTypography>
        </Box>
      }
      arrow
      followCursor
    > 
      <Wrapper>
        <Tag sx={{ background: tagColor }} />
          <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }} >
            <Typography variant="h5" sx={{ color: 'grey.900', fontFamily: "Futura Book" }}>{getHQTitle({ isHQ, title})}</Typography>
            <Typography variant="caption" sx={{ color: 'grey.500', textTransform: 'capitalize' }}>{description}</Typography>
          </Box>
      </Wrapper>
    </Tooltip>
  )
}

export const ListEvent = ({ data }) => {
  const { title, extendedProps, endStr } = data.event;
  const { description, tagColor, isHQ } = extendedProps;
  return (
    <Wrapper>
      <Tag sx={{ background: tagColor }} />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h3" sx={{ fontSize: 20 }}>{getHQTitle({ isHQ, title})}</Typography>
        {description && (
          <Typography variant="caption" sx={{ color: 'grey.500', marginBottom: 1.5 }}>
            {description}
          </Typography>
        )}
        <Typography variant="body2" sx={{ fontFamily: 'Futura medium', whiteSpace: 'nowrap'}}>Ending: {endStr}</Typography>
      </Box>
    </Wrapper>
  )
}

export default Event