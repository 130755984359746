import { WEEK } from '../utils/constants';

// https://medium.com/@sidathasiri/flux-and-redux-f6c9560997d7

const model = () => ({
  view: WEEK,
  territorySelection: [],
  departmentSelection: [],
  rangeDateSelection: [],
  openDrawer: {},
  closed: true,
  start: null,
  event: {},
});

const CHANGE_EMEA = '@CALENDAR/CHANGE_EMEA';
const CHANGE_VIEW = '@CALENDAR/CHANGE_VIEW';
const VIEW_EVENT = '@CALENDAR/VIEW_EVENT';
const CLOSE_EVENT = '@CALENDAR/CLOSE_Event';
const CHANGE_DATE = '@CALENDAR/CHANGE_DATE';
const OPEN_DRAWER = '@CALENDAR/OPEN_DRAWER';
const CHANGE_TERRITORY_SELECTION = '@CALENDAR/CHANGE_TERRITORY_SELECTION';
const CHANGE_DEPARTMENT_SELECTION = '@CALENDAR/CHANGE_DEPARTMENT_SELECTION';
const CHANGE_DATE_RANGE_SELECTION = '@CALENDAR/CHANGE_DATE_RANGE_SELECTION';
// ---------------------------------------------------------------ActionCreator
export const changeView = (view) => ({
  type: CHANGE_VIEW,
  payload: { view }
});

export const changeDate = ({ start, end }) => ({
  type: CHANGE_DATE,
  payload: { start, end }
});

export const openDrawer = (id, open) => ({
  type: OPEN_DRAWER,
  payload: { [id]: open }
});

export const changeTerritorySelection = (selection) => ({
  type: CHANGE_TERRITORY_SELECTION,
  payload: { selection }
})
export const changeDateRangeSelection = (selection) => ({
  type: CHANGE_DATE_RANGE_SELECTION,
  payload: { selection }
})

export const changeDepartmentSelection = (selection) => ({
  type: CHANGE_DEPARTMENT_SELECTION,
  payload: { selection }
})

export const viewEvent = (event) => ({
  type: VIEW_EVENT,
  payload: { event }
})
export const closeEvent = (closed) => ({
  type: CLOSE_EVENT,
  payload: { closed }
})

export const changeEmeaId = (id) => ({
  type: CHANGE_EMEA,
  payload: { id }
})

//----------------------------------------------------------------------Reducer
export default (state = model(), action) => {
  switch (action.type) {
    case CHANGE_EMEA:
      return { ...state, emeaId: action.payload.id };
    case VIEW_EVENT:
      return { ...state, event: action.payload.event };
    case CLOSE_EVENT:
      return { ...state, closed: action.payload.closed };
    case CHANGE_VIEW:
      return { ...state, view: action.payload.view };
    case CHANGE_DATE:
      return { ...state, start: action.payload.start, end: action.payload.end };
    case CHANGE_TERRITORY_SELECTION:
      return { ...state, territorySelection: action.payload.selection };
    case CHANGE_DEPARTMENT_SELECTION:
      return { ...state, departmentSelection: action.payload.selection };
    case CHANGE_DATE_RANGE_SELECTION:
      return { ...state, rangeDateSelection: action.payload.selection };
    case OPEN_DRAWER:
      return {
        ...state, openDrawer: {
          ...state.openDrawer,
          ...action.payload,
        }
      }
    default:
      return state;
  }
};
