import { useEffect, useState } from "react";
import * as R from "ramda";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Pagination,
  Box,
} from "@mui/material";
import Spotlight, { INPUT_CHARACTERS } from "./Spotlight";
import { searchResources } from "../../utils";

const Table = ({ rows, EndComponent, id }) => {
  const headerRow = R.head(rows) || [];
  const bodyRows = R.drop(1, rows) || [];
  const [page, setPage] = useState({ [id]: 1 });
  const currentPage = R.propOr(1, id, page);
  const rowsPerPage = 10;
  const [terms, setTerms] = useState({ [id]: "" });
  const term = R.propOr("", id, terms);
  const splittedRows = R.splitEvery(rowsPerPage,  searchResources(bodyRows, term));

  const handleChangePage = (newPage) => {
    setPage({ ...page, [id]: newPage });
  };

  const changeTerm = (term) => {
    setTerms({ ...terms, [id]: term });
  };

  useEffect(()=> {
    if (currentPage > R.length(splittedRows)) {
      handleChangePage(1);
    }
  }, [R.length(splittedRows)])
  
  useEffect(() => {
    handleChangePage(currentPage);
    changeTerm(term)
  }, [id])
  
  return (
    <>
      <MuiTable sx={{ borderCollapse: "unset" }}>
        <TableHead>
          <TableRow>
            {R.map((value) => {
              return <TableCell key={value}>{value}</TableCell>;
            }, headerRow)}
            <TableCell sx={{ minWidth: INPUT_CHARACTERS, maxWidth: INPUT_CHARACTERS }}>
              <Spotlight onChange={changeTerm} term={term} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {R.addIndex(R.map)((row, index) => (
            <TableRow key={`${R.head(row)}-${index}-row`}>
              {R.addIndex(R.map)((value, valueIndex) => {
                return (
                  <TableCell
                    key={`${valueIndex}-${index}-value`}
                    align="left"
                    sx={{ fontFamily: "Century Gothic" }}
                  >
                    {value}
                  </TableCell>
                );
              }, row)}
              <EndComponent row={row} />
            </TableRow>
          ), R.nth(currentPage - 1, splittedRows) || [])}
        </TableBody>
      </MuiTable>
      {R.length(bodyRows) > rowsPerPage && (
        <Box marginTop={4}>
          <Pagination
            count={R.length(splittedRows)}
            rowsperpage={rowsPerPage}
            page={currentPage}
            onChange={(_, page) => handleChangePage(page)}
            showFirstButton={true}
            showLastButton={true}
          />
        </Box>
      )}
    </>
  );
}

export default Table;