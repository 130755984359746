import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Calendar from "../Calendars/Calendar";
import SubNavBar from "../SubNavBar/SubNavBar";
import Legend from '../Legend/Legend';
import EventDetails from '../EventForm/EventDetails';
import AddEvent from '../Buttons/AddEvent';

const Agenda = () => {

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'end', top:"10px"}}>
        <AddEvent />
      </Box>
      <SubNavBar />
      <Legend />
      <Divider sx={{ mb: 3, mt: 2.5 }} />
      <Calendar />
      <EventDetails />
    </>
  );
}

export default Agenda