import { useState, useEffect } from "react";
import * as R from "ramda";
import { Typography, Input, Box } from '@mui/material'
import BlackButton from '../../BlackButton/BlackButton';
import Check from "../../icons/Check";
import useMarkets from "../../../hooks/useMarkets";
import { useSelector } from "react-redux";
import { selectEmeaId } from "../../../selectors/calendar";
import useEditMarket from "../../../hooks/useEditMarket";
import useAddMarket from "../../../hooks/useAddMarket";
import { getCleanedId } from "../../../utils";

const MarketForm = ({ editProps = [], variant = "add", changePage }) => {
  const isAdd = variant === "add";
  const { data: markets = [] } = useMarkets();
  const emeaId = useSelector(selectEmeaId)
  const label = R.head(editProps);
  const market = isAdd ? {} : R.prop(label, R.indexBy(R.prop("label"), markets));

  const [formDisabled, setFormDisabled] = useState(true);
  const [title, setTitle] = useState(market?.label || "");
  const [isSavePending, setIsSavePending] = useState(false);
  const [isAddPending, setIsAddPending] = useState(false);
  const editMarket = useEditMarket();
  const addMarket = useAddMarket();

  useEffect(() => {
    if (R.isEmpty(title)) {
      return setFormDisabled(true);
    }
    setFormDisabled(false)
  }, [title])

  const onEdit = params => {
    setIsSavePending(true)
    editMarket.mutate(params, {
      onSuccess: () => {
        setIsSavePending(false);
        changePage();
      },
      onError: (err) => {
        /* eslint-disable no-console */
        console.error('error:', err.message);
        setIsSavePending(false);
      }
    });
  };

  const onAdd = params => {
    setIsAddPending(true)
    addMarket.mutate(params, {
      onSuccess: () => {
        setIsAddPending(false);
        changePage();
      },
      onError: (err) => {
        /* eslint-disable no-console */
        console.error('error:', err.message);
        setIsAddPending(false);
      }
    });
  };

  const onReset = () => {
    setTitle(market?.label || "");
    changePage()
  }

  return (
    <Box maxWidth={405}>
      {isAdd && <Typography variant="h2">Add market</Typography>}
      {!isAdd && <Typography variant="h2">Edit market</Typography>}
      <Box my={5}>
        <Input
          fullWidth={true}
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          placeholder="*Add title"
          inputProps={{ maxLength: 40 }}
          variant="standard"
        />
      </Box>
      <Box sx={{ display: "flex", gap: "16px" }}>
        <BlackButton variant="outlined" onClick={onReset}>CANCEL</BlackButton>
        {isAdd && (
          <>
            <BlackButton
              startIcon={<Check />}
              disabled={formDisabled}
              onClick={() => onAdd({
                name: title,
                parentMarket: emeaId,
              })}
              isLoading={isAddPending}
            >
              CREATE MARKET
            </BlackButton>
          </>
        )}
        {!isAdd && (
          <>
            <BlackButton
              startIcon={<Check />}
              disabled={formDisabled}
              onClick={() => onEdit({
                id: getCleanedId(market.id),
                name: title,
                parentMarket: emeaId,
              })}
              isLoading={isSavePending}
            >
              SAVE
            </BlackButton>
          </>
        )}
      </Box>
    </Box>
  );
}

export default MarketForm;