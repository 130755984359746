import { Box, Button, CircularProgress, Grid, IconButton, styled, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import MuiArrowRight from '../icons/ArrowRight';
import { svgCss } from '../icons';
import useEvents from '../../hooks/useEvents';

const ArrowRight = styled(MuiArrowRight)(svgCss);

const Header = ({ next, prev, today, date, sideWidth, isEventsLoading, setFilterResourcesWithEvents, filterResourcesWithEvents }) => {
  const { refetch: refetchEvents, isRefetching } = useEvents({ enabled: false });
  return (
    <Grid container sx={{ alignItems: 'center' }} spacing={1}>
        <Grid item
          sx={{
            width: sideWidth,
            display: 'flex',
            justifyContent: 'flex-end',
            transition: 'width 0.75s ease-in-out',
            marginLeft: 2.5
          }}
        >
        </Grid>
      <Grid item sx={{ display: 'flex', alignItems: 'center', flexGrow: 1,  justifyContent: "space-between"}}>
        <Typography variant="h2">
          {date}
          {(isEventsLoading || isRefetching) && <CircularProgress size={16} sx={{ ml: 1 }} />}
        </Typography>
        <Box  id="headerBtns" sx={{ floatRight: 0 }}>
          <Button onClick={() => setFilterResourcesWithEvents(!filterResourcesWithEvents)}>
            {filterResourcesWithEvents
              ? "Show all locations"
              : "Hide locations with no event"
            }
          </Button>
          <IconButton onClick={() => refetchEvents()}>
            <RefreshIcon />
          </IconButton>
          {prev && 
            <IconButton onClick={prev}  sx={{ transform: 'rotate(180deg)'}}>
              <ArrowRight />
            </IconButton>
          }
          {next && 
            <IconButton onClick={next} >
              <ArrowRight />
            </IconButton>
          }
          {today &&
            <Button onClick={today}>
              Today
            </Button>
          }
        </Box>
      </Grid>
    </Grid>
  )
}

export default Header