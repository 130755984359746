import { useState } from 'react';
import * as R from 'ramda';
import { Button,
  IconButton,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress
} from '@mui/material';
import { Close } from '@mui/icons-material/';
import { getCleanedId } from './../../../utils';

const ConfirmDialog = ({ onClose, open, useEdit, useData, label }) => {
  const { data: artefact = [] } = useData();
  const data = R.prop(label, R.indexBy(R.prop("label"), artefact));
  const id = data?.id;
  const deleteData = useEdit();
  const [isPending, setIsPending] = useState({ [{ [id]: false }]: false });

  const confirmDelete = () => {
    setIsPending({ [id]: true })
    deleteData.mutate({
      id: getCleanedId(id),
      deletedAt: 'now'
    }, {
      onSuccess: () => {
        setIsPending({ [id]: false });
        onClose();
      },
      onError: (err) => {
        /* eslint-disable no-console */
        console.error('error:', err.message);
        setIsPending({ [id]: false });
      }
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography>Are you sure you want to delete this line?</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" variant="outlined" onClick={R.prop(id, isPending) ? null : confirmDelete}>
          {R.prop(id, isPending)
            ? <CircularProgress size={25} sx={{ color: '#ffffff'}} />
            : 'Delete'
          }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;