import { useState, useEffect } from "react";
import * as R from "ramda";
import { Typography, Box, Input } from "@mui/material";
import { styled } from '@mui/material/styles';
import SimpleSelect from "../../Select/Select";
import BlackButton from '../../BlackButton/BlackButton';
import useCountries from "../../../hooks/useCountries";
import useEditCountry from "../../../hooks/useEditCountry";
import useAddCountry from "../../../hooks/useAddCountry";
import useMarkets from "../../../hooks/useMarkets";
import Check from "../../icons/Check";
import { getCleanedId } from "../../../utils";

const Select = styled(SimpleSelect)(() => ({
  "& .MuiInputLabel-root": {
    color: "gray",
    transform: 'translate(0px, 20px) scale(1)',
  },
  "& label.Mui-focused": {
    transform: 'translate(0px, 20px) scale(1)',
    backgroundColor: 'transparent'
  }
}));


const CountryForm = ({ editProps = [], variant = "add", changePage }) => {
  const isAdd = variant === "add";
  const { data: countries = [] } = useCountries();
  const { data: markets = [] } = useMarkets();
  const label = R.head(editProps);
  // const market = useSelector(selectMarket)
  const country = isAdd ? {} : R.prop(label, R.indexBy(R.prop("label"), countries));
  const [formDisabled, setFormDisabled] = useState(true);
  const [title, setTitle] = useState(country?.label || "");
  const [isSavePending, setIsSavePending] = useState(false);
  const [isAddPending, setIsAddPending] = useState(false);
  const editCountry = useEditCountry();
  const addCountry = useAddCountry();
  const [selectedMarketId, setSelectedMarketId] = useState(country?.parentId || R.head(markets)?.id);

  useEffect(() => {
    if (R.isEmpty(title, selectedMarketId)) {
      return setFormDisabled(true);
    }
    setFormDisabled(false)
  }, [selectedMarketId, title])


  const onEdit = params => {
    setIsSavePending(true)
    editCountry.mutate(params, {
      onSuccess: () => {
        setIsSavePending(false);
        changePage();
      },
      onError: (err) => {
        /* eslint-disable no-console */
        console.error('error:', err.message);
        setIsSavePending(false);
      }
    });
  };

  const onAdd = params => {
    setIsAddPending(true)
    addCountry.mutate(params, {
      onSuccess: () => {
        setIsAddPending(false);
        changePage();
      },
      onError: (err) => {
        /* eslint-disable no-console */
        console.error('error:', err.message);
        setIsAddPending(false);
      }
    });
  };

  const onReset = () => {
    setTitle(country?.label || "");
    setSelectedMarketId(country?.parentId || R.head(markets)?.id);
    changePage()
  }

  return (
    <Box maxWidth={405}>
      {isAdd && <Typography variant="h2">Add Country</Typography>}
      {!isAdd && <Typography variant="h2">Edit Country</Typography>}
      <Box my={5}>
        <Input
          fullWidth={true}
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          placeholder="*Add title"
          variant="standard"
          inputProps={{ maxLength: 40 }}
        />
      </Box>
      <Box my={5}>
        <Select
          label="*Markets"
          items={markets}
          value={selectedMarketId}
          onChange={id => setSelectedMarketId(id)}
        />
      </Box>
      <Box sx={{ display: "flex", gap: "16px" }}>
        <BlackButton
          variant="outlined"
          onClick={onReset}
        >
          CANCEL
        </BlackButton>
        {isAdd && (
          <BlackButton
            startIcon={<Check />}
            disabled={formDisabled}
            onClick={() => onAdd({
              name: title,
              market: selectedMarketId,
              // parentMarket: market,
            })}
            isLoading={isAddPending}
          >
            CREATE COUNTRY
          </BlackButton>
        )}
        {!isAdd && (
          <BlackButton
            startIcon={<Check />}
            disabled={formDisabled}
            onClick={() => onEdit({
              id: getCleanedId(country.id),
              name: title,
              market: selectedMarketId,
              // parentMarket: market,
            })}
            isLoading={isSavePending}
          >
            SAVE
          </BlackButton>
        )}
      </Box>
    </Box>
  )
}

export default CountryForm
