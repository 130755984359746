import * as R from "ramda";
import { styled, Chip as MuiChip } from "@mui/material";
import CloseIcon from '@mui/icons-material/CloseRounded';

const Chip = styled((props) => (
  <MuiChip
    variant="filled"
    color="secondary"
    deleteIcon={<CloseIcon />}
    {...props}
  />
))(({ theme }) => ({
  ...theme.typography.h6,
  color: theme.palette.common.white,
  paddingRight: theme.spacing(0.5),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(0.25),
  '& .MuiChip-deleteIcon, & .MuiChip-deleteIcon:hover': {
    fontSize:'2vh',
    color: theme.palette.common.white,
  },
}));

const Chips = ({ items = [], onDelete }) => {
  return (
    <>
      {R.addIndex(R.map)(({ label, id, hasDelete = true }, index) => 
        <Chip
          key={`${id}-${index}`}
          label={label}
          onDelete={hasDelete && onDelete ? () => onDelete(id) : undefined}
          sx={{ ml: 2, fontSize:'1.5vh' }}
        />
      )(items)}
    </>
  )
}

export default Chips