import * as R from 'ramda';
import { useQuery } from 'react-query';
import agendaApi from '../api/agenda';
import useAuth from './useAuth';
import { getEvolvedGroups } from './utils';

export default () => {
  const headers = useAuth({});

  const ctx = {
    method: 'getGroups',
    params: {
      _pagination: false
    },
    headers
  };

  const queryKey = `@@agenda/${ctx.method}`;
  const data = useQuery(queryKey, () => agendaApi(ctx));
  return R.evolve({
    data: getEvolvedGroups
  })(data)
};
