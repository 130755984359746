import SvgIcon from "@mui/material/SvgIcon";

export default props => (
  <SvgIcon {...props}>
    <path
      d="M13.2434 11.7464L19.7867 5.18901C19.9174 5.05838 19.9907 4.88123 19.9907 4.6965C19.9907 4.51177 19.9174 4.33462 19.7867 4.204C19.6561 4.07337 19.4789 4 19.2942 4C19.1095 4 18.9324 4.07337 18.8017 4.204L12.2443 10.7473L5.68698 4.204C5.55637 4.07337 5.37921 4 5.19447 4C5.00976 4 4.83259 4.07337 4.70197 4.204C4.57136 4.33462 4.49797 4.51177 4.49797 4.6965C4.49797 4.88123 4.57136 5.05838 4.70197 5.18901L11.2453 11.7464L4.70197 18.3038C4.63466 18.367 4.58102 18.4432 4.54435 18.5279C4.50768 18.6127 4.48877 18.704 4.48877 18.7963C4.48877 18.8886 4.50768 18.9799 4.54435 19.0646C4.58102 19.1494 4.63466 19.2256 4.70197 19.2888C4.76514 19.3561 4.84143 19.4097 4.92614 19.4464C5.01085 19.483 5.10218 19.502 5.19447 19.502C5.28678 19.502 5.37811 19.483 5.46282 19.4464C5.54753 19.4097 5.62381 19.3561 5.68698 19.2888L12.2443 12.7455L18.8017 19.2888C18.8648 19.3561 18.9413 19.4097 19.0258 19.4464C19.1105 19.483 19.2019 19.502 19.2942 19.502C19.3865 19.502 19.4778 19.483 19.5625 19.4464C19.6472 19.4097 19.7236 19.3561 19.7867 19.2888C19.8541 19.2256 19.9077 19.1494 19.9444 19.0646C19.981 18.9799 20 18.8886 20 18.7963C20 18.704 19.981 18.6127 19.9444 18.5279C19.9077 18.4432 19.8541 18.367 19.7867 18.3038L13.2434 11.7464Z"
      fill="black"
    />
  </SvgIcon>
);
