import * as R from 'ramda';
import { useQuery } from 'react-query';
import agendaApi from '../api/agenda';
import useAuth from './useAuth';
import useCountries from './useCountries';

export default () => {
  const headers = useAuth({});

  const ctx = {
    method: 'getMarkets',
    params: {
      _pagination: false
    },
    headers
  };

  const queryKey = `@@agenda/${ctx.method}`;
  const { data: countries = [] } = useCountries();
  const data = useQuery(queryKey, () => agendaApi(ctx));

  const groupedCountries = R.groupBy(R.prop('parentId'), countries);
  return R.evolve({
    data: (data = []) => R.map((market) => R.assoc('countryIds', R.pipe(R.propOr([], market.id), R.pluck('id'))(groupedCountries), market))(data)
  })(data)
};
