import { useState, useEffect } from "react";
import * as R from "ramda";
import { Typography, Box, InputAdornment, Input } from '@mui/material'
import { styled } from '@mui/material/styles';
import SimpleSelect from "../../Select/Select";
import BlackButton from '../../BlackButton/BlackButton';
import useMarkets from "../../../hooks/useMarkets";
import useBoutiques from "../../../hooks/useBoutiques";
import useCountries from "../../../hooks/useCountries";
import useDepartments from "../../../hooks/useDepartments";
import useGroups from "../../../hooks/useGroups";
import { PERMISSIONS } from "../../../lib/permissions";
import useEditGroup from "../../../hooks/useEditGroup";
import useAddGroup from "../../../hooks/useAddGroup";
import Chips from "../../Chips/Chips";
import Check from "../../icons/Check";
import { cleanIdRegex, getRecursiveChildrenByIds, getRecursiveParentByIds, prepareDataRecursiveIds } from "../../../utils";
import { getCleanedId } from "../../../utils";
import { selectEmeaId } from "../../../selectors/calendar";
import { useSelector } from "react-redux";
import useIndexedData from "../../../hooks/useIndexedData";

const ALL_MARKETS = 'allMarkets';
const ALL_COUNTRIES = 'allCountries';
const ALL_STORES = 'allStores';
const BWT = 'BWT-';

const Select = styled(SimpleSelect)(() => ({
  "& .MuiInputLabel-root": {
    color: "gray",
    transform: 'translate(0px, 20px) scale(1)',
  },
  "& label.Mui-focused": {
    transform: 'translate(0px, 20px) scale(1)',
    backgroundColor: 'transparent'
  }
}));

const all = { id: "*", label: "All", hasDelete: false };

const getDefaultDepartmentsState = (ids = [], items = []) => {
  const list = R.indexBy(R.prop("id"), R.prepend(all)(items));
  // mode edit
  if (!R.isEmpty(ids)) {
    return ({
      selected: R.pick(ids, list),
      list
    })
  }

  return ({
    selected: { '*': all },
    list
  })
}

const getChipsSelection = (id, label, indexedItems) => selection => {
  const indexedItemsEnhanced = R.assoc(id, { id, label, hasDelete: false }, indexedItems)
  return R.pipe(
    R.mapObjIndexed((value, id) => {
      if (!value) return;
      return R.prop(id, indexedItemsEnhanced)
    }),
    R.values,
    R.reject(R.isNil),
  )(selection);
}

const hasAnyItemSelected = (artefact, selection) => R.pipe(
  R.map(({ id }) => R.has(id)),
  R.map(hasItem => hasItem(selection)),
  R.any(R.identity)
)(artefact);

export const getIds = (id, { markets, countries, boutiques }, isSelected = false) => {
  const {
    indexedItems,
    groupedItems,
  } = prepareDataRecursiveIds({ markets, countries, boutiques });

  const parentId = R.path([id, 'parentId'], indexedItems)
  const hasParentId = R.has(parentId, indexedItems)
  
  if (isSelected) {
    return  [
      ...getRecursiveParentByIds(parentId, indexedItems, (parentId && hasParentId) ? [parentId] : [])
    ];
  }

  return[
    ...getRecursiveChildrenByIds(id, groupedItems, indexedItems, [id]),
  ]; 
}

const getDefaultTerritoriesSelection = (group = {}) => {
  const { stores: storeIds = [], markets: marketIds = [], countries: countryIds = [] } = group || {};
  const selection = R.reduce((acc, id) => ({ ...acc, [id]: true }), {})([...marketIds, ...countryIds, ...storeIds])
  return {
    ...R.dissoc('*')(selection),
    [ALL_MARKETS]: R.isEmpty(marketIds) || R.has('*')(selection),
    [ALL_COUNTRIES]: R.isEmpty(countryIds) || R.has('*')(selection),
    [ALL_STORES]: R.isEmpty(storeIds) || R.has('*')(selection),
  }
}

const GroupsForm = ({ editProps = [], variant = "add", changePage }) => {
  const isAdd = variant === "add";
  const label = R.head(editProps);
  
  const indexedMarketsWithEmea = useIndexedData(useMarkets);
  const emeaId = useSelector(selectEmeaId);
  const indexedMarkets = R.dissoc(emeaId, indexedMarketsWithEmea);
  const markets = R.values(indexedMarkets);
  const indexedCountries = useIndexedData(useCountries);
  const { data: countries } = useCountries(); 
  const indexedBoutiques = useIndexedData(useBoutiques);
  const { data: boutiques } = useBoutiques();

  const { data: departments = [] } = useDepartments();
  const { data: groups = [] } = useGroups();

  const group = isAdd ? {} : R.prop(label, R.indexBy(R.prop("label"), groups));

  const addGroup = useAddGroup();
  const editGroup = useEditGroup();
  
  const permissions = R.pipe(R.values, R.map((label) => ({ label, id: label })))(PERMISSIONS)
  const defaultPermission = group?.permission || R.prop('id', R.head(permissions));
  const defaultTitle = R.replace(BWT, '', group?.label || "");
  const defaultTerritoriesSelection = getDefaultTerritoriesSelection(group);
  const defaultDepartmentsState = getDefaultDepartmentsState(group?.departments, departments);


  const [territoriesSelection, setTerritoriesSelection] = useState(defaultTerritoriesSelection);
  const [departmentState, setDepartmentState] = useState(defaultDepartmentsState);
  const [selectedPermission, setSelectedPermission] = useState(defaultPermission);

  const [title, setTitle] = useState(defaultTitle);
  const [formDisabled, setFormDisabled] = useState(false);
  const [isSavePending, setIsSavePending] = useState(false);
  const [isAddPending, setIsAddPending] = useState(false);

  useEffect(() => {
    if (R.isEmpty(title)) {
      return setFormDisabled(true);
    }
    setFormDisabled(false)
  }, [title]);


  const { indexedItems } = prepareDataRecursiveIds({ markets, countries, boutiques})

  const handleSelection = (set, artefact) => id => {
    if (R.head(id) === '*') {
      return set({ ...artefact, selected: { [id]: all } })
    }
    const selected = artefact.selected;
    if (R.has(id, selected)) {
      return set({ ...artefact, selected: R.pipe(R.omit([id]), R.ifElse(R.isEmpty, R.assoc('*', all), R.identity))(selected) });
    }
    return set({ ...artefact, selected: R.pipe(R.assoc(id, R.prop(id, artefact.list)), R.omit(['*']))(selected) });
  };

  const handleTerritoriesSelection = (id) => {
    const isSelected = !R.prop(id, territoriesSelection);
    const selectionIds = R.pipe(
      R.flatten,
      R.reduce((acc, id) => ({ ...acc, [id]: isSelected }), {})
    )(getIds(id, { markets, countries, boutiques }, isSelected));

    setTerritoriesSelection(R.filter(R.identity, { ...territoriesSelection, ...selectionIds, [id]: isSelected }));
  }
  const hasAnyMarketSelected = hasAnyItemSelected(markets, territoriesSelection);
  const hasAnyCountrySelected = hasAnyItemSelected(countries, territoriesSelection);
  const hasAnyStoreSelected = hasAnyItemSelected(boutiques, territoriesSelection);

  useEffect(() => {
    setTerritoriesSelection(selection => ({
      ...selection,
      [ALL_MARKETS]: !hasAnyMarketSelected,
      [ALL_COUNTRIES]: !hasAnyCountrySelected,
      [ALL_STORES]: !hasAnyStoreSelected,
    }))
  }, [hasAnyMarketSelected, hasAnyCountrySelected, hasAnyStoreSelected])

  const onEdit = params => {
    setIsSavePending(true)
    editGroup.mutate(params, {
      onSuccess: () => {
        setIsSavePending(false);
        changePage();
      },
      onError: (err) => {
        /* eslint-disable no-console */
        console.error('error:', err.message);
        setIsSavePending(false);
      }
    });
  };

  const onAdd = params => {
    setIsAddPending(true)
    addGroup.mutate(params, {
      onSuccess: () => {
        setIsAddPending(false);
        changePage();
      },
      onError: (err) => {
        /* eslint-disable no-console */
        console.error('error:', err.message);
        setIsAddPending(false);
      }
    });
  };

  const onReset = () => {
    setTitle(defaultTitle);
    setSelectedPermission(defaultPermission);
    setTerritoriesSelection(defaultTerritoriesSelection);
    setDepartmentState(defaultDepartmentsState);
    changePage()
  }

  const form = (groupId) => {
    const getIds = (apiKey, selection) => R.pipe(
      R.keys,
      R.filter(R.includes(apiKey)),
      R.map(R.pipe(R.replace(cleanIdRegex, ''), v => Number(v)))
    )(selection);
    return ({
      id: getCleanedId(groupId),
      name: `${BWT}${title}`,
      permissionType: selectedPermission,
      markets: R.prop(ALL_MARKETS, territoriesSelection) ? ['*'] : getIds('/api/markets/', territoriesSelection),
      countries: R.prop(ALL_COUNTRIES, territoriesSelection) ? ['*'] : getIds('/api/countries/', territoriesSelection),
      stores: R.prop(ALL_STORES, territoriesSelection) ? ['*'] : getIds('/api/stores/', territoriesSelection),
      departments: R.pipe(R.keys, R.head, R.equals('*'))(departmentState.selected) ? ['*'] : getIds('/api/departments/', departmentState.selected),
    })
  }

  return (
    <>
      {isAdd && <Typography variant="h2">Add group</Typography>}
      {!isAdd && <Typography variant="h2">Edit group</Typography>}
      <Box marginY={4} maxWidth="650px">
        <Box sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridTemplateRows: "0fr",
          gridColumnGap: "50px",
        }}>
          <Box sx={{
            display: "grid",
            gridTemplateRows: "0fr",
            gridRowGap: "19px",
          }} >
            <Box>
              <Input
                fullWidth={true}
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                placeholder="EMEA-Administrators"
                variant="standard"
                inputProps={{ maxLength: 40 }}
                sx={{ marginTop: "13px" }}
                startAdornment={<InputAdornment position="start" sx={{ marginRight: "unset" }}>{BWT}</InputAdornment>}
              />
            </Box>
            <Box>
              <Select
                label="Permissions"
                items={permissions}
                value={selectedPermission}
                onChange={id => setSelectedPermission(id)}
              />
            </Box>
          </Box>
          <Box sx={{
            display: "grid",
            gridTemplateColumns: "1fr",
            gridTemplateRows: "1fr",
            gridRowGap: "19px",
          }}>
            {R.map(({ artefact, indexedItems, allId, label }) => {
              
              return (
                <Box key={label}>
                  <Select
                    label={label}
                    items={artefact}
                    selectedValues={territoriesSelection}
                    onChange={(id) => handleTerritoriesSelection(id)}
                    isMultiple
                  />
                  <Chips
                    items={getChipsSelection(allId, `All ${label}`, indexedItems)(territoriesSelection)}
                    onDelete={(id) => handleTerritoriesSelection(id)}
                  />
                </Box>
              );
            }, [
              { indexedItems: indexedMarkets, artefact: markets, allId: ALL_MARKETS, label: 'Markets' },
              { indexedItems: indexedCountries, artefact: countries, allId: ALL_COUNTRIES, label: 'Countries' },
              { indexedItems: indexedBoutiques, artefact: boutiques, allId: ALL_STORES, label: 'Stores' },
            ])}
            <Box>
              <Select
                label="Departments"
                items={R.values(departmentState.list)}
                selectedValues={departmentState.selected}
                onChange={handleSelection(setDepartmentState, departmentState)}
                isMultiple
              />
              <Chips
                items={R.values(departmentState.selected)}
                onDelete={handleSelection(setDepartmentState, departmentState)}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: "16px" }}>
        <BlackButton
          variant="outlined"
          onClick={onReset}
        >
          CANCEL
        </BlackButton>
        {isAdd && (
          <BlackButton
            startIcon={<Check />}
            disabled={formDisabled}
            onClick={() => onAdd(R.dissoc('id', form()))}
            isLoading={isAddPending}
          >
            CREATE GROUP
          </BlackButton>
        )}
        {!isAdd && (
          <BlackButton
            startIcon={<Check />}
            disabled={formDisabled}
            onClick={() => onEdit(form(group.id))}
            isLoading={isSavePending}
          >
            SAVE
          </BlackButton>
        )}
      </Box>
    </>
  )
}

export default GroupsForm
