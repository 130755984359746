import { useEffect, useState } from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material';
import { OktaAuth } from '@okta/okta-auth-js';
import { AGENDA, CONFIG } from '../../utils/constants';
import Navbar from '../Navbar/Navbar';
import Agenda from '../Agenda/Agenda';
import Config from '../Config/Config';
import { selectTab } from '../../selectors/app';
import useMarkets from '../../hooks/useMarkets';
import { changeEmeaId } from '../../ducks/calendar';
import { selectEmeaId } from '../../selectors/calendar';
import dayjs from 'dayjs';

const App = () => {
  const theme = useTheme();
  const tab = useSelector(selectTab);
  const dispatch = useDispatch();
  const emeaId = useSelector(selectEmeaId);
  const { data: markets = [] } = useMarkets();
  const id = R.pipe(R.find(R.propEq('label', 'EMEA')), R.prop('id'))(markets);
  useEffect(() => {
    if (id !== emeaId) {
      dispatch(changeEmeaId(id));
    }
  }, [id]);
  let config;

  if (window.location.origin == process.env.REACT_APP_URL_UAT || process.env.REACT_APP_URL_LOCAL) {
    config = {
      clientId: process.env.REACT_APP_OKTA_CLIENT_ID_UAT,
      issuer: 'https://cdc.okta.com/',
      redirectUri: `${window.location.origin}/sso/callback`,
    };
  }
  if (window.location.origin == process.env.REACT_APP_URL_PPD) {
    config = {
      clientId: process.env.REACT_APP_OKTA_CLIENT_ID_PPD,
      issuer: 'https://cdc.okta.com/',
      redirectUri: `${window.location.origin}/sso/callback`,
    };
  }
  if (window.location.origin == process.env.REACT_APP_URL_PRD) {
    config = {
      clientId: process.env.REACT_APP_OKTA_CLIENT_ID_PRD,
      issuer: 'https://cdc.okta.com/',
      redirectUri: `${window.location.origin}/sso/callback`,
    };
  }

  const authClient = new OktaAuth(config);
  const [tokenExpired, setTokenExpired] = useState(
    window?.location?.search.split('token=')[1] == undefined &&
      dayjs().isBefore(
        dayjs.unix(JSON.parse(localStorage.getItem('okta-token-storage'))?.accessToken?.expiresAt),
      ),
  );

  // await authClient.stop()
  function loginMethod() {
    if (window?.location?.search.split('token=')[1] == undefined) {
      authClient.token
        .getWithRedirect({
          responseType: ['token', 'id_token'],
        })
        .catch(function (err) {
          // eslint-disable-next-line
          console.error('Error OKTA login redirect', err);
        });
    }
  }

  function setTokenMethod() {
    if (window?.location?.search.split('token=')[1] == undefined) {
      authClient.token
        .parseFromUrl()
        .then(async function (res) {
          // manage token or tokens
          var tokens = res.tokens;
          // Setting token in token manager to get fetch access token from SDK
          authClient.tokenManager.setTokens(tokens);
        })
        .catch(function (err) {
          console.error('Error OKTA set token', err);
        });
    }
  }

  useEffect(() => {
    window?.location?.href == window.location.origin + '/' && loginMethod();
  }, [tokenExpired]);

  useEffect(() => {
    window?.location?.search.split('code=')[1] && setTokenMethod();
    !tokenExpired && loginMethod();
  }, []);

  useEffect(() => {
    window?.location?.search.split('token=')[1] == undefined &&
      setTokenExpired(
        dayjs().isBefore(
          dayjs.unix(
            JSON.parse(localStorage.getItem('okta-token-storage'))?.accessToken?.expiresAt,
          ),
        ),
      );
  });

  // const oktaLogoutMethod = async () => {
  //   await authClient.revokeAccessToken();
  //   authClient.signOut({
  //     postLogoutRedirectUri: `${window.location.origin}`,
  //   });
  // };
  // useEffect(() => {
  //   oktaLogoutMethod();
  // }, [authToken]);

  return (
    (tokenExpired || window?.location?.search.split('token=')[1] != undefined) && (
      <Container
        maxWidth={false}
        disableGutters
        sx={{ padding: theme.spacing(0, 6), maxHeight: '100vh' }}
      >
        {/* <div id="sign-in-widget" /> */}
        <Navbar />
        {(() => {
          switch (tab) {
            case AGENDA:
              return <Agenda />;
            case CONFIG:
              return <Config />;
            default:
              return null;
          }
        })()}
      </Container>
    )
  );
};

export default App;