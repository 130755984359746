import SvgIcon from "@mui/material/SvgIcon";

export default props => (
  <SvgIcon {...props}>
    <path
      d="M19.2602 4.73994C18.5962 4.10895 17.7161 3.75708 16.8001 3.75708C15.8841 3.75708 15.0041 4.10895 14.3401 4.73994L9.54015 9.53994C9.17815 9.90714 8.90115 10.3498 8.73015 10.8364C8.55915 11.323 8.49713 11.8414 8.55013 12.3545C8.60213 12.8676 8.76814 13.3627 9.03414 13.8045C9.30014 14.2463 9.66114 14.6238 10.0901 14.9099C10.1681 14.9576 10.2581 14.9818 10.3501 14.9799C10.4291 14.9823 10.5071 14.9642 10.5781 14.9274C10.6481 14.8906 10.7071 14.8364 10.7501 14.7699C10.8191 14.6648 10.8441 14.5368 10.8201 14.4133C10.7961 14.2899 10.7241 14.1809 10.6201 14.1099C10.2681 13.8818 9.97815 13.5693 9.77715 13.2008C9.57615 12.8323 9.47114 12.4195 9.47014 11.9999C9.47314 11.3293 9.73813 10.6866 10.2101 10.2099L15.0001 5.41994C15.4791 4.95741 16.1191 4.69891 16.7851 4.69891C17.4511 4.69891 18.0911 4.95741 18.5701 5.41994C18.8071 5.65173 18.9961 5.92858 19.1251 6.23425C19.2531 6.53992 19.3201 6.86824 19.3201 7.19994C19.3221 7.53397 19.2571 7.865 19.1301 8.17394C19.0031 8.48288 18.8161 8.76361 18.5801 8.99994L16.7901 10.7999C16.7441 10.8429 16.7082 10.8948 16.6832 10.9524C16.6582 11.01 16.6451 11.0721 16.6451 11.1349C16.6451 11.1977 16.6582 11.2598 16.6832 11.3174C16.7082 11.375 16.7441 11.4269 16.7901 11.4699C16.8801 11.5572 17.0001 11.6061 17.1251 11.6061C17.2501 11.6061 17.3701 11.5572 17.4601 11.4699L19.2602 9.66994C19.9072 9.01481 20.2701 8.13093 20.2701 7.20994C20.2701 6.28895 19.9072 5.40507 19.2602 4.74994V4.73994Z"
      fill="gray"
    />
    <path
      d="M13.91 9.09987C13.858 9.06572 13.8001 9.04213 13.7391 9.03043C13.6781 9.01874 13.615 9.01917 13.555 9.03171C13.432 9.05703 13.324 9.1301 13.255 9.23486C13.186 9.33962 13.1621 9.46749 13.1871 9.59032C13.2121 9.71316 13.2851 9.82091 13.3901 9.88987C13.7401 10.1198 14.0271 10.433 14.2261 10.8012C14.4251 11.1695 14.529 11.5813 14.53 11.9999C14.527 12.6704 14.2621 13.3132 13.7901 13.7899L9.00004 18.5799C8.52104 19.0424 7.88104 19.3009 7.21504 19.3009C6.54904 19.3009 5.90903 19.0424 5.43003 18.5799C5.19303 18.3481 5.00404 18.0712 4.87504 17.7656C4.74704 17.4599 4.68003 17.1316 4.68003 16.7999C4.67803 16.4659 4.74304 16.1348 4.87004 15.8259C4.99704 15.5169 5.18406 15.2362 5.42006 14.9999L7.21003 13.1999C7.25603 13.1569 7.29203 13.105 7.31703 13.0474C7.34203 12.9897 7.35505 12.9276 7.35505 12.8649C7.35505 12.8021 7.34203 12.74 7.31703 12.6824C7.29203 12.6248 7.25603 12.5729 7.21003 12.5299C7.12003 12.4425 7.00004 12.3937 6.87504 12.3937C6.75004 12.3937 6.63005 12.4425 6.54005 12.5299L4.74003 14.3299C4.11403 14.9876 3.76904 15.8641 3.78004 16.7723C3.79104 17.6805 4.15705 18.5484 4.79905 19.1906C5.44205 19.8329 6.30903 20.1986 7.21803 20.2096C8.12603 20.2207 9.00205 19.8763 9.66005 19.2499L14.46 14.4499C14.822 14.0836 15.099 13.6417 15.27 13.1559C15.441 12.6701 15.5021 12.1525 15.4501 11.6401C15.3971 11.1277 15.232 10.6334 14.966 10.1924C14.699 9.75151 14.339 9.37499 13.91 9.08986V9.09987Z"
      fill="gray"
    />
  </SvgIcon>
);
