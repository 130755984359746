import SvgIcon from "@mui/material/SvgIcon";
import './styles.css'

export default props => (
  <SvgIcon viewBox="0 0 26 26" {...props}>
    <path d="M9.33325 16.6459H20.4166" className='icon-svg-list'/>
    <path d="M9.33325 11.8959H20.4166" className='icon-svg-list'/>
    <path d="M9.33325 7.14587H20.4166" className='icon-svg-list'/>
    <circle cx="4.97925" cy="7.14587" r="1.1875" fill="black"/>
    <circle cx="4.97925" cy="11.8959" r="1.1875" fill="black"/>
    <circle cx="4.97925" cy="16.6459" r="1.1875" fill="black"/>
  </SvgIcon>
);
