import { useQueryClient, useMutation } from 'react-query';
import agendaApi from '../api/agenda';
import useAuth from './useAuth';
import { onEventSuccess } from './utils';

export default () => {
  const queryClient = useQueryClient();  
  const headers = useAuth({}); 

  const ctx = (params) => ({
    method: 'postEvent',
    params,
    headers,
  });
  
  return useMutation(params => agendaApi(ctx(params)), { onSuccess: onEventSuccess(queryClient) });
};
