import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useEffect, useState } from 'react';
import './CalendarDateRange.css';
import { useDispatch } from 'react-redux';
import { changeDateRangeSelection, changeView, changeDate } from '../../ducks/calendar';
import { DAY, WEEK } from '../../utils/constants';
import dayjs from 'dayjs';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import CalendarDatesFieldIcons from '../icons/CalendarDatesFieldIcons';

const CalendarDateRange = (props) => {
  const moment = extendMoment(Moment);
  const dispatch = useDispatch();
  const [state, setState] = useState([
    {
      key: 'selection',
      color: '#EDEDED',
    },
  ]);
  useEffect(() => {
    if (state[0].endDate == undefined && state[0].start == undefined) {
      dispatch(changeView(WEEK));
      [
        {
          startDate: null,
          endDate: null,
          key: 'selection',
          color: '#EDEDED',
        },
      ];
    }
    if (dayjs(state[0].endDate).isAfter(dayjs(state[0].startDate))) {
      dispatch(changeDate({ start: state[0]?.startDate, end: moment(state[0]?.endDate)?.add('1','day')._d }));
      dispatch(changeDateRangeSelection(state));
      dispatch(changeView(DAY));
      props.setOpenRange(false);
    }
  }, [dayjs(state[0].endDate).isAfter(dayjs(state[0].startDate))]);

  return props.openRange ? (
    <>
      <div
        className="calendar-backdrop"
        onClick={() => {
          props.setOpenRange(false);
        }}
      ></div>

      <div className="container">
        <CalendarDatesFieldIcons className="start-date"></CalendarDatesFieldIcons>
        <CalendarDatesFieldIcons className="end-date"></CalendarDatesFieldIcons>
        <DateRange
          retainEndDateOnFirstSelection={false}
          showMonthAndYearPickers={false}
          startDatePlaceholder={'*Starting date'}
          endDatePlaceholder={'*Ending date'}
          editableDateInputs={false}
          onChange={(item) => {
            setState([item.selection]);
          }}
          moveRangeOnFirstSelection={false}
          ranges={state}
        />
        <hr className="line"></hr>
      </div>
    </>
  ) : (
    ''
  );
};

export default CalendarDateRange;
