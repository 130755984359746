import * as R from 'ramda';

export const isDev = process.env.NODE_ENV === 'development';
export const sortAsc = R.sort((a, b) => a - b);

export const capitalize = string => R.replace(/^./, R.toUpper)(R.toLower(string))

export const renameKeys = R.curry((keysMap, obj) =>
  R.reduce((acc, key) => R.assoc(keysMap[key] || key, obj[key], acc), {}, R.keys(obj)),
);

export const getTree = (groupedList, id = 'root') => {
  const currentList = R.head(R.take(1, groupedList));
  const nextGroupedList = R.drop(1, groupedList);
  if (R.isEmpty(nextGroupedList)) return R.pipe(
    R.propOr([], id),
    R.map(renameKeys({ label: 'title' }),
    ))(currentList);

  return R.map(
    (item) => ({
      ...renameKeys({ label: 'title' })(item),
      children: getTree(nextGroupedList, item.id),
    }),
    R.propOr([], id, currentList),
  );
};
export const getFilteredTerritoriesEvents = (territoriesSelection, emeaId) => (events) => {
  if (R.isEmpty(territoriesSelection)) return events;
  return R.filter(({ resourceId }) => {
    const indexedTerritories = new Set([...territoriesSelection, emeaId]);
    return indexedTerritories.has(resourceId);
  }, events);
};

export const filterByDepartment = departmentIds => (data = []) => {
  if (R.isEmpty(departmentIds)) return data;
  return R.filter(item => R.includes(R.path(['extendedProps', 'departmentId'], item), departmentIds))(data);
};

export const getLeaves = (artefacts) => (incompleteflatTreeIndexedById) => {
  const indexedArtefacts = R.indexBy(R.prop('id'), artefacts);

  const flatTreeIndexedById = R.pipe(
    R.values,
    R.pluck('parentId'),
    R.reduce((acc, parentId) => {
      const parent = R.prop(parentId, indexedArtefacts);
      if (!parent) return acc;
      return { ...acc, [parent.id]: parent }
    }, incompleteflatTreeIndexedById),
  )(incompleteflatTreeIndexedById)

  return R.pipe(
    R.values,
    R.pluck('parentId'),
    (parentIdKeys) => R.omit(parentIdKeys, flatTreeIndexedById),
  )(flatTreeIndexedById)
};
export const searchResources = (splittedRows, searched) =>
  R.filter(
    name => R.includes(
      R.toLower(searched),
      R.toLower(R.prop(0, name))),
    splittedRows)

export const searchEvents = (allEvents, searched) =>
  R.filter(
    event => R.includes(
      R.toLower(searched),
      R.toLower(event?.title)),
    allEvents
  )

export const removeDuplicatesEvents = R.pipe(
  R.uniqBy(R.prop('eventId'))
)

export const intersperse = R.curry((separator, list) => {
  const length = R.length(list)
  return R.addIndex(R.reduce)((acc, value, index) => {
    if (length === index + 1) return R.append(value, acc);
    return R.append(separator(index))(R.append(value, acc), acc)
  }, [], list)
});

export const cleanIdRegex = /(\/.*\/)/gm; // /api/markets/5 remove /api/markets/ to let 5
export const getCleanedId = (value = '') => R.pipe(R.replace(cleanIdRegex, ""), v => Number(v))(value);

// should be transformed into a hook useMemo
export const prepareDataRecursiveIds = ({ markets, countries, boutiques }) => {
  const groupedCountries = R.groupBy(R.propOr('root', 'parentId'), countries)
  const groupedBoutiques = R.groupBy(R.propOr('root', 'parentId'), boutiques)
  const indexedMarkets = R.indexBy(R.prop('id'), markets);
  const indexedCountries = R.indexBy(R.prop('id'), countries);
  const indexedBoutiques = R.indexBy(R.prop('id'), boutiques);

  return {
    indexedItems: { ...indexedMarkets, ...indexedCountries, ...indexedBoutiques },
    groupedItems: { ...groupedCountries, ...groupedBoutiques }
  }
};

export const getRecursiveChildrenByIds = (id, groupedItems, indexedItems, memo = []) => {
  const children = R.propOr({}, id, groupedItems);
  if (!R.has(id, indexedItems)) return memo;
  if (R.isEmpty(children)) return memo;
  return R.reduce((acc, { id }) =>
    getRecursiveChildrenByIds(id, groupedItems, indexedItems, R.append(id, acc))
  )(memo, children)
}

export const getRecursiveParentByIds = (parentId, indexedItems, memo = []) => {
  const item = R.prop(parentId, indexedItems);
  if (!R.has(item?.parentId, indexedItems)) return memo;
  if (R.isNil(item?.parentId)) return memo;
  return getRecursiveParentByIds(item.parentId, indexedItems, R.append(item.parentId, memo))
}
