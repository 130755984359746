import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import createRootReducer from './reducers';
import { isDev } from './utils';

export default (initialState) => {
  const middlewares = [thunk];
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  if (isDev) {
    const { createLogger } = require('redux-logger');
    const logger = createLogger({ duration: true, timestamp: false, collapsed: true, diff: true });
    middlewares.push(logger);
  }

  const store = createStore(
    createRootReducer,
    initialState,
    composeEnhancer(applyMiddleware(...middlewares)),
  );

  return store;
};
