import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import CalendarIcon from '../icons/EditIcons/CalendarIconEdit';

const DatePicker = ({ onChange, value, label, ...props }) => {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MuiDatePicker
        {...props}
        inputFormat="DD/MM/YYYY"
        label={label}
        InputAdornmentProps={{
          position: "start",
          sx: { mr: '-0.7vh', fontSize: '1.7vh' }
        }}
        components={{
          OpenPickerIcon: (props) => <CalendarIcon {...props} />
        }}
        value={value}
        onChange={(e) => onChange(e?.$d || null)}
        renderInput={(params) => (
          <TextField
            sx={{ fontSize: '1.7vh' }}
            variant='standard'
            {...params}
          />
        )}
        disableHighlightToday
      />
    </LocalizationProvider>
  );
}

export default DatePicker;