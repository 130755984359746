


import SvgIcon from '@mui/material/SvgIcon';

export default (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M21.23 15.71L21.23 15.711C21.2349 18.1183 20.72 19.718 19.7138 20.7254C18.7074 21.7328 17.1075 22.25 14.7 22.25H9.30001C6.88734 22.25 5.28755 21.7328 4.28126 20.7254C3.27485 19.7179 2.76001 18.1179 2.76001 15.71V11.22C2.76001 11.0861 2.87615 10.97 3.01001 10.97C3.14387 10.97 3.26001 11.0861 3.26001 11.22V15.71C3.26001 17.7898 3.62342 19.348 4.64271 20.3673C5.66199 21.3866 7.22014 21.75 9.30001 21.75H14.69C16.7699 21.75 18.328 21.3866 19.3473 20.3673C20.3666 19.348 20.73 17.7898 20.73 15.71V11.22C20.73 11.0861 20.8462 10.97 20.98 10.97C21.1139 10.97 21.23 11.0861 21.23 11.22L21.23 15.71Z" fill="black" stroke="black" />
      <path d="M10.35 2.25H9.89743L9.85248 2.70033L9.25271 8.70804C9.25267 8.70844 9.25263 8.70883 9.25258 8.70923C9.16922 9.51015 9.40773 10.2837 9.92987 10.8562C10.4663 11.4489 11.2484 11.725 12 11.725C12.7519 11.725 13.5344 11.4486 14.0709 10.8554L14.0728 10.8532C14.5903 10.2741 14.8312 9.50303 14.7474 8.70881L14.1475 2.70033L14.1026 2.25H13.65H10.35ZM12 12.25C11.034 12.25 10.1719 11.8745 9.56188 11.1958C8.94953 10.5144 8.661 9.62532 8.75751 8.6599C8.75751 8.65985 8.75752 8.6598 8.75752 8.65975L9.42725 1.98234C9.42729 1.98204 9.42732 1.98173 9.42735 1.98143C9.44179 1.84869 9.55382 1.75 9.68 1.75H14.35C14.4816 1.75 14.5884 1.84399 14.6026 1.98062C14.6026 1.98075 14.6026 1.98088 14.6026 1.98101L15.2725 8.65975C15.2725 8.6598 15.2725 8.65985 15.2725 8.6599C15.3688 9.62351 15.0816 10.5111 14.4716 11.1919C13.828 11.8769 12.9623 12.25 12 12.25Z" fill="black" stroke="black" />
      <path d="M21.8625 8.33011L21.8626 8.33059C21.9688 9.37471 21.651 10.353 20.9794 11.0943C20.3079 11.8357 19.3643 12.25 18.31 12.25C16.5379 12.25 14.9416 10.8056 14.7574 9.03928C14.7574 9.03895 14.7574 9.03863 14.7573 9.0383L14.0577 2.0326C14.0577 2.03232 14.0577 2.03205 14.0577 2.03177C14.0514 1.96322 14.0742 1.88881 14.1263 1.82925C14.1688 1.78067 14.2303 1.75 14.31 1.75H17.36C18.768 1.75 19.7021 2.04531 20.3343 2.61418C20.9684 3.1848 21.3853 4.10806 21.5834 5.55878L21.8625 8.33011ZM15.14 2.25H14.5874L14.6425 2.79983L15.2625 8.98983L15.2625 8.98983L15.2627 8.99172C15.4188 10.493 16.7905 11.75 18.31 11.75C19.2019 11.75 20.0175 11.4072 20.6071 10.7694L20.6072 10.7694L20.6106 10.7656C21.1932 10.1223 21.4495 9.27012 21.3679 8.38414L21.3679 8.38414L21.3674 8.37935L21.0874 5.62935L21.0865 5.62068L21.0854 5.61205C20.9276 4.46184 20.6498 3.5734 20.0163 2.99753C19.3769 2.41624 18.4833 2.25 17.36 2.25H15.14Z" fill="black" stroke="black" />
      <path d="M5.64002 12.75C4.45002 12.75 3.37002 12.28 2.60002 11.43C1.83002 10.58 1.47002 9.46 1.59002 8.28L1.86002 5.53C2.28002 2.48 3.65002 1.25 6.59002 1.25H9.64002C9.85002 1.25 10.05 1.34 10.2 1.5C10.35 1.66 10.41 1.87 10.39 2.08L9.69002 9.09C9.48002 11.11 7.67002 12.75 5.64002 12.75ZM6.59002 2.75C4.40002 2.75 3.67002 3.41 3.35002 5.7L3.08002 8.43C3.00002 9.19 3.23002 9.9 3.71002 10.43C4.19002 10.96 4.87002 11.25 5.64002 11.25C6.90002 11.25 8.07002 10.19 8.19002 8.94L8.81002 2.75H6.59002Z" fill="black" />
      <path d="M14.5 22.75H9.5C9.09 22.75 8.75 22.41 8.75 22V19.5C8.75 17.4 9.9 16.25 12 16.25C14.1 16.25 15.25 17.4 15.25 19.5V22C15.25 22.41 14.91 22.75 14.5 22.75ZM10.25 21.25H13.75V19.5C13.75 18.24 13.26 17.75 12 17.75C10.74 17.75 10.25 18.24 10.25 19.5V21.25Z" fill="black" />
    </svg>
  </SvgIcon>
);